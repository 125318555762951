import styled from 'styled-components';

const HeaderNavStyle = styled('div')`
    height: 47px;
    // width: 100%;
    background-color: #fff;
    width: fit-content;
    border: 2px solid #ebebed;
    // padding: 10px;
    display: flex;
    align-items: center;
    margin: 0px;

    .menuItem {
        padding: 10px;
        font-size: 14px;
        // border: 1px solid #ebebed;
        cursor: pointer;
        // text-transform: uppercase;
        font-weight: 600;
    }

    .active {
        // color: #fff;
        background: #ebebed;
        cursor: default;
    }
`;

export { HeaderNavStyle };
