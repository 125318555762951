import React, { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { CardBody, Card, ModalBody, Modal, ModalHeader } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from 'components/Loader/loader';
import { useHistory } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import ConvertDate from 'utils/timeZone';

const DashboardTransaction = (props) => {
    const NoDataIndication = () => (
        <div className="EmptyBlock">
            <div className="rect_Emplty">No Data Available</div>
        </div>
    );

    const history = useHistory();
    const { SearchBar } = Search;
    const [openNote, setOpenNote] = useState(false);
    const [noteDetail, setNoteDetail] = useState('');

    const columns = [
        {
            dataField: 'booking_no',
            text: 'Booking Number',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return (
                    <div
                        style={{ color: 'steelblue', cursor: 'pointer' }}
                        onClick={() => window.open(`/apps/bookingdetails/${row.booking_id}`, '_blank')}>
                        {rowContent}
                    </div>
                );
            },
        },
        {
            dataField: 'vessel',
            text: 'Vessel',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return <div>{rowContent || '-'}</div>;
            },
        },
        {
            dataField: 'customer_name',
            text: 'Customer',
            sort: true,
            headerClasses: 'id-custom-cell',
        },

        {
            dataField: 'start_date',
            text: 'Appointment Date',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                let date = rowContent?.split(',');
                let testDate = date?.map((individualDate) => {
                    return ConvertDate(individualDate);
                });
                return <div>{testDate?.join(',')}</div>;
            },
        },
        {
            dataField: 'schedule_date',
            text: 'Schedule Survey Date',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                let date = rowContent?.split(',');
                let testDate = date?.map((individualDate) => {
                    return ConvertDate(individualDate);
                });
                return <div>{testDate?.join(',')}</div>;
            },
        },
        {
            dataField: 'solution',
            text: 'Solution',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'subsolutions',
            text: 'SubSolution',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'location_name',
            text: 'Location',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'superintendent_name',
            text: 'Superintendent',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'note',
            text: 'Note',
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return (
                    <>
                        {rowContent && rowContent?.length ? (
                            <div
                                style={{ cursor: 'pointer', color: 'steelblue' }}
                                onClick={(prev) => {
                                    setOpenNote(true);
                                    setNoteDetail(rowContent);
                                }}>
                                view
                            </div>
                        ) : (
                            '-'
                        )}
                    </>
                );
            },
        },
        // {
        //     dataField: 'chain_name',
        //     text: 'Chain',
        //     sort: true,
        //     headerClasses: 'id-custom-cell',
        // },

        // {
        //     dataField: 'status',
        //     text: 'Status',
        //     sort: true,
        //     headerClasses: 'id-custom-cell',
        // },
    ].filter((item) => !item.hidden);

    return (
        <Card>
            <CardBody>
                <Modal
                    isOpen={openNote}
                    toggle={(e) => setOpenNote(!e)}
                    // className={this.state.className}
                    // size={this.state.size}
                >
                    <ModalHeader toggle={(e) => setOpenNote(!e)}>Note</ModalHeader>
                    <ModalBody> {noteDetail} </ModalBody>
                </Modal>
                <h6 style={{ fontWeight: 'bold' }}>
                    {props?.title}{' '}
                    {props?.superIntendentId && (
                        <span
                            onClick={() => props?.setSuperIntendentId('')}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 145, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            {props?.superIntendentId?.user_name}
                            <FaTimes />
                        </span>
                    )}
                    {props?.timelogMissingStatus && (
                        <span
                            onClick={() => props?.setTimelogMissingStatus(false)}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 145, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            timelog missing
                            <FaTimes />
                        </span>
                    )}
                    {props?.worklogMissingStatus && (
                        <span
                            onClick={() => props?.setWorklogMissingStatus(false)}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 145, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            worklog missing
                            <FaTimes />
                        </span>
                    )}
                    {props.jobStatusId && (
                        <span
                            onClick={() => {
                                props.setJobStatusId(undefined);
                            }}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 145, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            {props.jobStatusId?.booking_status}
                            <FaTimes />
                        </span>
                    )}
                    {props.bookingStatusMonth && (
                        <span
                            onClick={() => {
                                props.setBookingStatusMonth(false);
                            }}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 145, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            Month Booking
                            <FaTimes />
                        </span>
                    )}
                    {props.bookingStatusReceivedToday && (
                        <span
                            onClick={() => {
                                props.setBookingStatusReceivedToday(false);
                            }}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 135, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            Received Today
                            <FaTimes />
                        </span>
                    )}
                    {props.bookingStatusToday && (
                        <span
                            onClick={() => {
                                props.setBookingStatusToday(false);
                            }}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 125, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            Today
                            <FaTimes />
                        </span>
                    )}
                </h6>
                {props?.isBookingLoading ? (
                    <Loader />
                ) : (
                    <div
                        className="dashboard-table"
                        style={{
                            maxHeight: '500px',
                            overflowY: 'auto',
                        }}>
                        <ToolkitProvider
                            bootstrap4
                            keyField="activity_id"
                            data={props?.data || []}
                            columns={columns}
                            search>
                            {(props) => (
                                <React.Fragment>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        striped={true}
                                        wrapperClasses="table-responsive"
                                        noDataIndication={() => <NoDataIndication />}
                                    />
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </div>
                )}

                <div style={{ marginTop: '15px' }}>Total count : {props.count}</div>
            </CardBody>
        </Card>
    );
};

export default DashboardTransaction;
