import React from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

const BarChartBetaRevenue = (props) => {
    const {
        setStatistics,
        setTodaysJobCount,
        setSuperintendantCount,
        setLocationCount,
        setVesselCount,
        setJobStatus,
        setYearlyPerformance,
        setSelectedSubSolution,
        setInitialFinanceStatus,
        setWorklogMissingData,
    } = props;
    const series = [
        {
            name: 'Revenue',
            data: props?.data?.map((list) => list?.total_rate || []) ?? [],
        },
    ];

    console.log(props?.data, 'props?.data');
    const options = {
        title: {
            text: props?.title,
            // align: 'center',
        },
        chart: {
            id: 'basic-bar',
            width: '100%',
            height: '500px',
            zoom: {
                enabled: true,
            },

            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setInitialFinanceStatus(false);
                    setWorklogMissingData([]);
                    setSelectedSubSolution(config.w.config.customData[config.dataPointIndex]);
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        colors: ['#3B93A5'],
        dataLabels: {
            // enabled: false,
        },
        xaxis: {
            categories: props?.data?.map((list) => list.name) || [],
            tickPlacement: 'on',
        },
    };

    const data = props?.data?.map((list) => list?.total) ?? [];
    // console.log(data, 'data');
    return (
        <>
            <div style={{ overflowX: 'scroll' }}>
                {/* <h5 className="card-title header-title">{props.title}</h5> */}

                <Chart
                    options={{ ...options, customData: props?.data }}
                    series={series}
                    type="bar"
                    className="apex-charts"
                    height={'500px'}
                />
            </div>
        </>
    );
};

export default BarChartBetaRevenue;
