import React, { useEffect, useState } from 'react';

import { get2FactorQA } from './api.functions';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { reset_qr_code } from 'pages/sections/api.functions';
import { toast } from 'react-toastify';

const TwoFactor_QR = () => {
    const [qrimage, setQrimage] = useState(undefined);
    const [qrDetail, setQrDetail] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const res = await get2FactorQA();
            setQrimage(res.data);
            setQrDetail(res);
        })();
    }, []);

    const userDetail = JSON.parse(localStorage.getItem('br_user'));

    const regenerate_status = () => {
        setIsSubmitting(true);
        reset_qr_code({ status: true, user_id: userDetail?.id })
            .then((response) => {
                setIsSubmitting(false);
                toast.success('Successfully Activated the QR');
                history.push('/account/send-otp');
            })
            .catch((error) => {
                setIsSubmitting(false);
                toast.error('Erroring activating the QR code');
            });
    };
    return (
        <div className="setting-tab-holder">
            <Card className="TwoFactor_QR">
                <CardBody>
                    <div style={{ display: 'grid', placeContent: 'center', textAlign: 'center', height: '90vh' }}>
                        <div>
                            <h2 className="heading">Set Up Two Factor Authentication</h2>
                            <div className="sub-title">
                                Download the Microsoft Authenticator App From Your App Store or Play Store.
                            </div>
                            <div>
                                <p component="p" variant="body1" className="p-bold">
                                    Scan the image above to display the 6 digit verification code.
                                </p>
                            </div>
                            <div>
                                <p component="p" variant="body1">
                                    After scanning the QR code image, the app will display a six-digit code that you can
                                    enter while login.
                                </p>
                                <p>For alternative option, you can use secret key - {qrDetail?.secret_misson}</p>
                            </div>
                            <div className="qr-image">{qrimage && <img src={qrimage} />}</div>

                            <button
                                className="btn btn-primary"
                                onClick={() => regenerate_status()}
                                disabled={isSubmitting || false}>
                                {isSubmitting ? 'Activating...' : ' Activate and Proceed'}
                            </button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default TwoFactor_QR;
