import React from 'react';
import SelectComponent from 'react-select';
import { StyledSelect } from './select.style';

const Select = ({
    disabled = false,
    isLoading = false,
    isClearable = true,
    options,
    name,
    required = false,
    label,
    ...rest
}) => {
    return (
        <>
            {Boolean(label) && <p className="mb-1 mt-3 font-weight-bold">{`${label} ${required ? '*' : ''}`}</p>}
            <SelectComponent
                className="basic-single"
                classNamePrefix="select"
                defaultValue={Boolean(isLoading)}
                isLoading={Boolean(isLoading)}
                isClearable={Boolean(isClearable)}
                isRtl={false}
                isSearchable={true}
                style={{ zIndex: 999 }}
                name={name}
                isDisabled={disabled}
                options={options || []}
                {...rest}
            />
        </>
    );
};

export default Select;
