import Loader from 'components/Loader/loader';
import React, { useEffect, useState } from 'react';
import PaginationComponent from 'react-reactstrap-pagination';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { deleteNotifications, getNotifications, readNotifications } from '../api.functions';
import NotificationDetails from './NotificationDetails';
import SingleNotification from './SingleNotification';

const ArchiveDiv = styled.div`
    width: 40%;
    padding: 1.2rem;
    background-color: #fff;
    position: relative;

    .__notification__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 1rem;
    }
`;
const ActiveRecord = styled.div`
    width: 60%;
    padding: 1.2rem;
    background-color: #fff;
`;

const Archive = ({ setTotalArchive }) => {
    const [open, setOpen] = useState(null);

    const [notifications, setNotifications] = useState([]);
    const [results, setResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [is_Restore, setIs_Restore] = useState(false);
    const [deletedPermanently, setDeletedPermanently] = useState(false)
    const [avatar, setAvatar] = useState("");



    const [isLoading, setIsLoading] = useState(false);
    const fetchNotifications = (page_size = "5", page = "1") => {
        setIsLoading(true);
        getNotifications("", "true", page_size, page)
            .then((res) => {
                const data = res || [];
                setResults(data);
                setNotifications(data?.results);
                setIsLoading(false);
                setTotalArchive(data?.count)
                setIs_Restore(data?.results?.id_deleted)
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    };
    const readNotification = (data, id) => {
        // setLoading(true);
        readNotifications(data, id)
            .then((res) => {
                const data = res || [];
                // setResponse(data);
                // setLoading(false);
                if (data?.response_code === 200) {
                    toast.success(data?.message);
                }
                if (data?.message === "Restored Successfully") {
                    setIs_Restore(true);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error(data?.message);
                // setLoading(false);
            });
    };
    const deleteNotification = (data, id) => {
        // setLoading(true);
        deleteNotifications(data, id)
            .then((res) => {
                const data = res || [];
                // setResponse(data);
                // setLoading(false);
                if (data?.response_code === 200) {
                    toast.success(data?.message);
                    setDeletedPermanently(true)
                }

            })
            .catch((e) => {
                console.log(e);
                toast.error(data?.message);
                // setLoading(false);
            });
    };
    // deleteNotifications

    useEffect(() => {
        fetchNotifications(5, currentPage);
        setDeletedPermanently(false);
        const userDetails = JSON.parse(localStorage.getItem('br_user'));
        setAvatar(userDetails?.avatar)
    }, [currentPage, is_Restore, deletedPermanently]);

    if (isLoading) {
        return <Loader />;
    }
    const setCurrentPageNo = (e) => {
        setCurrentPage(e)
    }


    return (
        <>
            {notifications?.length <= 0 ? (
                <h3>No Archived Notifications</h3>
            ) : (
                <>
                    <div style={{ display: "flex" }}>
                        <ArchiveDiv>
                            <div className="__notification__list">
                                {notifications?.map((d) => (
                                    <SingleNotification
                                        id={d?.id}
                                        key={d?.id}
                                        title={d?.subject}
                                        avatar={avatar}
                                        time={d?.created_date}
                                        project={d?.project}
                                        type={d?.module}
                                        isDeleted={d?.archived}
                                        isRead={d?.is_read}
                                        setOpen={setOpen}
                                        obj={d}
                                        deleteNotification={deleteNotification}
                                        readNotification={readNotification}
                                        schedule_survey_date={d?.schedule_survey_date}
                                        vessel_name={d?.vessel_name}
                                    />
                                ))}
                            </div>
                        </ArchiveDiv>
                        <ActiveRecord>
                            {notifications
                                ?.filter((d) => d.id === open)
                                ?.map((fiterData) => (
                                    <NotificationDetails
                                        id={fiterData?.id}
                                        key={fiterData?.id}
                                        title={fiterData?.subject}
                                        avatar={avatar}
                                        time={fiterData?.created_date}
                                        project={fiterData?.project}
                                        type={fiterData?.module}
                                        isDeleted={fiterData?.archived}
                                        isRead={fiterData?.is_read}
                                        setOpen={setOpen}
                                        obj={fiterData}
                                        deleteNotification={deleteNotification}
                                        details={fiterData?.details}
                                        readNotification={readNotification}
                                    />
                                ))}
                        </ActiveRecord>
                    </div>
                    {results?.count > 5 && <PaginationComponent
                        totalItems={results?.count}
                        pageSize={5}
                        onSelect={setCurrentPageNo}
                        defaultActivePage={currentPage}
                    />}
                </>
            )}
        </>
    );
};

export default Archive;
