import React, { useState } from 'react';
import { useFormik } from 'formik';
import TextField from '../../../../../components/textFeild/textFeild';
import { Row, Col } from 'reactstrap';
import SelectField from '../../../../../components/selectField/selectField';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../../../config';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { COMMON_API_ERROR } from '../../../../../constants';

const validationSchema = Yup.object().shape({
    location_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    address1: Yup.string().min(2, 'Too Short!').max(255, 'Too Long!').required('This field is required'),
    // address2: Yup.string().min(2, 'Too Short!').max(255, 'Too Long!').required('This field is required'),
    suburb: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    city: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    location_state: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    postcode: Yup.number().typeError('Postcode must be a number').required('This field is required'),
    country: Yup.string().notOneOf(['Select Country'], 'This field is required').required('Country is required'),
    notes: Yup.string().nullable(),
    active: Yup.string().required('This field is required'),
    territory: Yup.number()
        .min(1, 'This field is required')
        .typeError('This field is required')
        .required('This field is required'),
});

const LocationForm = ({
    country = [],
    actionType = 'Add',
    modelClose = () => {},
    setLocation = () => { },
    initialValues,
    territory = [],
    setNewLocationData,
    reload = () => {},
    fetch = () => {},
}) => {
    let _initialValues =
        actionType === 'Add'
            ? {
                  location_name: '',
                  address1: '',
                  address2: '',
                  suburb: '',
                  city: '',
                  location_state: '',
                  postcode: '',
                  country: '',
                  notes: '',
                  created_by_user: localStorage.getItem('loggedin_user_id'),
                  modified_by_user: '',
                  active: '1',
                  location_latitude: '',
                  location_longitude: '',
                  territory: '',
              }
            : initialValues;

    const [addedLoactionData, setAddedLoactionData] = useState(null);

    // console.log(_initialValues)
    const formik = useFormik({
        initialValues: _initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let req;
            values['user_id'] = localStorage.getItem('loggedin_user_id');
            switch (actionType) {
                case 'Add':
                    req = axios.post(`${config.apiRoot}configuration/create_location`, values);
                    break;
                case 'Edit':
                    req = axios.post(`${config.apiRoot}configuration/update_location`, values);
                    break;
                default:
                    req = axios.post(`${config.apiRoot}configuration/create_location`, values);
                    break;
            }
            req.then((res) => {
                modelClose();
                setLocation(res.data.location_name, res.data.location_id)
                toast.success(res.data.message);
                fetch();
            }).catch((e) => {
                toast.error(e.response.data.message || COMMON_API_ERROR);
            });
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit} id="settingsLocation">
                <Row>
                    <Col lg={12}>
                        <TextField
                            label="Location Name"
                            required={true}
                            name="location_name"
                            value={formik.values.location_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.location_name &&
                                formik.touched.location_name &&
                                formik.errors.location_name
                            }
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Address Line 1"
                            required={true}
                            name="address1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.address1 && formik.touched.address1 && formik.errors.address1}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Address Line 2"
                            required={false}
                            name="address2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.address2 && formik.touched.address2 && formik.errors.address2}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Suburb"
                            required={true}
                            name="suburb"
                            value={formik.values.suburb}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.suburb && formik.touched.suburb && formik.errors.suburb}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="City"
                            required={true}
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.city && formik.touched.city && formik.errors.city}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Location state"
                            required={true}
                            name="location_state"
                            value={formik.values.location_state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.location_state &&
                                formik.touched.location_state &&
                                formik.errors.location_state
                            }
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Postcode"
                            required={true}
                            name="postcode"
                            value={formik.values.postcode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.postcode && formik.touched.postcode && formik.errors.postcode}
                        />
                    </Col>
                    <Col lg={6}>
                        <SelectField
                            label={'Territory'}
                            required={true}
                            name={'territory'}
                            firstLabel={'Select Territory'}
                            list={territory}
                            value={formik.values.territory}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.territory && formik.touched.territory && formik.errors.territory}
                        />
                    </Col>
                    <Col lg={6}>
                        <SelectField
                            label={'Country'}
                            required={true}
                            name={'country'}
                            firstLabel={'Select Country'}
                            list={country}
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.country && formik.touched.country && formik.errors.country}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Lattitude"
                            required={false}
                            // type="number"
                            name="location_latitude"
                            value={formik.values.location_latitude}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.location_latitude &&
                                formik.touched.location_latitude &&
                                formik.errors.location_latitude
                            }
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Longitude"
                            required={false}
                            name="location_longitude"
                            // type="number"
                            value={formik.values.location_longitude}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.location_longitude &&
                                formik.touched.location_longitude &&
                                formik.errors.location_longitude
                            }
                        />
                    </Col>
                    <Col lg={6}>
                        <SelectField
                            label={'Status'}
                            //required={true}
                            name={'active'}
                            firstLabel={'Select Status'}
                            list={[
                                { name: 'Active', value: '1' },
                                { name: 'InActive', value: '0' },
                            ]}
                            value={formik.values.active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.active && formik.touched.active && formik.errors.active}
                        />
                    </Col>
                    <Col lg={12}>
                        <TextField
                            label="Notes"
                            type={'textarea'}
                            name="notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.notes && formik.touched.notes && formik.errors.notes}
                        />
                    </Col>

                    <Col lg={12}>
                        <Button id={'btnSubmit'} type={'submit'}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default LocationForm;
