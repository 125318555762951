import React, { Component, useEffect } from 'react';
import Routes from './routes/Routes';
import { BASE_URL } from './helpers/helper';
import { refreshheaderToken } from './apis/apis';

// setup fake backend
// import { configureFakeBackend } from './helpers';

// Themes

// default
import axios from 'axios';

import './assets/scss/theme.scss';
import { ToastContainer, toast } from 'react-toastify';

import 'accordion-js/dist/accordion.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { refreshToken } from './apis/auth.apis';
import { Cookies } from 'react-cookie';
import Loader from './components/Loader/loader';
import CustomLoaderComponent from './components/custom_loader.compnent/custom.loader.component';
import { connect } from 'react-redux';
import { getSocketResponse } from './redux/notification/actions';
import { useState } from 'react';
import { getUserRole } from 'helpers/api.functions';
// import { isUserAuthenticated } from './helpers/authUtils';
// import { Redirect } from 'react-router-dom';
// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

// configure fake backend
// configureFakeBackend();

/**
 * Main app component
 */
export const Logout = () => {
    let cookies = new Cookies();
    // refreshToken(null);
    localStorage.clear();
    sessionStorage.clear();

    cookies.remove('user', { path: '/' });

    window.open('/account/logout', '_self');
};

const setupWebSocket = ({ getSocketResponse }) => {
    const userID = localStorage.getItem('loggedin_user_id');

    if (userID) {
        const socketURL = `${process.env.REACT_APP_SOCKET_URL}/ws/message_recieve/${userID}/`;
        const webSocket = new WebSocket(socketURL);

        webSocket.onopen = () => {
            console.log('WebSocket connection established');
        };

        webSocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const messageCount = message?.data?.count;

            if (!message?.data?.isRead && messageCount) {
                toast.info('You have new notifications.');
            }
            getSocketResponse(message?.data);
            if (typeof messageCount === 'number') {
                localStorage.setItem('notification_count', messageCount);
                // setCount(messageCount);
            }
        };

        webSocket.onclose = (event) => {
            console.log(`WebSocket connection closed with code ${event.code} and reason "${event.reason}"`);
        };

        webSocket.onerror = (error) => {
            console.error('WebSocket encountered error:', error.message, 'Closing socket');
            webSocket.close();
        };

        return webSocket;
    }

    return null;
};

const App = ({ getSocketResponse }) => {
    const [isLoading, setLoading] = React.useState(false);
    const [webSocket, setWebSocket] = useState(null);

    // const [count, setCount] = useState(0);
    const getpermission = () => {
        let payload = {
            active: '',
            userrole_id: JSON.parse(localStorage.getItem('loggedin_user_role')).userrole_id,
            user_id: localStorage.getItem('loggedin_user_id'),
        };

        getUserRole(payload)
            .then((res) => {
                localStorage.setItem('permissions', JSON.stringify(res?.data?.[0]?.permissions));
            })
            .catch(() => {});
    };

    useEffect(() => {
        const userID = localStorage.getItem('loggedin_user_id');
        if (userID) {
            getpermission();
        }
    }, []);

    useEffect(() => {
        refreshAuthorizationToken();
        const intervalId = setInterval(() => {
            refreshAuthorizationToken();
        }, 30000000);

        return () => {
            clearInterval(intervalId);
            if (webSocket) {
                webSocket.close();
            }
        };
    }, []);
    useEffect(() => {
        const ws = setupWebSocket({ getSocketResponse });
        setWebSocket(ws);
    }, []);

    // renderRedirectToRoot = () => { 30000000
    //     const isAuthTokenValid = isUserAuthenticated();
    //     if (isAuthTokenValid) {
    //         return <Redirect to="/" />;
    //     }
    // };

    const refreshAuthorizationToken = () => {
        let rf_token = null;
        let login_data = JSON.parse(localStorage.getItem('br_token_object'));
        rf_token = login_data?.refresh_token || null;
        // if (rf_token !== null && rf_token !== undefined && rf_token !== '') {
        if (rf_token === null && rf_token === undefined && rf_token !== '') {
            this.setState({ isLoading: true });
            let body = new FormData();
            body.append('refresh_token', login_data.refresh_token);
            body.append('grant_type', 'refresh_token');

            const url = BASE_URL + `accounts/sign_in`;
            const headers = {
                'Access-Control-Allow-Origin': '*',
                Authorization:
                    'Basic NWFwTDZINmc4N1UxOFlETnNlN2Q5cXVnMW9PVTQ3S1pCc3R0cmVVVjp6RmlsSUx6V0o5czA0NmhHcVYzd3owSUVZRGdGR0dDUVMyQTgwV3hlZk02aG05ZHRZdThFM3JwSmVtYjROdnRLd0JWTFlZbGFJTjM0MHRBdmRDQUcyVWludFNyU2JqOGpzWnliSnBOOHV4U254SHJrdlcxU1ZQREJkTEE1ZkR6Vw==',
            };
            axios
                .post(url, body, { headers: headers })

                .then((response) => {
                    let token = response.data.token;

                    refreshheaderToken(token.access_token);

                    localStorage.setItem('br_login_response', JSON.stringify(response.data));
                    localStorage.setItem('br_access_token', token.access_token);
                    localStorage.setItem('is_bivac_user', response.data.is_bivac);
                    localStorage.setItem('br_token_object', JSON.stringify(token));
                    setLoading(false);
                })
                .then()
                .catch((err) => {
                    setLoading(false);
                    Logout();
                });
        } else {
            const local_token = localStorage.getItem('br_access_token');
            refreshheaderToken(local_token);
            setLoading(false);
        }
    };

    toast.configure();
    return (
        <>
            {isLoading ? (
                <CustomLoaderComponent open />
            ) : (
                <Routes>
                    <ToastContainer />
                </Routes>
            )}{' '}
        </>
    );
};
const mapStateToProps = ({}) => ({});

const mapDispatchToProps = {
    getSocketResponse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(App);
