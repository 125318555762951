import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
import './pagination.scss';
import { Input } from 'reactstrap';
import '../../index.css';

const Pagination = (props) => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    // if (currentPage === 0 || paginationRange.length < 2) {
    //     return null;
    // }

    const onNext = () => {
        onPageChange({ page: currentPage + 1, pageSize: pageSize });
    };

    const onPrevious = () => {
        onPageChange({ page: currentPage - 1, pageSize: pageSize });
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <>
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-flex-start  col-6">
                    <React.Fragment>
                        <label className="m-0 ">Show</label>
                        <Input
                            type="select"
                            name="select"
                            id="no-entries"
                            className="custom-select custom-select-sm mx-2 px-2 col-1 p-space"
                            defaultValue={pageSize}
                            onChange={(e) =>
                                onPageChange({
                                    page: 1,
                                    pageSize: Number(e.target.value),
                                })
                            }>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                        </Input>
                        <label className="m-0"> of {totalCount} entries</label>
                    </React.Fragment>
                </div>
                <div className="">
                    <ul className={classnames('pagination-container ', { [className]: className })}>
                        <li
                            className={classnames('pagination-item ', {
                                disabled: currentPage === 1,
                            })}
                            onClick={onPrevious}>
                            <div className="arrow left " />
                        </li>
                        {paginationRange.map((pageNumber, ind) => {
                            if (pageNumber === DOTS) {
                                return (
                                    <li key={`a-${ind}`} className="pagination-item dots">
                                        &#8230;
                                    </li>
                                );
                            }

                            return (
                                <li
                                    key={`b-${ind}`}
                                    className={classnames('pagination-item', {
                                        selected: pageNumber === currentPage,
                                    })}
                                    onClick={() => onPageChange({ page: pageNumber, pageSize: pageSize })}>
                                    {pageNumber}
                                </li>
                            );
                        })}
                        <li
                            key={`c`}
                            className={classnames('pagination-item', {
                                disabled: currentPage === lastPage,
                            })}
                            onClick={onNext}>
                            <div className="arrow right" />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Pagination;
