import React, { Component } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Input } from 'reactstrap';

import Swal from 'sweetalert2';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import HeaderNav from '../../../components/headerNav/headerNav';

// import Addactivity from './addactivity';
import * as FeatherIcon from 'react-feather';
import Addactivity from '../../sections/addactivity';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
const DashboardActivitySectionBeta = (props) => {
    const isfollowup = false;
    const ColoumnWrapper = ({ children }) => {
        return <Col lg={6}>{children}</Col>;
    };

    const bookingStatusData = [
        {
            booking_status_id: 1,
            name: 'Open',
        },
        {
            booking_status_id: 4,
            name: 'Cancelled',
        },
        {
            booking_status_id: 5,
            name: 'Completed',
        },
        {
            booking_status_id: 10,
            name: 'Approved',
        },
    ];

    const NoDataIndication = () => (
        <div
            className="EmptyBlock"
            style={{
                minHeight:
                    JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team' ||
                    JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent'
                        ? '150px'
                        : '35px',
            }}>
            <div className="rect_Emplty">No Data Available</div>
        </div>
    );

    const { SearchBar } = Search;

    const columns = [
        {
            dataField: 'booking_id',
            hidden: props.isbookinghidden,
            text: 'Status',
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'booking_status_id',
            text: 'Status',
            headerClasses: 'id-custom-cell',

            formatter: (rowContent, row) => {
                return (
                    <a href="#booking">
                        <div
                            style={{ color: 'steelblue', cursor: 'pointer' }}
                            onClick={() => {
                                props.handleJobStatus(row);
                            }}>
                            {bookingStatusData.find((label) => label.booking_status_id == rowContent)?.name}
                        </div>
                    </a>
                );
            },
            footer: 'Total',
        },
        {
            dataField: 'count',
            text: 'Total Orders',
            headerClasses: 'id-custom-cell',
            footer: (columnData) => Number(columnData?.reduce((acc, item) => acc + item, 0).toFixed(2)),
        },
        {
            dataField: 'total',
            text: 'Total Revenue',
            headerClasses: 'id-custom-cell',
            hidden: props.isRevenueHidden,
            footer: (columnData) => Number(columnData?.reduce((acc, item) => acc + item, 0).toFixed(2)),
        },
    ].filter((item) => !item.hidden);

    const columnsCancelled = [
        {
            dataField: 'booking_id',
            hidden: props.isbookinghidden,
            text: 'Status',
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'booking_status_id',
            text: 'Status',
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return (
                    <a href="#booking">
                        <div
                            style={{ color: 'steelblue', cursor: 'pointer' }}
                            onClick={() => {
                                props.handleJobStatus(row);
                            }}>
                            {bookingStatusData.find((label) => label.booking_status_id == rowContent)?.name}
                        </div>
                    </a>
                );
            },
            footer: 'Total',
        },
        {
            dataField: 'count',
            text: 'Total Orders',
            headerClasses: 'id-custom-cell',

            footer: (columnData) => Number(columnData?.reduce((acc, item) => acc + item, 0).toFixed(2)),
        },
        {
            dataField: 'total',
            text: 'Total Revenue',
            headerClasses: 'id-custom-cell',
            hidden: props.isRevenueHidden,
            footer: (columnData) => Number(columnData?.reduce((acc, item) => acc + item, 0).toFixed(2)),
        },
    ].filter((item) => !item.hidden);
    let sumOpenCompleted =
        props &&
        props.data &&
        props?.data
            ?.filter((list) => list.booking_status_id !== 4)
            ?.reduce(function (a, b) {
                return a + b.total;
            }, 0);
    let sumCancelled =
        props &&
        props.data &&
        props?.data
            ?.filter((list) => list.booking_status_id === 4)
            ?.reduce(function (a, b) {
                return a + b.total;
            }, 0);
    // if (Boolean(this.state.customerLoading)) return <Loader />;
    // if (Boolean(this.state.customerError)) return <Error message={this.state.customerError} />;

    return (
        <div className="dashboard-table-job-status">
            <div className={props.disableCard ? '' : 'card'}>
                <div className={props.disableCard ? '' : 'card-body'}>
                    {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingBottom: '0.3rem',
                        }}>
                        <div></div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            {' '}
                            <FaArrowUp
                                style={{
                                    backgroundColor: '#DCD2FE',
                                    padding: '0.3rem',
                                    borderRadius: '4px',
                                    fontSize: '1.5rem',
                                    color: '',
                                }}
                            />{' '}
                            <span>{sumOpenCompleted}</span>
                        </div>
                    </div> */}
                    <ToolkitProvider
                        bootstrap4
                        keyField="activity_id"
                        // data={this.state.records}
                        data={
                            (props && props.data && props?.data?.filter((list) => list.booking_status_id !== 4)) || []
                        }
                        columns={columns}
                        search>
                        {(props) => (
                            <React.Fragment>
                                <Row>
                                    <Col>
                                        {props.navList && (
                                            <div>
                                                <HeaderNav
                                                    navList={props.navList}
                                                    selectedIndex={props.tab}
                                                    onNavClick={props.onNavClick}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                                <BootstrapTable
                                    {...props.baseProps}
                                    bordered={false}
                                    // defaultSorted={this.state.defaultSorted}
                                    wrapperClasses="table-responsive"
                                    noDataIndication={() => <NoDataIndication />}
                                    // rowEvents={{ onClick: (e, ...arg) => onRowClick(...arg) }}
                                />
                            </React.Fragment>
                        )}
                    </ToolkitProvider>

                    {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            paddingBottom: '0.3rem',
                        }}>
                        <div></div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            {' '}
                            <FaArrowDown
                                style={{
                                    backgroundColor: '#AFDAFF',
                                    padding: '0.3rem',
                                    borderRadius: '4px',
                                    fontSize: '1.5rem',
                                    color: '',
                                }}
                            />{' '}
                            <span>{sumCancelled}</span>
                        </div>
                    </div> */}

                    <ToolkitProvider
                        bootstrap4
                        keyField="activity_id"
                        // data={this.state.records}
                        // data={[{ status: 'Cancelled', total_order: '53', total_revenue: '4350' }]}
                        data={
                            (props && props.data && props?.data?.filter((list) => list.booking_status_id === 4)) || []
                        }
                        columns={columnsCancelled}
                        search>
                        {(props) => (
                            <React.Fragment>
                                <Row>
                                    <Col>
                                        {props.navList && (
                                            <div>
                                                <HeaderNav
                                                    navList={props.navList}
                                                    selectedIndex={props.tab}
                                                    onNavClick={props.onNavClick}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                                <BootstrapTable
                                    {...props.baseProps}
                                    bordered={false}
                                    // defaultSorted={this.state.defaultSorted}
                                    // pagination={paginationFactory({
                                    //     sizePerPage: 25,
                                    //     sizePerPageRenderer: sizePerPageRenderer,
                                    //     sizePerPageList: [
                                    //         { text: '5', value: 5 },
                                    //         { text: '10', value: 10 },
                                    //         { text: '25', value: 25 },
                                    //         { text: 'All', value: this.state.records.length },
                                    //     ],
                                    // })}
                                    wrapperClasses="table-responsive"
                                    noDataIndication={() => <NoDataIndication />}
                                    // rowEvents={{ onClick: (e, ...arg) => onRowClick(...arg) }}
                                />
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </div>
            </div>
        </div>
    );
};

export default DashboardActivitySectionBeta;
