
import React, { useEffect, useState } from "react";

import config from "../config";
// import { COMMON_API_ERROR ,AUTHORIZATION_VIEW_ERROR} from "../../../constants";
import { toast } from 'react-toastify';
import { getUserRole } from "../helpers/api.functions";
function usePermissionAuth(props) {

    // console.log(JSON.parse(localStorage.getItem('loggedin_user_role')));

    const [permissions, setPermissions] = useState([]);


    useEffect(() => {
        getpermission();
    }, [])

    const getpermission = () => {
        let payload = {
            active: '',
            userrole_id: JSON.parse(localStorage.getItem('loggedin_user_role')).userrole_id,
            user_id:localStorage.getItem('loggedin_user_id'),
        };

        getUserRole(payload)
            .then((res) => {
                // setCustomerLoading(false);
                setPermissions(res?.data[0]?.permissions);
            })
            .catch(() => {
                // setCustomerLoading(false);
                // setCustomerError(COMMON_API_ERROR);
            });

        // setPermissions (
        // [{"role_module_permission_id":13,"module_id":1,"userrole_id":6,"create":0,"read":1,"update":0,"delete":0,"approve":0},{"role_module_permission_id":14,"module_id":2,"userrole_id":6,"create":0,"read":1,"update":0,"delete":0,"approve":0},{"role_module_permission_id":15,"module_id":3,"userrole_id":6,"create":0,"read":1,"update":0,"delete":0,"approve":0},{"role_module_permission_id":16,"module_id":4,"userrole_id":6,"create":0,"read":0,"update":0,"delete":0,"approve":0},{"role_module_permission_id":17,"module_id":5,"userrole_id":6,"create":0,"read":0,"update":0,"delete":0,"approve":0},{"role_module_permission_id":18,"module_id":6,"userrole_id":6,"create":0,"read":0,"update":0,"delete":0,"approve":0}]);

    }

    return {
        setPermissions,
        permissions

    }
}

export default usePermissionAuth;

