import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import usePermissionAuth from '../../../routes/userauthentication';
import DashboardActivitySectionBeta from './dashboardactivityBeta';
import Loader from 'components/Loader/loader';
import LoaderBeta from 'components/LoaderBeta/loader';

const TasksBeta = (props) => {
    const permissions = usePermissionAuth();

    return (
        <div style={{ height: '395px' }}>
            <div className="p-2">
                {/* <Button className="float-right mt-2" size={'sm'} color="primary">
                    View All
                </Button> */}
                <h6 style={{ margin: '0', fontWeight: 'bold', marginBottom: '0.5rem' }}>{props.title}</h6>

                <PerfectScrollbar
                    style={{
                        height:
                            JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team' ||
                            JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent'
                                ? '380px'
                                : '295px',
                        maxHeight:
                            JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team' ||
                            JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent'
                                ? '380px'
                                : '295px',
                    }}>
                    {/* <TaskList>
                        {props.data &&
                            props.data.length !== 0 &&
                            props.data.map((item, i) => (
                                <TaskItem
                                    {...props}
                                    title={item.title}
                                    key={i}
                                    due_date={item.date ? moment(item.date).format('DD-MMM-YYYY') : ''}
                                    className="mt-2"
                                />
                            ))}
                    </TaskList> */}
                    {props.isLoading ? (
                        <LoaderBeta height="300px" />
                    ) : (
                        <div style={{ marginTop: '1rem' }}>
                            {props.data ? (
                                <DashboardActivitySectionBeta
                                    booking_id={''}
                                    backgroundColor="#597dbc"
                                    color="#fff"
                                    disableCard={true}
                                    isRevenueHidden={props.isRevenueHidden}
                                    isbookinghidden={true}
                                    listAll={true}
                                    transactionRef={props.transactionRef}
                                    handleJobStatus={props.handleJobStatus}
                                    data={props.data}
                                    // permissions={permissions?.permissions}
                                    {...permissions}
                                />
                            ) : null}
                        </div>
                    )}
                </PerfectScrollbar>
            </div>
        </div>
    );
};

export default TasksBeta;
