// import { ServInstance } from "../config";
import axios from 'axios';
import { BASE_URL } from 'helpers/helper';
import { Redirect } from 'react-router-dom';

const servInstance = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*', Authorization: null };

export const refreshheaderToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        axios.defaults.headers.common['Authorization'] = null;
    }
};

export const Logout = () => {
    // refreshToken(null);
    localStorage.clear();
    sessionStorage.clear();

    window.open('/account/logout', '_self');
};

export const customRefreshingToken = () => {
    let rf_token = null;
    let login_data = JSON.parse(localStorage.getItem('br_token_object'));
    rf_token = login_data?.refresh_token || null;

    // this.setState({ isLoading: true });
    let body = new FormData();
    body.append('refresh_token', login_data?.refresh_token);
    body.append('grant_type', 'refresh_token');

    const url = BASE_URL + `accounts/sign_in`;
    const headers = {
        'Access-Control-Allow-Origin': '*',
        Authorization:
            'Basic NWFwTDZINmc4N1UxOFlETnNlN2Q5cXVnMW9PVTQ3S1pCc3R0cmVVVjp6RmlsSUx6V0o5czA0NmhHcVYzd3owSUVZRGdGR0dDUVMyQTgwV3hlZk02aG05ZHRZdThFM3JwSmVtYjROdnRLd0JWTFlZbGFJTjM0MHRBdmRDQUcyVWludFNyU2JqOGpzWnliSnBOOHV4U254SHJrdlcxU1ZQREJkTEE1ZkR6Vw==',
    };

    axios
        .post(url, body, { headers: headers })

        .then((response) => {
            let token = response.data.token;

            refreshheaderToken(token.access_token);

            localStorage.setItem('br_login_response', JSON.stringify(response.data));
            localStorage.setItem('br_access_token', token.access_token);
            localStorage.setItem('is_bivac_user', response.data.is_bivac);
            localStorage.setItem('br_token_object', JSON.stringify(token));
            // this.setState({ isLoading: false });
        })

        .catch((err) => {
            Logout();
        });
};

export const api = {
    get: (URL, queryParams, type = {}) =>
        axios
            .get(servInstance + URL, queryParams, type)
            .then((res) => res.data)
            .catch((err) => {
                if (err.response?.status === 401) {
                    customRefreshingToken();
                }
            }),
    post: (URL, reqData = {}) =>
        axios
            .post(servInstance + URL, reqData)
            .then((res) => res.data)
            .catch((err) => {
                console.log({ err });
                if (err.response?.status === 401) {
                    customRefreshingToken();
                }
                return err;
            }),

    put: (URL, reqData = {}) =>
        axios
            .put(servInstance + URL, reqData)
            .then((res) => res.data)
            .catch((err) => {
                if (err.response?.status === 401) {
                    customRefreshingToken();
                }
            }),

    patch: (URL, reqData = {}) =>
        axios
            .patch(servInstance + URL, reqData)
            .then((res) => res.data)
            .catch((err) => {
                if (err.response?.status === 401) {
                    customRefreshingToken();
                }
            }),

    delete: (URL, reqData = {}) => axios.delete(servInstance + URL, reqData).then((res) => res.data),
};
export const downloadApi = {
    get: (URL, queryParams, type = {}) => axios.get(servInstance + URL, queryParams, type).then((res) => res),
};
export default api;
