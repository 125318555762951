import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Lock } from 'react-feather';

import {
    Button,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';
import { get2FactorStatus, setChange2FactorAuth, verify2FA } from './api.functions';
import TwoFactor_QR from './TwoFactorQR';
import { toast } from 'react-toastify';

const TwoFaSetup = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isTwoFactor, setIsTwoFactor] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const handleRedirect = () => {
        // history.push('/two-factor-qr');
    };

    const toggleTwoFactorAuthentication = async () => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            const data = await setChange2FactorAuth({ status: !isTwoFactor });
            if (data?.status) {
                toast.success('Two Factor Authentication is enabled');
            }
            if (data?.status === false) {
                toast.success('Two Factor Authentication is disabled');
            }

            setIsTwoFactor((prev) => !prev);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const fetchTwoFactorAuthenticationDetails = async () => {
        try {
            setIsLoading(true);
            const res = await get2FactorStatus();
            setIsTwoFactor(res?.status);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const reverify = async () => {
        toggle();
    };
    const toggle = () => {
        setOpenModal(!openModal);
    };

    useEffect(() => {
        fetchTwoFactorAuthenticationDetails();
    }, []);
    const selfRole = JSON.parse(localStorage?.getItem('loggedin_user_role'));

    return (
        <>
            {/* {isTwoFactor ? <TwoFactor_QR /> : null} */}

            {/* Modal for Verifying 2FA */}
            <Modal isOpen={openModal} toggle={toggle} size={'lg'}>
                <ModalHeader toggle={toggle}>Verify</ModalHeader>
                <Formik
                    initialValues={{ token: '' }}
                    onSubmit={async (values) => {
                        try {
                            const data = await verify2FA(values);
                            if (data?.response_code === 200) {
                                await toggleTwoFactorAuthentication();
                                toggle();
                            } else {
                                toast.error(data?.response?.data?.message || "Couldn't verify Secret Key");
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.message || "Couldn't verify Secret Key");
                        }
                    }}>
                    {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                        <>
                            <AvForm onValidSubmit={handleSubmit} className="authentication-form">
                                <ModalBody>
                                    <p style={{ color: 'red' }} component="p" variant="body1">
                                        Caution: This action is necessary to ensure a seamless transition to the
                                        enhanced security measures. Once 2FA is enabled or disabled, all active sessions
                                        will be terminated, and users will need to log in again with their credentials
                                        and complete the 2FA setup process with Microsoft Authenticator App. Are you
                                        sure you want to proceed?
                                    </p>
                                    <AvGroup className="mb-3">
                                        <Label for="password">Secret Key</Label>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text">
                                                    <Lock className="icon-dual" />
                                                </span>
                                            </InputGroupAddon>
                                            <AvInput
                                                type="text"
                                                name="token"
                                                id="token"
                                                placeholder="Enter your secret key"
                                                value={values.token}
                                                required
                                                onChange={handleChange}
                                                autoComplete="off"
                                            />
                                        </InputGroup>
                                    </AvGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" className="ml-1" onClick={toggle}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={isSubmitting} color="primary" className="ml-1">
                                        Yes, Proceed
                                    </Button>
                                </ModalFooter>
                            </AvForm>
                        </>
                    )}
                </Formik>
            </Modal>

            {selfRole?.userrole_name === 'Super Admin' ? (
                <Card className="two-fa-holder">
                    <CardBody>
                        <div className="holder">
                            <div className="ico-holder">
                                {/* <img src="/assets/icons/shield.svg" alt="sheild" /> */}
                            </div>
                            <h2 component="h2" variant="h5">
                                Dual Layer Security System
                            </h2>
                            <p component="p" variant="body1">
                                Two-factor authentication adds an additional layer of security to your account by
                                requiring more than just a password to sign in.
                            </p>

                            {isLoading ? (
                                <>Loading...</>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    onClick={async () => {
                                        if (!isTwoFactor) {
                                            handleRedirect();
                                        }
                                        reverify();
                                        // await toggleTwoFactorAuthentication();
                                    }}
                                    variant={`${isTwoFactor ? 'outlined' : 'contained'}`}
                                    style={{ borderWidth: isTwoFactor ? '2px' : 'auto' }}>
                                    {`${isTwoFactor ? 'Disable' : 'Enable'} Two Factor Authentication`}
                                </button>
                            )}
                        </div>
                        {/* <div className="link-holder">
                    <Link to={PublicRoute.TWO_FACTOR_TERMS} className="link-button">
                        Learn more{" "}
                        <img
                            src="/assets/icons/arrow-up-right.svg"
                            alt="sheild"
                            className="right"
                        />
                    </Link>
                </div> */}
                    </CardBody>
                </Card>
            ) : (
                'Not Authorized'
            )}
        </>
    );
};

export default TwoFaSetup;
