// import { Box } from '@mui/material';
import { embedDashboard } from '@superset-ui/embedded-sdk';
// import { userDataStore } from 'globalStates/userData';
import React from 'react';
import { useEffect } from 'react';
import './supersetDashboard.css';

const token =
'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InVzZXJuYW1lIjoiYW5hbHl0aWNzIiwiZmlyc3RfbmFtZSI6IkJ1c2luZXNzIiwibGFzdF9uYW1lIjoiSW50ZWxsaWdlbmNlIn0sInJlc291cmNlcyI6W3siaWQiOiJlZWU1ZGI5NC03MTBjLTQ2ODEtYjE4Yi1mNDE3Y2JlYWNlNWEiLCJ0eXBlIjoiZGFzaGJvYXJkIn1dLCJybHNfcnVsZXMiOltdLCJpYXQiOjE2OTc3MzY2OTguNjc0NDU0NSwiZXhwIjo0Njk3NzM2Njk4LjY3NDQ1NSwiYXVkIjoiaHR0cDovLzAuMC4wLjA6ODA4MC8iLCJ0eXBlIjoiZ3Vlc3QifQ.EkBXuDvuySl8y7kqVtciqNt4HQkbrPpBPEGVCVe4U2k';
// const fetchGuestTokenFromBackend = async (userInfo: any) => {
//   let guestJwtToken: string;
//   const payload = {
//     user: userInfo,
//     resources: [{ type: 'dashboard', id: process.env.REACT_APP_SUPERSET_UUID }],
//     rls: [{ clause: `clientId=${userInfo?.clientId}` }],
//   };
//   const { data, status } = await postAPI(
//     process.env.REACT_APP_GUEST_TOKEN_API || 'http://default_guest_token_url',
//     payload,
//   );
//   if (status === 200 || (status > 200 && status < 300)) {
//     guestJwtToken = data?.token;
//   }
//   console.log('guestJwtToken Received', guestJwtToken);
// };

// const fetchGuestTokenFromSuperset = async () => {
//   try {
//     const { data, status } = await axios.post(
//       'https://superset-bas-dev.bridge.propelmarine.com/security/guest_token/',
//       {
//         user: {
//           first_name: 'BAS',
//           last_name: 'User',
//           username: 'bas',
//         },
//         rls: [],
//         resources: [
//           {
//             type: 'dashboard',
//             id: '696dac36-0833-419b-9d8b-7e9b3aee6fc2',
//           },
//         ],
//       },
//       {
//         headers: {
//           // 'Content-Type': 'application/json',
//           Authorization: `Bearer ${access_token}`, // das ist ist das Token aus dem ersten Request
//         },
//       },
//     );
//     console.log({ data, status });
//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// };

async function fetchGuestToken() {
    // console.log('fetch guest token');
    return process.env.REACT_APP_DASHBOARD_TOKEN || '';
}

function SupersetDashboard() {
    // const { userName, userType, clientId, ...rest } = userDataStore();

    useEffect(() => {
        embedDashboard({
            id: process.env.REACT_APP_DASHBOARD_UUID, // given by the Superset embedding UI
            supersetDomain: 'https://superset-bas-dev.bridge.propelmarine.com',
            mountPoint: document.getElementById('superset-container'), // any html element that can contain an iframe
            // fetchGuestToken: async () => {
            //   const token = await fetchGuestTokenFromBackend({ userName, userType, clientId });
            //   console.log('token', token);
            //   return token;
            // },
            fetchGuestToken,
            dashboardUiConfig: {
                // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
                hideTitle: true,
                filters: {
                    expanded: false,
                },
            },
        });
    }, []);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    overflowX: 'auto',
                }}>
                <div className="my-superset-container" id="superset-container">
                    <iframe id="superset-container" title="BAS Dashboard" />
                </div>
            </div>

            {/* <button
        onClick={() =>
          embedDashboard({
            id: '696dac36-0833-419b-9d8b-7e9b3aee6fc2', // given by the Superset embedding UI
            supersetDomain: 'https://superset-bas-dev.bridge.propelmarine.com',
            mountPoint: document.getElementById('superset-container') as HTMLElement, // any html element that can contain an iframe
            // fetchGuestToken: async () => {
            //   const token = await fetchGuestTokenFromBackend({ userName, userType, clientId });
            //   console.log('token', token);
            //   return token;
            // },
            fetchGuestToken: async () => {
              console.log('fetch');
              return token;
            },
            dashboardUiConfig: {
              // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
              hideTitle: true,
              filters: {
                expanded: false,
              },
            },
          })
        }>
        start chart
      </button> */}

            {/* <button
        onClick={async () => {
          const result = await fetchGuestTokenFromBackend({ userName, userType, clientId });
          console.log({ result });
        }}>
        FetchGuestTokenTest
      </button> */}

            {/* <button onClick={fetchGuestTokenFromSuperset}>FetchGuestTokenFromSuperset</button> */}
        </div>
    );
}


export default SupersetDashboard;
