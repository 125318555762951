import React from 'react';
import { HeaderNavStyle } from './headerNav.style';
import { useLocation } from 'react-router-dom';

const HeaderNav = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // Get search parameters
    const active = searchParams.get('active');
    return (
        <HeaderNavStyle>
            {props.navList &&
                props.navList.length !== 0 &&
                props.navList.map((nav, i) => (
                    <div
                        key={nav + i}
                        onClick={() => props.onNavClick(nav, i)}
                        className={`menuItem ${active == i ? 'active' : ''}`}>
                        {nav}
                    </div>
                ))}
        </HeaderNavStyle>
    );
};

export default HeaderNav;
