import React from 'react';
import { Spinner, Card, CardBody } from 'reactstrap';
import './loader.css';

const Loader = ({ fromModal = false, fullheight = false }) => {
    return (
        <div className={`${fromModal ? '' : 'card'} ${fullheight ? 'h-100' : ''}`}>
            <CardBody className="loaderContainer">
                <Spinner>Loading...</Spinner>
            </CardBody>
        </div>
    );
};

export default Loader;
