// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';
import { refreshheaderToken } from '../../apis/apis';
import { BASE_URL } from '../../helpers/helper';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    console.log('cookie user', user);
    let cookies = new Cookies();
    if (user) {
        cookies.set('user', JSON.stringify(user), { path: '/' });
    } else cookies.remove('user', { path: '/' });
};

const setToken = (token, user, role, permissions) => {
    // console.log(user);

    localStorage.setItem('br_access_token', token.access_token);
    localStorage.setItem('br_token_object', JSON.stringify(token));
    localStorage.setItem('br_user', JSON.stringify(user));
    localStorage.setItem('br_user_role', JSON.stringify(role));
    localStorage.setItem('br_user_permissions', JSON.stringify(permissions));

    // let cookies = new Cookies();
    // if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    // else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
// function* login({ payload: { email, password } }) {
//     // const csrftokenCookie = Cookies.get('csrftoken');
//     // console.log("csrf", csrftokenCookie);
//     const options = {
//         body: JSON.stringify({ email, password, "user_type": "1" }),
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             // 'Authorization': 'Basic NWFwTDZINmc4N1UxOFlETnNlN2Q5cXVnMW9PVTQ3S1pCc3R0cmVVVjp6RmlsSUx6V0o5czA0NmhHcVYzd3owSUVZRGdGR0dDUVMyQTgwV3hlZk02aG05ZHRZdThFM3JwSmVtYjROdnRLd0JWTFlZbGFJTjM0MHRBdmRDQUcyVWludFNyU2JqOGpzWnliSnBOOHV4U254SHJrdlcxU1ZQREJkTEE1ZkR6Vw=='
//         },
//     };

//     try {

//         const response = yield call(fetchJSON, BASE_URL + 'accounts/sign_in', options);
//         if (response.response_code == 200) {

//             // console.log(response,'response')
//             let payload = {
//                 active: '',
//                 userrole_id: response.user_role.userrole_id
//             };

//             const options_2 = {
//                 body: JSON.stringify({ active: "", userrole_id: response.user_role.userrole_id }),
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//             };

//             const response_permission = yield call(fetchJSON, BASE_URL + 'system/list_user_role', options_2);
//             if (response_permission.response_code == 200) {
//                 console.log(response_permission.data);
//                 localStorage.setItem('permissions', JSON.stringify(response_permission?.data?.[0]?.permissions));
//             }

//             setSession(response);

//             yield put(loginUserSuccess(response));
//         } else {
//             yield put(loginUserFailed(response.message));
//         }
//     } catch (error) {

//         let message;
//         switch (error.status) {
//             case 500:
//                 message = 'Internal Server Error';
//                 break;
//             case 401:
//                 message = 'Invalid credentials';
//                 break;
//             default:
//                 message = error;
//         }
//         yield put(loginUserFailed(message));
//         setSession(null);
//     }
// }
function* login({ payload: { email, password } }) {
    // const csrftokenCookie = Cookies.get('csrftoken');
    // console.log("csrf", csrftokenCookie);
    const form = new FormData();

    form.append('username', email ?? '');
    form.append('password', password ?? '');
    form.append('grant_type', 'password');

    const options = {
        body: form,
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            Authorization:
                'Basic NWFwTDZINmc4N1UxOFlETnNlN2Q5cXVnMW9PVTQ3S1pCc3R0cmVVVjp6RmlsSUx6V0o5czA0NmhHcVYzd3owSUVZRGdGR0dDUVMyQTgwV3hlZk02aG05ZHRZdThFM3JwSmVtYjROdnRLd0JWTFlZbGFJTjM0MHRBdmRDQUcyVWludFNyU2JqOGpzWnliSnBOOHV4U254SHJrdlcxU1ZQREJkTEE1ZkR6Vw==',
        },
    };

    try {
        const response = yield call(fetchJSON, BASE_URL + 'accounts/sign_in', options);

        // const response = localStorage
        if (response.response_code == 200) {
            console.log('response', response);
            let payload = {
                active: '',
                userrole_id: response.user_role.userrole_id,
                user_id: localStorage.getItem('loggedin_user_id'),
            };

            const options_2 = {
                body: JSON.stringify({
                    active: '',
                    userrole_id: response.user_role.userrole_id,
                    user_id: localStorage.getItem('loggedin_user_id'),
                }),
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${response.token.access_token}` },
            };

            const response_permission = yield call(fetchJSON, BASE_URL + 'system/list_user_role', options_2);
            if (response_permission.response_code == 200) {
                console.log(response_permission.data);

                let user = response_permission?.data[0];
                let perm = user?.permissions ? user?.permissions : [];
                let settingsMenu = perm.length > 0 && perm.filter((p) => p.parent_id === 18);
                let settingsAccess = settingsMenu.filter((item) => item.read === 1);
                settingsAccess =
                    settingsAccess.length > 0
                        ? settingsAccess?.sort((a, b) => a.module_name.localeCompare(b.module_name))
                        : [];

                localStorage.setItem('settingsMenu', JSON.stringify(settingsAccess));
                localStorage.setItem('permissions', JSON.stringify(response_permission?.data?.[0]?.permissions));
            }
            console.log(response);
            if (response.two_factor_authentication && response.configured_two_factor_authentication) {
                window.location.href = '/account/send-otp';
            } else if (response.two_factor_authentication && !response.configured_two_factor_authentication) {
                window.location.href = '/account/scan-qr';
            } else {
                localStorage.setItem('br_login_response', JSON.stringify(response));
            }

            // token, user, role, permission
            refreshheaderToken(response.token.access_token);

            setToken(response.token, response.user, response.user_role, response_permission?.data?.[0]?.permissions);
            setSession(response);

            yield put(loginUserSuccess(response));
        } else {
            yield put(loginUserFailed('Invalid credentials'));
            // console.log('response', response);
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = 'Invalid credentials';
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
