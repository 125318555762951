import Loader from 'components/Loader/loader';
import Pagination from 'hoc/paginations/Pagination';
import React from 'react';
import { useState } from 'react';
import PaginationComponent from 'react-reactstrap-pagination';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

import styled from 'styled-components';
import { getNotifications, readNotifications } from './api.functions';
import Archive from './components/Archive';
import Inbox from './components/Inbox';
import SingleNotification from './components/SingleNotification';
import useNotification from './getNotification';

const NotificationDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
    .__heading {
        font-size: 19px;
        margin: 10px 0;
        font-family: 'Nunito Sans', sans-serif;
        color: #4b4b5a;
        font-weight: 600;
    }

    .__tabs {
        box-sizing: border-box;
        padding-left: 1rem;
        background-color: #fff;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        .tabs__{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        

        .__inbox {
            cursor: pointer;
            width: 100px;
            /* border-bottom: 2px solid transparent; */
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            position: relative;
            &::before {
                content: '';
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                background-color: gray;
                transition: all 0.3s ease;
                transform-origin: right;
            }
            .__notification_counts {
                background-color: gray;
                padding: 0 0.3rem;
                border-radius: 3px;
                color: #f3f4f7;
                font-size: 0.6rem;
            }
            &.__active {
                transition: all 0.3s;
                /* border-bottom: 2px solid gray !important; */
                &::before {
                    width: 100%;
                }
            }
        }
        .__archived {
            cursor: pointer;

            width: 100px;
            border-bottom: 2px solid transparent;
            height: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            position: relative;
            &::before {
                content: '';
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                background-color: gray;
                transition: all 0.3s ease;
                transform-origin: center center;
            }
            .__notification_counts {
                background-color: gray;
                padding: 0 0.3rem;
                border-radius: 3px;
                color: #f3f4f7;
                font-size: 0.6rem;
            }
            &.__active {
                transition: all 0.3s;
                /* border-bottom: 2px solid gray !important; */
                &::before {
                    width: 100%;
                }
            }
        }
    }
        .refresh__{
            margin-right:10px;
            cursor:pointer;
            :hover{
                color:#CCC;
            }
        }
        span {
            color: #6c757d;
        }
    }
    .__list {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        gap: 1rem;
        flex-direction: column;
    }
`;

const Notifications = () => {
    const [activeTab, setTactiveTab] = useState('inbox');
    const [totalInbox, setTotalInbox] = useState(0)
    const [totalArchive, setTotalArchive] = useState(0)
    const [refresh, setRefresh] = useState(false)

    const { notifications, loading, error, setPage, page, totalPages } = useNotification(activeTab, refresh);



    return (
        <>
            <NotificationDiv>
                <div className="__heading">Notifications</div>
                <div className="__tabs">
                    <div className='tabs__'>
                        <div
                            className={activeTab === 'inbox' ? '__inbox __active' : '__inbox'}
                            onClick={() => setTactiveTab('inbox')}>
                            <span>Inbox</span>
                            {/* <span className="__notification_counts">{totalInbox}</span> */}
                        </div>
                        <div
                            className={activeTab === 'archive' ? '__archived __active' : '__archived'}
                            onClick={() => setTactiveTab('archive')}>
                            <span>Archived</span>
                            {/* <span className="__notification_counts">{totalArchive}</span> */}
                        </div>
                    </div>
                    <div className='refresh__' onClick={() => { setRefresh(true) }}>
                        <Button color='primary'>Refresh</Button>
                    </div>
                </div>
                <div className="__list">
                    {activeTab === 'archive' ? (
                        <Archive refresh={refresh} setRefresh={setRefresh} setTotalArchive={setTotalArchive} />
                    ) : (
                        <Inbox refresh={refresh} setRefresh={setRefresh} setTotalInbox={setTotalInbox} />
                    )}
                </div>
            </NotificationDiv>

        </>
    );
};
export default Notifications;
