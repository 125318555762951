import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { getUserDesignation } from "../api.functions";

const useUserDesignation = () => {

    const [customerList, setCustomerList] = useState();


    const fetch = () => {
        getUserDesignation()
            .then((res) => {
                setCustomerList(res?.data);
            }).catch(() => { })
    }

    return {
        fetch,
        customerList
    }

}

export default useUserDesignation;