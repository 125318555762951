import { React, useState, useEffect } from 'react';
import { getNotifications } from './api.functions';

const useNotification = (props) => {
    const [notifications, setNotofications] = useState([]);
    const [results, setResults] = useState([]);
    const [is_Deleted, setIs_Deleted] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const fetchNotifications = (props) => {
        console.log(props)
        setIsLoading(true);
        getNotifications()
            .then((res) => {
                const data = res || [];
                setResults(data);
                setNotofications(data?.results);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        // fetchNotifications();
        setIs_Deleted(false);
    }, [is_Deleted]);
    return { notifications, isLoading, results, fetchNotifications, is_Deleted, setIs_Deleted };
};
export default useNotification;
