// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

import config from '../config';
import { getUserRole } from './api.functions';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    // const decoded = jwtDecode(user.token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     console.warn('access token expired');
    //     return false;
    // } else {
    return true;
    // }
};

const isUserAuthenticatedOperations = () => {
    return localStorage.getItem('operations') === 'false';
};
/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    // const cookies = new Cookies();
    // const user = cookies.get('user');
    const user = localStorage.getItem('br_login_response');

    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

function usePermissionAuth() {
    let payload = {
        active: '',
        userrole_id: JSON.parse(localStorage.getItem('loggedin_user_role')).userrole_id,
        user_id: localStorage.getItem('loggedin_user_id'),
    };
    getUserRole(payload)
        .then((res) => {
            return 'keerth';
        })
        .catch(() => {});
}

export { isUserAuthenticated, getLoggedInUser, usePermissionAuth, isUserAuthenticatedOperations };
