import React from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';
import { MdDelete } from 'react-icons/md';
import { BiUndo } from 'react-icons/bi';
import { TiTick } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import moment from 'moment';
import userAvatar from "assets/images/users/defaultUser.png"


const SingleNoficationDiv = styled.div`
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding: 0.3rem;
    /* height: 90px; */
    display: flex;
    gap: 1.2rem;
    align-items: flex-start;
    position: relative;
    width: 100%;

    .__is_new {
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: tomato;
        border-radius: 50%;
        right: 2%;
        top: 8%;
    }
    .__is_Read {
        position: absolute;
        right: 2%;
        top: 10%;
    }
    .__delete {
        position: absolute;
        right: 1%;
        top: 3%;
        cursor: pointer;
    }
    .__img {
        flex-shrink:0;
        width: 40px;
        img {
            max-width: 100%;
            border-radius: 50%;
            border: 1px solid #f3f4f7;
        }
    }
    .__notifications_details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
        justify-content: center;
        .__des {
            .__title {
                font-size: 1rem;
                font-weight: 700;
                color: #3b414e;
                cursor: pointer;
            }
            .__notification_time {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 0.4rem;
                .__dot {
                    width: 4px;
                    height: 4px;
                    background-color: #6c757d;
                    border-radius: 50%;
                }
                span {
                    font-size: 0.65rem !important;
                    font-weight: 600;
                }
            }
        }
    }
    .__actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.6rem;
        span {
            font-size: 0.75rem;
            font-weight: 600;
        }
        .__badget_link {
            background-color: #f3f4f7;
            padding: 0.2rem 0.5rem;
            border-radius: 0.2rem;
            font-size: 0.65rem;
            font-weight: 600;
            color: #3b414e;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
`;

const NotificationDetails = ({
    id,
    title,
    avatar,
    time,
    project,
    type,
    isDeleted = true,
    isRead = true,
    setOpen,
    obj,
    details,
    readNotification,
    subject,
    severity_type_name,
    due_date,
    vessel_name,
    schedule_survey_date
}) => {
    const durationInSecond = (time) => {
        const now = new Date().getTime();
        const past = new Date(time).getTime();
        const duration = now - past;
        return duration / 1000;
    };
    const timeInsecond = durationInSecond(time);

    function secondsToDhms(timeInsecond) {
        timeInsecond = Number(timeInsecond);
        const d = Math.floor(timeInsecond / (3600 * 24));
        const h = Math.floor((timeInsecond % (3600 * 24)) / 3600);
        const m = Math.floor((timeInsecond % 3600) / 60);
        const s = Math.floor(timeInsecond % 60);

        const dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : '';
        const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
        const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
        const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
        if (dDisplay) {
            return dDisplay;
        } else if (hDisplay) {
            return hDisplay;
        } else if (mDisplay) {
            return mDisplay;
        } else if (sDisplay) {
            return sDisplay;
        }
    }
    const module_id =
        type === 'ticket'
            ? `/ticketing-system/${obj['ticket_module']}`
            : type === 'Booking'
                ? `/apps/bookingdetails/${obj['booking_module']}`
                : type === 'customer'
                    ? obj['customer_module']
                    : type === 'Activity'
                        ? `/apps/bookingdetails/${obj['activity_module']}`
                        : "#";

    function htmlDecode(input) {
        var rawMarkup = input
        return { __html: rawMarkup };
    }
    return (
        <SingleNoficationDiv>
            {/* {!isRead ? (
                <div className="__is_new"></div>
            ) : (
                <div className="__is_Read">
                    <TiTick size={20} />
                </div>
            )} */}
            <div className="__delete">
                {!isDeleted ? (
                    <MdDelete
                        onClick={() => {
                            !isDeleted && readNotification({ archive: 'true' }, id);
                        }}
                        size={20}
                    />
                ) : (
                    <BiUndo
                        onClick={() => {
                            isDeleted && readNotification({ archive: 'false' }, id);
                        }}
                        size={20}
                    />
                )}
            </div>
            <div className="__img">
                <img
                    src={
                        avatar ||
                        userAvatar
                    }
                    alt=""
                />
            </div>
            <div className="__notifications_details">
                <div className="__des">
                    <span onClick={() => setOpen(id)} className="__title" >
                        {title}
                    </span>
                    <div className="__notification_time">
                        <span>{secondsToDhms(timeInsecond)} ago</span> <div className="__dot"></div>{' '}
                        <span>{project}</span>
                    </div>
                </div>
                <div>{subject}</div>
                <div className="__actions">
                    <Link to="#" className="__badget_link">
                        {type}
                    </Link>
                    {type === "Activity" && (
                        <>
                            <span style={{ color: severity_type_name === "High" ? "tomato" : severity_type_name === "Medium" ? "orange" : severity_type_name === "Low" ? "gray" : "" }}>
                                {severity_type_name}
                            </span>
                            <span style={{ color: "skyblue" }}>
                                {due_date}
                            </span>
                        </>)}
                    {type === "Booking" && (
                        <>
                            <span style={{ color: "green" }}>
                                {vessel_name}
                            </span>
                            <span style={{ color: "gray" }}>
                                {moment(schedule_survey_date)?.utc().format('YYYY-MM-DD')}
                            </span>
                        </>)}
                </div>
                <div dangerouslySetInnerHTML={{ __html: details.replace(/\n/g, '<br />') }} />{' '}
                {/* <div>
                    {details.indexOf('</') !== -1
                        ? (
                            <div dangerouslySetInnerHTML={{ __html: details.replace(/(<? *script)/gi, 'illegalscript') }} >
                            </div>
                        )
                        : details
                    }

                </div> */}

            </div>
        </SingleNoficationDiv>
    );
};

export default NotificationDetails;
