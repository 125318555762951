import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
// import usePermissionAuth from "./userauthentication";
import axios from 'axios';
import config from '../config';
import UsersCardView from '../pages/apps/Settings/compoenent/views/UserCard/UsersCardView';
import Notifications from 'pages/apps/Notifications';
import SendOtp from 'pages/auth/SendOtp';
import SupersetDashboard from 'pages/dashboard/SupersetDashboard';
import MainDashboard from 'pages/dashboard/MainDashboard';
import TVShow from 'pages/tv-show/TVShow';
import TwoFaSetup from 'pages/other/Profile/TwoFaSetup';
import TwoFactor_QR from 'pages/other/Profile/TwoFactorQR';

// var cors = require('cors')

// app.use(cors()) // Use this after the variable declaration

// auth
const Landing = React.lazy(() => import('../pages/auth/Landing'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const TicketingSystem = React.lazy(() => import('../pages/apps/ticketingSystem'));
const ResetPassword = React.lazy(() => import('../pages/auth/resetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// dashboard
// const Dashboard = React.lazy(() => import('../pages/dashboard'));
const DashboardBeta = React.lazy(() => import('../pages/dashboard/DashboardDelta/dashboard'));
const ExecutiveDashboard = React.lazy(() => import('../pages/dashboard/executive'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/calender/index_new'));
// const CalendarApp = React.lazy(() => import('../pages/apps/calender/index_version1'));
const CalendarBeta = React.lazy(() => import('../pages/apps/calender/index_version1'));

const Settings = React.lazy(() => import('../pages/apps/Settings'));
// const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
// const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
// const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
// const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
// const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
// const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const ActivityApp = React.lazy(() => import('../pages/apps/Activity'));

const Operations = React.lazy(() => import('../pages/apps/operations'));

const Finance = React.lazy(() => import('../pages/apps/finance'));
const CustomerDetails = React.lazy(() => import('../pages/apps/operations/customerDetails'));
const CustomerDetailsView = React.lazy(() => import('../pages/apps/operations/customerDetails/customerview'));
const IncentiveDetails = React.lazy(() => import('../pages/apps/Management/component/incentives/incentivedetails'));
const CompanyTargetDetails = React.lazy(() => import('../pages/apps/Management/companytargetdetails'));
const JobCostingDetails = React.lazy(() => import('../pages/apps/Management/component/jobcost/jobcostingdetails'));
const TariffDetails = React.lazy(() => import('../pages/apps/finance/compoenent/tariff/tariffdetails/index'));
const ReportsComponent = React.lazy(() => import('../pages/apps/reports'));

// pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const CustomerRelations = React.lazy(() => import('../pages/apps/CustomerRelations/index'));
const CRM = React.lazy(() => import('../pages/apps/CRM/index'));
const CRMDetails = React.lazy(() => import('../pages/apps/CRM/CrmDetailsComponent'));
const CustomerDetailsCRM = React.lazy(() => import('../pages/apps/CRM/components/CustomerDetails'));

const Management = React.lazy(() => import('../pages/apps/Management/index'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
const Charts = React.lazy(() => import('../pages/charts/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

//excel data
const ExcelData = React.lazy(() => import('../pages/apps/Management/excelData'));

const BookingsApp = React.lazy(() => import('../pages/apps/Bookings'));
const BookingdetailsApp = React.lazy(() => import('../pages/apps/Bookingdetails'));
// const TariffDetails = React.lazy(() => import('../pages/apps/TariffDetails'));
// System
const AlertDetails = React.lazy(() => import('../pages/apps/Settings/compoenent/details/alert/alertDetails'));
// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            // alert();

            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            if (loggedInUser) {
                localStorage.setItem('loggedin_user_id', loggedInUser?.user?.id);
                localStorage.setItem('loggedin_user_details', JSON.stringify(loggedInUser.user));

                localStorage.setItem('loggedin_user_role', JSON.stringify(loggedInUser.user_role));
                localStorage.setItem('loggedin_user_role_id', loggedInUser.user_role?.userrole_id);
                sessionStorage.setItem('read', '1');
                sessionStorage.setItem('delete', '1');
                sessionStorage.setItem('update', '1');
                sessionStorage.setItem('create', '1');
                localStorage.setItem(
                    'loggedin_user_permissions',
                    loggedInUser.permissions ? JSON.stringify(loggedInUser.permissions) : []
                );
            }

            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser?.user_role?.userrole_name) === -1) {
                // role not authorised so redirect to home page
                // return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
    ishidden: false,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: '',
    // badge: {
    //     variant: 'success',
    //     text: '1',
    // },
    // component: () => <></>,
    component: MainDashboard,
    roles: ['Super Admin'],
    route: PrivateRoute,
    ishidden: false,
};
const notificationAppRoutes = {
    path: '/apps/notification',
    name: 'Notifications',
    header: '',
    icon: FeatherIcon.Bell,
    component: Notifications,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

// dashboards
// const dashboardBetaRoutes = {
//     path: '/new-dashboard',
//     name: 'Dashboard New',
//     icon: FeatherIcon.Home,
//     header: '',
//     // badge: {
//     //     variant: 'success',
//     //     text: '1',
//     // },
//     component: DashboardBeta,
//     // component: Dashboard,
//     roles: ['Super Admin'],
//     route: PrivateRoute,
//     ishidden: false,
// };

// apps

const operationAppRoutes = {
    path: '/apps/operations',
    name: 'Operations',
    header: '',
    icon: FeatherIcon.Tool,
    component: Operations,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

const bookingAppRoutes = {
    path: '/apps/booking',
    name: 'Bookings',
    header: '',
    icon: FeatherIcon.Book,
    component: BookingsApp,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

// const calendarAppRoutes = {
//     path: '/apps/Calendar',
//     name: 'Calendar',
//     header: '',
//     icon: FeatherIcon.Calendar,
//     component: CalendarApp,
//     route: PrivateRoute,
//     roles: ['Super Admin'],
// };

//beta calendar
const calendarAppRoute2 = {
    path: '/apps/beta-calendar',
    name: 'Calendar Beta',
    header: '',
    icon: FeatherIcon.Calendar,
    component: CalendarBeta,
    route: PrivateRoute,
    roles: ['Super Admin'],
};
const calendarBetaAppRoute = {
    path: '/apps/calendar',
    name: 'Calendar',
    header: '',
    icon: FeatherIcon.Calendar,
    component: CalendarBeta,
    route: PrivateRoute,
    roles: ['Super Admin'],
};

const activityAppRoutes = {
    path: '/apps/Activity',
    name: 'Activities',
    header: '',
    icon: FeatherIcon.Activity,
    component: ActivityApp,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

const bookingdetailsAppRoutes = {
    path: '/apps/bookingdetails/:id',
    name: 'Booking Details',
    header: '',
    icon: FeatherIcon.Book,
    component: BookingdetailsApp,
    route: PrivateRoute,
    roles: ['Super Admin'],
};
const crmdetailsAppRoutes = {
    path: '/apps/crmdetails/:id',
    name: 'CRM Details',

    component: CRMDetails,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};
const crmCustomerdetailsAppRoutes = {
    path: '/apps/customer-details/:id',
    name: 'CRM Details',

    component: CustomerDetailsCRM,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

const alertDetailsRoute = {
    path: '/apps/alert-template/:id',
    name: 'Alert Details',
    component: AlertDetails,
    route: PrivateRoute,
    roles: ['Super Admin'],
    // ishidden: true,
};
const ticketingRoute = {
    path: '/ticketing-system',
    name: 'Ticketing System',
    component: TicketingSystem,
    route: PrivateRoute,
    roles: ['Super Admin'],
};

const twoFaSetupRoute = {
    path: '/two-fa-setup',
    name: 'Two FA Setup',
    component: TwoFaSetup,
    route: PrivateRoute,
    roles: ['Super Admin'],
};

// const tariffDetailsRoute = {
//     path: '/apps/tariffdetails/:id',
//     name: 'Tariff Details',
//     header: '',
//     icon: FeatherIcon.Calendar,
//     component: TariffDetails,
//     route: PrivateRoute,
//     roles: ['Super Admin'],
// }

const invoiceAppRoutes = {
    path: '/pages/invoice/:id',
    name: 'Invoice',
    component: Invoice,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

const managementAppRoutes = {
    path: '/apps/management',
    name: 'Management',
    header: '',
    icon: FeatherIcon.Server,
    component: Management,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

// const customerAppRoutes = {
//     path: '/apps/customer_relations',
//     name: 'Customer Relations',
//     header: '',
//     icon: FeatherIcon.Calendar,
//     component: CustomerRelations,
//     route: PrivateRoute,
//     roles: ['Super Admin'],
// };
const CRMAppRoutes = {
    path: '/apps/crm',
    name: 'Customer Relations',
    header: '',
    icon: FeatherIcon.Users,
    component: CRM,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

const financeAppRoutes = {
    path: '/apps/finance',
    name: 'Finance',
    header: '',
    icon: FeatherIcon.DollarSign,
    component: Finance,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};

const usersAppRoutes = {
    path: '/apps/users',
    name: 'Users',
    header: '',
    icon: FeatherIcon.Users,
    component: Starter,
    route: PrivateRoute,
    roles: ['Super Admin'],
};
const reportAppRoutes = {
    path: '/apps/report',
    name: 'Report',
    header: '',
    icon: FeatherIcon.PieChart,
    component: ReportsComponent,
    route: PrivateRoute,
    roles: ['Super Admin'],
    ishidden: true,
};
const sysytemAppRoutes = {
    path: '/apps/system',
    name: 'System',
    header: '',
    icon: FeatherIcon.Calendar,
    component: Starter,
    route: PrivateRoute,
    roles: ['Super Admin'],
};

const settingsAppRoutes = {
    path: '/apps/settings',
    name: 'Settings',
    header: '',
    icon: FeatherIcon.Settings,
    component: Settings,
    route: PrivateRoute,
    roles: ['Super Admin'],
};

// const calendarAppRoutes = {
//     path: '/apps/calendar',
//     name: 'Calendar',
//     header: '',
//     icon: FeatherIcon.Calendar,
//     component: CalendarApp,
//     route: PrivateRoute,
//     roles: ['Super Admin'],
// };

// const emailAppRoutes = {
//     path: '/apps/email',
//     name: 'Email',
//     icon: FeatherIcon.Inbox,
//     children: [
//         {
//             path: '/apps/email/inbox',
//             name: 'Inbox',
//             component: EmailInbox,
//             route: PrivateRoute,
//             roles: ['Super Admin'],
//         },
//         {
//             path: '/apps/email/details',
//             name: 'Details',
//             component: EmailDetail,
//             route: PrivateRoute,
//             roles: ['Super Admin'],
//         },
//         {
//             path: '/apps/email/compose',
//             name: 'Compose',
//             component: EmailCompose,
//             route: PrivateRoute,
//             roles: ['Super Admin'],
//         },
//     ]
// };

// const projectAppRoutes = {
//     path: '/apps/projects',
//     name: 'Projects',
//     icon: FeatherIcon.Briefcase,
//     children: [
//         {
//             path: '/apps/projects/list',
//             name: 'List',
//             component: ProjectList,
//             route: PrivateRoute,
//             roles: ['Super Admin'],
//         },
//         {
//             path: '/apps/projects/detail',
//             name: 'Detail',
//             component: ProjectDetail,
//             route: PrivateRoute,
//             roles: ['Super Admin'],
//         },
//     ]
// };

// const appRoutes=[operationAppRoutes,bookingAppRoutes,activityAppRoutes, calendarAppRoutes,managementAppRoutes,customerAppRoutes,financeAppRoutes,reportAppRoutes,sysytemAppRoutes,settingsAppRoutes]

async function axiosTest() {
    let payload = {
        active: '',
        userrole_id: JSON.parse(localStorage.getItem('loggedin_user_role')).userrole_id,
    };

    try {
        const { data: response } = await axios.post(`${config.apiRoot}system/list_user_role`, payload); //use data destructuring to get data from the promise object
        return response;
    } catch (error) {
        console.log(error);
    }
}

const appRoutes = [];

const loggedInUser = getLoggedInUser();
if (loggedInUser) {
    // const  loggedInUserPermissions=result?.data?.[0]?.permissions;

    const loggedInUserPermissions = JSON.parse(localStorage.getItem('permissions'));
    const check_permission_op = (Routes, permissions) => {
        if (
            permissions.some(
                (t) =>
                    (t.read === 1 && t.module_id == 2) ||
                    (t.read === 1 && t.module_id == 3) ||
                    (t.read === 1 && t.module_id == 4)
            ) ||
            loggedInUser.user_role.userrole_id === 1
        ) {
            Routes.ishidden = false;
        }
    };
    const check_permission_bo = (Routes, permissions) => {
        if (
            permissions.some(
                (t) =>
                    (t.read === 1 && t.module_id == 13) ||
                    (t.read === 1 && t.module_id == 14) ||
                    (t.read === 1 && t.module_id == 15) ||
                    (t.read === 1 && t.module_id == 16) ||
                    (t.read === 1 && t.module_id == 17) ||
                    (t.read === 1 && t.module_id == 5)
            ) ||
            loggedInUser.user_role.userrole_id === 1
        ) {
            Routes.ishidden = false;
        }
    };
    const check_permission_ac = (Routes, permissions) => {
        if (permissions.some((t) => t.read === 1 && t.module_id == 15) || loggedInUser.user_role.userrole_id === 1) {
            Routes.ishidden = false;
        }
    };

    const check_permission_ma = (Routes, permissions) => {
        if (
            permissions.some(
                (t) =>
                    (t.read === 1 && t.module_id == 6) ||
                    (t.read === 1 && t.module_id == 20) ||
                    (t.read === 1 && t.module_id == 21) ||
                    (t.read === 1 && t.module_id == 22)
            ) ||
            loggedInUser.user_role.userrole_id === 1
        ) {
            Routes.ishidden = false;
        }
    };
    const check_permission_crm = (Routes, permissions) => {
        if (
            permissions.some(
                (t) => t.read === 1 && t.module_id == 10
                // &&
                // (t.read === 1 && t.module_id == 8) ||
                // (t.read === 1 && t.module_id == 15) ||
                // (t.read === 1 && t.module_id == 22)
            ) ||
            loggedInUser.user_role.userrole_id === 1
        ) {
            Routes.ishidden = false;
        }
    };
    const check_permission_re = (Routes, permissions) => {
        if (
            permissions.some(
                (t) =>
                    (t.create === 1 && t.module_id == 11) ||
                    (t.create === 1 && t.module_id == 27) ||
                    (t.create === 1 && t.module_id == 28) ||
                    (t.create === 1 && t.module_id == 29)
            ) ||
            loggedInUser.user_role.userrole_id === 1
        ) {
            Routes.ishidden = false;
        }
    };
    const check_permission_fi = (Routes, permissions) => {
        if (
            permissions.some(
                (t) =>
                    (t.read === 1 && t.module_id == 9) ||
                    (t.read === 1 && t.module_id == 23) ||
                    (t.read === 1 && t.module_id == 25) ||
                    (t.read === 1 && t.module_id == 26)
            ) ||
            loggedInUser.user_role.userrole_id === 1
        ) {
            Routes.ishidden = false;
        }
    };

    // =======================
    var routeArr = [];
    var notifyApp = {};
    var operationApp = {};
    var bookingApp = {};
    var calendarBetaApp = {};
    var calendarBetaApp2 = {};
    var activityApp = {};
    var managementApp = {};
    var CRMApp = {};
    var financeApp = {};
    var reportApp = {};
    var settingsApp = {};
    loggedInUserPermissions.map((obj) => {
        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 1 || obj.module_id === 2 || obj.module_id === 3 || obj.module_id === 4) &&
                (obj.create === 1 || obj.update === 1 || obj.delete === 1 || obj.read === 1))
        ) {
            check_permission_op(notificationAppRoutes, loggedInUserPermissions);
            notifyApp = notificationAppRoutes;
            routeArr.push(notificationAppRoutes);
        }

        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 1 || obj.module_id === 2 || obj.module_id === 3 || obj.module_id === 4) &&
                (obj.create === 1 || obj.update === 1 || obj.delete === 1 || obj.read === 1))
        ) {
            check_permission_op(operationAppRoutes, loggedInUserPermissions);
            operationApp = operationAppRoutes;
            routeArr.push(operationAppRoutes);
        }

        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 5 ||
                obj.module_id === 13 ||
                obj.module_id === 14 ||
                obj.module_id === 15 ||
                obj.module_id === 16 ||
                obj.module_id === 17) &&
                obj.read === 1)
        ) {
            check_permission_bo(bookingAppRoutes, loggedInUserPermissions);
            bookingApp = bookingAppRoutes;
            routeArr.push(bookingAppRoutes);
        }

        //   if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 19 && obj.read === 1)) {
        //  alendarApp    var a = calendarAppRoutes;
        //   }
        //   if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 19 && obj.read === 1)) {
        //  calendarAp    var a = calendarAppRoute;
        //   }
        if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 19 && obj.read === 1)) {
            calendarBetaApp = calendarBetaAppRoute;
            calendarBetaApp2 = calendarAppRoute2;
            routeArr.push(calendarBetaAppRoute);
            routeArr.push(calendarAppRoute2);
        }

        //  ndarBetaApvar a = calendarBetaAppRoute;

        if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 15 && obj.read === 1)) {
            check_permission_ac(activityAppRoutes, loggedInUserPermissions);
            activityApp = activityAppRoutes;
            routeArr.push(activityAppRoutes);
        }
        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 6 || obj.module_id === 20 || obj.module_id === 21 || obj.module_id === 22) &&
                obj.read === 1)
        ) {
            check_permission_ma(managementAppRoutes, loggedInUserPermissions);
            managementApp = managementAppRoutes;
            routeArr.push(managementAppRoutes);
        }
        // Customer communication || Activity
        // if (
        //     (obj.module_id === 10 || obj.module_id === 8 || obj.module_id === 15) &&
        //     (obj.create === 1 || obj.update === 1 || obj.delete === 1 || obj.read === 1)
        // ) {
        //  customerApp  var a = customerAppRoutes;
        // }
        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 10 || obj.module_id === 8 || obj.module_id === 15) && obj.read === 1)
        ) {
            check_permission_crm(CRMAppRoutes, loggedInUserPermissions);
            CRMApp = CRMAppRoutes;
            routeArr.push(CRMAppRoutes);
        }
        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 9 || obj.module_id === 23 || obj.module_id === 25 || obj.module_id === 26) &&
                obj.read === 1)
        ) {
            check_permission_fi(financeAppRoutes, loggedInUserPermissions);
            financeApp = financeAppRoutes;
            routeArr.push(financeAppRoutes);
        }
        if (
            loggedInUser.user_role.userrole_id === 1 ||
            ((obj.module_id === 11 || obj.module_id === 27 || obj.module_id === 28 || obj.module_id === 29) &&
                obj.read === 1)
        ) {
            check_permission_re(reportAppRoutes, loggedInUserPermissions);
            reportApp = reportAppRoutes;
            routeArr.push(reportAppRoutes);
        }
        if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 12 && obj.read === 1)) {
            // sysytemAppr a = sysytemAppRoutes;
        }
        if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 18 && obj.read === 1)) {
            settingsApp = settingsAppRoutes;
            routeArr.push(settingsAppRoutes);
        }
    });

    // /======================

    let appIds = loggedInUserPermissions
        ? loggedInUserPermissions?.reduce(function (ids, obj) {
              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 1 || obj.module_id === 2 || obj.module_id === 3 || obj.module_id === 4) &&
                      (obj.create === 1 || obj.update === 1 || obj.delete === 1 || obj.read === 1))
              ) {
                  check_permission_op(notificationAppRoutes, loggedInUserPermissions);
                  appRoutes.push(notificationAppRoutes);
              }

              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 1 || obj.module_id === 2 || obj.module_id === 3 || obj.module_id === 4) &&
                      (obj.create === 1 || obj.update === 1 || obj.delete === 1 || obj.read === 1))
              ) {
                  check_permission_op(operationAppRoutes, loggedInUserPermissions);
                  appRoutes.push(operationAppRoutes);
              }

              //
              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 5 ||
                      obj.module_id === 13 ||
                      obj.module_id === 14 ||
                      obj.module_id === 15 ||
                      obj.module_id === 16 ||
                      obj.module_id === 17) &&
                      obj.read === 1)
              ) {
                  check_permission_bo(bookingAppRoutes, loggedInUserPermissions);
                  appRoutes.push(bookingAppRoutes);
              }

              //   if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 19 && obj.read === 1)) {
              //       appRoutes.push(calendarAppRoutes);
              //   }
              //   if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 19 && obj.read === 1)) {
              //       appRoutes.push(calendarAppRoute);
              //   }
              if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 19 && obj.read === 1)) {
                  appRoutes.push(calendarBetaAppRoute);
                  appRoutes.push(calendarAppRoute2);
              }

              //   appRoutes.push(calendarBetaAppRoute);

              if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 15 && obj.read === 1)) {
                  check_permission_ac(activityAppRoutes, loggedInUserPermissions);
                  appRoutes.push(activityAppRoutes);
              }
              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 6 || obj.module_id === 20 || obj.module_id === 21 || obj.module_id === 22) &&
                      obj.read === 1)
              ) {
                  check_permission_ma(managementAppRoutes, loggedInUserPermissions);
                  appRoutes.push(managementAppRoutes);
              }
              // Customer communication || Activity
              // if (
              //     (obj.module_id === 10 || obj.module_id === 8 || obj.module_id === 15) &&
              //     (obj.create === 1 || obj.update === 1 || obj.delete === 1 || obj.read === 1)
              // ) {
              //     appRoutes.push(customerAppRoutes);
              // }
              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 10 || obj.module_id === 8 || obj.module_id === 15) && obj.read === 1)
              ) {
                  check_permission_crm(CRMAppRoutes, loggedInUserPermissions);
                  appRoutes.push(CRMAppRoutes);
              }
              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 9 || obj.module_id === 23 || obj.module_id === 25 || obj.module_id === 26) &&
                      obj.read === 1)
              ) {
                  check_permission_fi(financeAppRoutes, loggedInUserPermissions);
                  appRoutes.push(financeAppRoutes);
              }
              if (
                  loggedInUser.user_role.userrole_id === 1 ||
                  ((obj.module_id === 11 || obj.module_id === 27 || obj.module_id === 28 || obj.module_id === 29) &&
                      obj.read === 1)
              ) {
                  check_permission_re(reportAppRoutes, loggedInUserPermissions);
                  appRoutes.push(reportAppRoutes);
              }
              if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 12 && obj.read === 1)) {
                  // appRoutes.push(sysytemAppRoutes);
              }
              if (loggedInUser.user_role.userrole_id === 1 || (obj.module_id === 18 && obj.read === 1)) {
                  appRoutes.push(settingsAppRoutes);
              }
          }, [])
        : '';
}
// } else {
//     appRoutes.push(
//         operationAppRoutes,
//         bookingAppRoutes,
//         activityAppRoutes,
//         calendarAppRoutes,
//         managementAppRoutes,
//         // customerAppRoutes,
//         CRMAppRoutes,
//         financeAppRoutes,
//         reportAppRoutes,
//         settingsAppRoutes
//     );

//  sysytemAppRoutes
// operationAppRoutes["ishidden"]=false;
// bookingAppRoutes["ishidden"]=false;
// calendarAppRoutes['ishidden']=false;
// activityAppRoutes['ishidden']=false;
// managementAppRoutes["ishidden"]=false;
// customerAppRoutes["ishidden"]=false;
// financeAppRoutes["ishidden"]=false;
// reportAppRoutes["ishidden"]=false;
// sysytemAppRoutes["ishidden"]=false;
// settingsAppRoutes["ishidden"]=false;
// }

const unique = (value, index, self) => {
    return self.indexOf(value) === index;
};

const appRoutesN = appRoutes.filter(unique);
// const appRoutesN = appRoutes;

// pages
const pagesRoutes = {
    path: '/pages',
    name: 'Pages',
    header: 'Custom',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/pages/starter',
            name: 'Starter',
            component: Starter,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/dashboard-executive',
            name: 'Executive Dashboard',

            component: ExecutiveDashboard,
            roles: ['Super Admin'],
            route: PrivateRoute,
        },
        {
            path: '/operations/customerDetails',
            name: 'CustomerDetails',
            component: CustomerDetails,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },

        {
            path: '/management/incentivedetails',
            name: 'Details',
            component: IncentiveDetails,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/operations/customerDetails/customerview/:id',
            name: 'CustomerDetails',
            component: CustomerDetailsView,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/management/companytargetdetails',
            name: 'CustomerDetails',
            component: CompanyTargetDetails,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },

        {
            path: '/management/jobcostingdetails',
            name: 'CustomerDetails',
            component: JobCostingDetails,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/finance/tariffdetails',
            name: 'CustomerDetails',
            component: TariffDetails,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/account/profile',
            name: 'Profile',
            component: Profile,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },

        {
            path: '/pages/activity',
            name: 'Activities',
            component: Activity,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/pages/invoice',
            name: 'Invoice',
            component: Invoice,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/pages/users-card',
            name: 'Users card view',
            component: UsersCardView,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/pages/pricing',
            name: 'Pricing',
            component: Pricing,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        {
            path: '/pages/error-404',
            name: 'Error 404',
            component: Error404,
            route: Route,
        },
        // {
        //   path: "/pages/excel-data",
        //   name: "Excel Data",
        //   component: ExcelData,
        //   route: PrivateRoute,
        //   roles: ["Super Admin"],
        // },
        {
            path: '/pages/error-500',
            name: 'Error 500',
            component: Error500,
            route: Route,
        },
        {
            path: '/pages/excel-data',
            name: 'Excel Data',
            component: ExcelData,
            route: PrivateRoute,
            roles: ['Super Admin'],
        },
        bookingdetailsAppRoutes,
        alertDetailsRoute,
        invoiceAppRoutes,
        crmdetailsAppRoutes,
        crmCustomerdetailsAppRoutes,
        // tariffDetailsRoute
    ],
};

const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/tv-shows',
            name: 'Confirm',
            component: TVShow,
            route: Route,
        },
        {
            path: '/account/forgot-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },

        {
            path: '/account/send-otp',
            name: 'Send OTP',
            component: SendOtp,
            route: Route,
        },

        {
            path: '/account/scan-qr',
            name: 'Two Factor QR',
            component: TwoFactor_QR,
            route: Route,
        },

        {
            path: '/account/main',
            name: 'Main',
            component: Landing,
            route: Route,
        },

        {
            path: '/account/reset-password/:user/:token',
            name: 'Reset Password',
            component: ResetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    // LandingRoute,
    dashboardRoutes,
    // dashboardBetaRoutes,
    ...appRoutesN,
    pagesRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    ticketingRoute,
    twoFaSetupRoute,
    // tableRoutes,
    authRoutes,
];

const authProtectedRoutes = [
    dashboardRoutes,
    // ...routeArr
    notifyApp,
    operationApp,
    bookingApp,
    calendarBetaApp,
    calendarBetaApp2,
    activityApp,
    managementApp,
    CRMApp,
    financeApp,
    reportApp,
    settingsApp,
];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
