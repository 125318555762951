import apis from '../../apis/api.actions';
import { parseQueryParams } from '../../utils/formValidate';

export const getSuperDashBoard = (data) =>
    apis.getDatas(`operation/super-dashboard/${parseQueryParams(data)}`, data).then((res) => res); // new

export const getDashBoard = (data) => apis.createData(`reports/dashboard`, data).then((res) => res);
export const getReportDashboard = (data) => apis.createData(`reports/dashboard`, data).then((res) => res);
export const getDashboardPermission = (data) =>
    apis.getDatas(`operation/dash-data-permission/`, data).then((res) => res);
export const getReportDashboardDelta = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/${parseQueryParams(data)}`, data).then((res) => res);
export const getDashJobStatus = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/job_status${parseQueryParams(data)}`, data).then((res) => res);
export const getReportDashboardDeltaAppointment = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/appointment_based${parseQueryParams(data)}`, data).then((res) => res);
export const getFilteredSolutionDatas = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/sol${parseQueryParams(data)}`, data).then((res) => res);
export const getFilteredSubSolutionDatas = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/subsolution${parseQueryParams(data)}`, data).then((res) => res);
export const getFilteredCustomersDatas = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/customer${parseQueryParams(data)}`, data).then((res) => res);
export const getFilteredLocationDatas = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/location${parseQueryParams(data)}`, data).then((res) => res);
// api/operation/dash-data/api/v2/subsolution?subsolution_id=1
// api/operation/dash-data/api/v2/sol?
// api/operation/dash-data/api/v2/bar-chart?solution_id=1&key=subsolution
export const getSubSolutionsDashboardDeltaIntial = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/bar-chart/initial${parseQueryParams(data)}`).then((res) => res);
export const getSubSolutionsDashboardDeltaRevenue = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/bar-chart${parseQueryParams(data)}`).then((res) => res);
export const getSuperIntendentDashboardDelta = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/super${parseQueryParams(data)}`).then((res) => res);
export const getSuperIntendentWorklog = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/super_add${parseQueryParams(data)}`).then((res) => res);
export const getBookingDashboardDelta = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/booking${parseQueryParams(data)}`).then((res) => res);
// api/operation/dash-data/api/v2/super?subsolution_name=Bulk Safety Vetting Inspection&solution_name=Quality
// /api/operation/dash-data/api/v2/booking?subsolution_name=Bulk Safety Vetting Inspection&user_id=4
export const getRateList = (data) => apis.createData(`operation/list_activity`, data).then((res) => res);
export const getNewRateList = (data) =>
    apis.getDatas(`operation/activity_list_new${parseQueryParams(data)}`).then((res) => res);
export const getCustomerInvoices = (data) =>
    apis.getDatas(`finance/customer-invoice${parseQueryParams(data)}`).then((res) => res);

export const getCustomerInvoiceDetails = (data) =>
    apis.getDatas(`finance/customer-invoice-details${parseQueryParams(data)}`).then((res) => res);

export const getPieChartDatas = (data) =>
    apis.getDatas(`finance/customer-invoice-pie${parseQueryParams(data)}`).then((res) => res);

export const getInvoicedLog = (data) =>
    apis.getDatas(`finance/receipt_logs${parseQueryParams(data)}`).then((res) => res);

// http://localhost:9000/api/finance/receipt_logs?start_date=2023-03-03&end_date=2023-2-25&status=success&uploaded_by=1

export const getReportDashboardDeltaScheduled = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/schedule_based${parseQueryParams(data)}`, data).then((res) => res);

export const getCountrysSchedule = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/country_schedule${parseQueryParams(data)}`, data).then((res) => res);

export const getFinancePerformanceScheduled = (data) =>
    apis.getDatas(`operation/dash-data/api/v2/schedule_based_period${parseQueryParams(data)}`, data).then((res) => res);

export const getVesselLists = (data) =>
    apis.getDatas(`operation/list-vessels${parseQueryParams(data)}`, data).then((res) => res);

export const getTerritoryLists = (data) =>
    apis.getDatas(`operation/list-territory${parseQueryParams(data)}`, data).then((res) => res);
