import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';

const LineChartAppointment = (props) => {
    const { viewCard = true, title, performanceChartTab } = props;

    // const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const day = props?.data?.map((list) => moment(list?.day)?.format('ddd, DD MMM'));
    const week = props?.data?.map((list) => 'week ' + moment(list?.week)?.week());
    const month = props?.data?.map((list) => moment(list?.month)?.format('MMM YYYY'));

    const series = [
        {
            // name: performanceChartTab === 'revenue' ? 'Revenue' : 'Jobs',
            name: 'Appointments',
            // data: props.data,
            data: props?.data?.map((list) => list?.count || 0) || [],
        },
    ];
    const options = {
        markers: {
            size: 5,
            colors: ['#008ffb'],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 7,
            },
        },
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false,
            },
            toolbar: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: title,
            align: 'left',
            style: {
                fontWeight: 'bold',
            },
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: props?.format === 'month' ? month : props?.format === 'week' ? week : day,
        },
    };

    // const data = props.data ?? [];
    const data = props?.data
        ?.sort((a, b) => {
            return a?.month - b?.month;
        })
        ?.map((list) => list?.count || '');
    // console.log(data, 'dataatatatata');
    return (
        <div>
            <div className="chart_based_on_app_dates">
                <Chart options={options} series={series} className="apex-charts" height={296} />
            </div>
        </div>
    );
};

export default LineChartAppointment;
