import React from 'react';
import { Modal } from 'reactstrap';

import ImageCropper from './ImageCropper';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ImageModal = ({ modalIsOpen, closeModal, image, onCropImage, ratio }) => {
    return (
        <div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
                {image && (
                    <ImageCropper
                        imgName={image?.[0]?.name}
                        onCropImage={onCropImage}
                        inputImg={image[0] && URL.createObjectURL(image[0])}
                        closeModal={closeModal}
                        ratio={ratio}
                    />
                )}
            </Modal>
        </div>
    );
};

export default ImageModal;
