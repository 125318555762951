import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ButtonGroup } from 'reactstrap';
import Swal from 'sweetalert2';

import config from '../../../config';
import { COMMON_API_ERROR } from '../../../constants';
import Form from './compoenent/Forms/territory';

// import {BASE_URL} from "../../../helpers/helper";
// import useCustomer from './useCustomer';
// import useCompany from './useCompany';

function useTerritory(props) {
    const defaultValues = {
        territory_name: '',
        notes: '',
        company_id: '',
        customer_id: '',
    };

    // const _useCustomer = useCustomer(props);
    // const _useCompany = useCompany(props);

    // useEffect(() => {
    //     _useCustomer.fetch();
    //     _useCompany.fetch();
    // }, []);

    // const getCustomers = () => {
    //     let data = _useCustomer.customerList,
    //         list = [];
    //     data.map((value) => {
    //         list.push({ name: value.customer_name, value: value.customer_id });
    //     });
    //     return list;
    // };

    // const getCompany = () => {
    //     let data = _useCompany.customerList,
    //         list = [];
    //     data.map((value) => {
    //         list.push({ name: value.company_name, value: value.company_id });
    //     });
    //     return list;
    // };

    const [customerList, setCustomerList] = useState([]);
    const [customerError, setCustomerError] = useState(null);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(null);
    const [action, setAction] = useState('Create');
    const [initialValues, SetInitialValues] = useState(defaultValues);

    const fetch = () => {
        setCustomerLoading(true);
        const payload = {
            customer_id: '',
            tarrif_rate_type_name: '',
            user_id: localStorage.getItem('loggedin_user_id'),
        };
        axios
            .post(`${config.apiRoot}configuration/territory_list`, payload)
            .then((res) => {
                setCustomerLoading(false);
                setCustomerList(res?.data.data);
            })
            .catch(() => {
                setCustomerLoading(false);
                setCustomerError(COMMON_API_ERROR);
            });
    };
    const customerColumns = [
        {
            dataField: 'sl.no',
            text: 'Sl no.',
            headerStyle: { width: '80px' },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let rowNumber =
                    (localStorage.getItem('page') - 1) * localStorage.getItem('sizePerPage') + (rowIndex + 1);

                return rowNumber;
            },
            // sort: true,
        },
        {
            dataField: 'territory_name',
            text: 'Territory Name',
            sort: true,
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'notes',
            text: 'Notes',
            sort: false,
            formatter: (value) => {
                return (
                    <div
                        style={{
                            'max-width': '130px',
                            'text-overflow': 'ellipsis',
                            overflow: 'hidden',
                            'white-space': 'nowrap',
                        }}>
                        {value}
                    </div>
                );
            },
        },

        {
            dataField: 'active',
            text: 'Status',
            formatter: (item, row) => {
                if (Boolean(item)) return <div style={{ color: 'green' }}>Active</div>;
                return <div style={{ color: 'red' }}>Inactive</div>;
            },
        },
        {
            dataField: 'created_date',
            text: 'Created At',
            formatter: (value) => {
                if (Boolean(value)) return moment(value, 'YYYY-MM-DD').format('DD MMMM YYYY');
            },
        },

        {
            dataField: 'territory_id',
            text: '',
            headerStyle: { width: '50px' },
            formatter: (rowContent, row) => {
                return (
                    <ButtonGroup>
                        {sessionStorage.getItem('update') === '1' && (
                            <Button
                                className="btn btn-info"
                                onClick={() => handleEditClick(row, localStorage.getItem('user_id'))}>
                                Edit
                            </Button>
                        )}
                        {sessionStorage.getItem('delete') === '1' && (
                            <Button className="btn btn-danger" onClick={() => onDelete(rowContent)}>
                                Delete
                            </Button>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const defaultSorted = [{
        dataField: 'territory_name',
        order: 'asc' 
      }];

    const handleClose = () => {
        setVisible(false);
        SetInitialValues(defaultValues);
    };

    const ModalContent = () => {
        return <Form reload={fetch} initialValues={initialValues} actionType={action} modelClose={handleClose} />;
    };

    const openModel = (title = 'Add Territory', action = 'Create') => {
        setTitle(title);
        setVisible(true);
        setAction(action);
    };

    const handleEditClick = (row) => {
        openModel('Edit Territory', 'Edit');
        SetInitialValues(row);
    };

    const onDelete = (rowContent) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete? ',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let payload = {
                    territory_id: rowContent,
                    user_id: localStorage.getItem('loggedin_user_id'),
                };
                axios
                    .post(`${config.apiRoot}configuration/delete_territory`, payload)
                    .then((res) => {
                        fetch();
                        toast.success(res.data.data?.message);
                    })
                    .catch((e) => {
                        toast.error(COMMON_API_ERROR);
                    });
            }
        });
    };

    return {
        fetch,
        customerList,
        customerError,
        customerLoading,
        customerColumns,
        ModalContent,
        openModel,
        onDelete,
        handleEditClick,
        visible,
        title,
        keyField: 'territory_id',
        handleClose,
        defaultSorted,
    };
}

export default useTerritory;
