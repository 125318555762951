import React, { useState } from 'react';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import BasicInfo from './userPermissionForms/BasicInfo';
import DetailInfo from './userPermissionForms/DetailInfo';
import { countries } from 'constants/countryList';
import Loader from 'components/Loader/loader';

export const certificationItems = [
    {
        type: 'text',
        name: 'Name',
        value: 'certification_name',
        required: true,
    },
    {
        name: 'File',
        type: 'file',
        value: 'certification_file',
        required: true,
    },
];

export function setInitialData(initialValues) {
    var formData = { ...initialValues };
    var data = formData['territory'],
        list = [];
    if (data) {
        data.map((value) => {
            list.push({ label: value.territory_name, value: value.territory_id });
        });
        data = list.sort((a, b) => a.label.localeCompare(b.label));
    }

    formData['territory'] = data;

    return formData;
}

const UserForms = ({
    actionType = 'Create',
    initialValues,
    modelClose = () => {},
    reload = () => {},
    territories = [],
    customer = [],
    company = [],
    userRole = [],
    currencyType = [],
    loading,
    ...rest
}) => {
    const selfID = localStorage?.getItem('loggedin_user_id');
    const selfRole = JSON.parse(localStorage?.getItem('loggedin_user_role'));

    const [activeStep, setactiveStep] = useState(
        initialValues?.user_profile_id === Number(selfID) &&
            selfRole?.userrole_name !== 'Super Admin' &&
            selfRole?.userrole_name !== 'Admin'
            ? 2
            : 1
    );

    const certificationValues = {
        // user: initialValues?.user_profile_id,
        certification_name: '',
        certification_file: '',
    };

    const onFileRemove = (files, key) => {
        let current = files;
        let newObject = {};
        let i = 0;
        Object.keys(current).map((k) => {
            if (k !== key) {
                newObject = { ...newObject, [i]: current[k] };
                i++;
            }
        });
        return newObject;
    };
    let _initialValues =
        actionType === 'Create'
            ? {
                  // "user_id":"1",
                  currency_type_id: '',
                  company_id: '1',
                  customer_id: '',
                  user_first_name: '',
                  user_last_name: '',
                  user_role_id: '',
                  territory: '',
                  user_email: '',
                  active: '1',
                  created_by_user: localStorage.getItem('loggedin_user_id'),
                  is_superintendent: 0,
                  is_bivac: 0,
                  password: '',
                  confirm_password: '',
                  photograph: [],
                  fileBanner: '',
                  imageFile: [],
                  dob: '',
                  date_of_joining: '',
                  designation: '',
                  citizenship: '',
                  mobile_number: '',
                  address: '',
                  msic_number: '',
                  country_priority: null,
                  headquarters_priority: null,
                  organizations_priority: null,
                  certifications: { 0: certificationValues },
                  images: [],
              }
            : {
                  ...initialValues,
                  imageFile: [],
                  created_by_user: localStorage.getItem('loggedin_user_id'),
                  certifications: initialValues?.attachments?.length
                      ? initialValues.attachments
                      : { 0: certificationValues },
                  fileBanner: initialValues.user_photograph,
                  citizenship: countries.find((list) => list?.code === initialValues?.citizenship),
                  user_role_id: initialValues.user_role_id
                      ? {
                            label: initialValues?.user_role,
                            value: initialValues?.user_role_id,
                        }
                      : '',
                  active:
                      initialValues.active == '0' || '1'
                          ? {
                                label: initialValues?.active === 1 ? 'Active' : 'Inactive',
                                value: initialValues?.active,
                            }
                          : '',
                  currency_type_id: initialValues?.currency_details?.currency_type_id
                      ? {
                            ...initialValues.currency_details,
                            label: initialValues?.currency_details?.currency_type_name,
                            value: initialValues?.currency_details?.currency_type_id,
                        }
                      : '',
                  designation: initialValues?.designation,
                  exising: true,
                  country_priority: initialValues.country_priority,
                  headquarters_priority: initialValues?.headquarters_priority,
                  organizations_priority: initialValues?.organizations_priority,
                  images: initialValues?.attachments,
              };

    const [formData, setFormData] = useState(setInitialData(_initialValues));
    console.log(_initialValues, '_initialValues');

    const handleAction = (data, type) => {
        setFormData({ ...formData, ...data });
        if (type === 1) {
            setactiveStep(2);
        } else if (type === 2) {
            setactiveStep(1);
        }
    };
    return (
        <div>
            <Row>
                {selfRole?.userrole_name === 'Super Admin' || selfRole?.userrole_name === 'Admin' ? (
                    <Col
                        lg={6}
                        style={{
                            textAlign: 'center',
                            padding: '5px',
                            background: activeStep === 1 ? '#334977' : 'white',
                            color: activeStep === 1 ? 'white' : 'black',
                        }}>
                        Basic Info
                    </Col>
                ) : null}
                <Col
                    lg={
                        initialValues?.user_profile_id === Number(selfID) &&
                        selfRole?.userrole_name !== 'Super Admin' &&
                        selfRole?.userrole_name !== 'Admin'
                            ? 12
                            : 6
                    }
                    style={{
                        textAlign: 'center',
                        padding: '5px',
                        background: activeStep === 2 ? '#334977' : 'white',
                        color: activeStep === 2 ? 'white' : 'black',
                    }}>
                    Detail Info
                </Col>
            </Row>

            {loading ? (
                <Loader />
            ) : (
                <>
                    {activeStep === 1 && userRole?.length > 0 && (
                        <BasicInfo
                            // {...rest}
                            handleAction={handleAction}
                            key={activeStep}
                            data={formData}
                            actionType={actionType}
                            userRole={userRole}
                            territories={territories}
                            currencyType={currencyType}
                            // setFormData={setFormData}
                        />
                    )}
                    {activeStep === 2 && (
                        <DetailInfo
                            // {...rest}
                            handleAction={handleAction}
                            key={activeStep}
                            actionType={actionType}
                            // setFormData={setFormData}
                            data={formData}
                            certificationItems={certificationItems}
                            certificationValues={certificationValues}
                            onFileRemove={onFileRemove}
                            reload={reload}
                            modelClose={modelClose}
                            selfRole={selfRole}
                        />
                    )}
                </>
            )}
        </div>
    );
};
export default UserForms;
