import apis from '../../apis/api.actions';
import { parseQueryParams } from '../../utils/formValidate';
//SYSTEM APIS START//////////////////////////////////////////////////////////////////////////////////////////////////////
export const getUserDesignation = (data) => apis.getDatas(`system/designation`).then((res) => res);
export const getUserRole = (data) => apis.createData(`system/list_user_role`, data).then((res) => res);
export const getUserList = (data) => apis.createData(`system/list_user`, data).then((res) => res);
export const listIncentiveStatus = (data) => apis.createData(`system/list_incentive_status`, data).then((res) => res);
//SYSTEM APIS END////////////////////////////////////////////////////////////////////////////////////////////////////////
// =================================================================================================================== //
// =================================================================================================================== //
//CONFIGERATION APIS START///////////////////////////////////////////////////////////////////////////////////////////////
export const listLocation = (data) => apis.createData(`configuration/location_list`, data).then((res) => res);
export const getCountries = (data) => apis.createData(`configuration/country_list`, data).then((res) => res);
export const getCurrencyTypes = (data) => apis.createData(`configuration/currency_type_list`, data).then((res) => res);
export const getInvoiceTypes = (data) => apis.createData(`configuration/invoice_type_list`, data).then((res) => res);
export const getTerritory = (data) => apis.createData(`configuration/territory_list`, data).then((res) => res);
/// export
export const getExportTerritory = (data) =>
    apis
        .getDatas(`configuration/download_data/get_customers_location_data${parseQueryParams(data)}`)
        .then((res) => res);
export const setExportTerritory = (data) =>
    apis
        .getDatas(`configuration/download_data/download_customers_location_data${parseQueryParams(data)}`)
        .then((res) => res);
export const revertExportTerritory = (data) =>
    apis
        .deleteData(`configuration/download_data/download_customers_location_data${parseQueryParams(data)}`)
        .then((res) => res);

export const getExportClient = (data) =>
    apis.getDatas(`configuration/download_data/get_customers_data${parseQueryParams(data)}`).then((res) => res);
export const setExportClient = (data) =>
    apis.getDatas(`configuration/download_data/download_customers_data${parseQueryParams(data)}`).then((res) => res);
export const revertExportClient = (data) =>
    apis.deleteData(`configuration/download_data/download_customers_data${parseQueryParams(data)}`).then((res) => res);

export const getExportInvoice = (data) =>
    apis.getDatas(`configuration/download_data/get_invoice_data${parseQueryParams(data)}`).then((res) => res);
export const setExportInvoice = (data) =>
    apis.getDatas(`configuration/download_data/download_invoice_data${parseQueryParams(data)}`).then((res) => res);
export const revertExportInvoice = (data) =>
    apis.deleteData(`configuration/download_data/download_invoice_data${parseQueryParams(data)}`).then((res) => res);

//////
export const delete_booking_status = (data) =>
    apis.createData(`configuration/delete_booking_status`, data).then((res) => res);
export const getBookingStatusList = (data) =>
    apis.createData(`configuration/bookings_status_list`, data).then((res) => res);
export const getVesselType = (data) => apis.createData(`configuration/vessel_type_list`, data).then((res) => res);
export const listTarrifRateTypes = (data) =>
    apis.createData(`configuration/tarrif_rate_type_list`, data).then((res) => res);
export const listSolutions = (data) => apis.createData(`configuration/solutions_list`, data).then((res) => res);
export const listSubSolutions = (data) => apis.createData(`configuration/sub_solutions_list`, data).then((res) => res);
export const list_chain = (data) => apis.createData(`configuration/list_chain`, data).then((res) => res);
export const invoice_status_list = (data) =>
    apis.createData(`configuration/invoice_status_list`, data).then((res) => res);
export const list_chain_service = (data) =>
    apis.createData(`configuration/list_chain_service`, data).then((res) => res);
export const delete_company_chain = (data) =>
    apis.createData(`configuration/delete_company_chain`, data).then((res) => res);
export const listCurrencyTypes = (data) => apis.createData(`configuration/currency_type_list`, data).then((res) => res);
export const listInvoiceTypes = (data) => apis.createData(`configuration/invoice_type_list`, data).then((res) => res);
export const listCountries = (data) => apis.createData(`configuration/country_list`, data).then((res) => res);
export const listTerritories = (data) => apis.createData(`configuration/territory_list`, data).then((res) => res);
export const action_level = (data) => apis.createData(`configuration/action_level`, data).then((res) => res);
export const action_mapping = (data) => apis.createData(`configuration/action_mapping`, data).then((res) => res);
export const approveTimesheet = (data) => apis.createData(`configuration/approve_timesheet`, data).then((res) => res);
export const getBookingAttachments = (id) =>
    apis.getDatas(`operation/booking-attachments?booking=${id}`).then((res) => res);
export const deleteBookingAttachments = (id) =>
    apis.deleteData(`operation/booking-attachments/${id}`).then((res) => res);

export const action_mapping_paged = (data) =>
    apis.getDatas(`configuration/action_map_lines${parseQueryParams(data)}`).then((res) => res);
export const get_anticipated_business = (data) =>
    apis.getDatas(`crm/aniticapitated_businesses${parseQueryParams(data)}`).then((res) => res);
export const get_alert_templates = (data) =>
    apis.getDatas(`system/alert-template${parseQueryParams(data)}`).then((res) => res);
export const get_alerts = (data) => apis.getDatas(`system/alerts${parseQueryParams(data)}`).then((res) => res);
export const get_alert_details = (id) => apis.getDatas(`system/alerts/${id}`).then((res) => res);
export const updata_alert_template = (id, data) =>
    apis.updateData(`system/alert-template/${id}`, data).then((res) => res);
export const update_alerts = (id, data) => apis.updateData(`system/alerts${id}`, data).then((res) => res);
export const createAnticipatedBusiness = (data) =>
    apis.createData(`crm/aniticapitated_businesses`, data).then((res) => res);
export const updateAnticipatedBusiness = (id, data) =>
    apis.updateData(`crm/aniticapitated_businesses/${id}`, data).then((res) => res);
export const delete_communication_type = (data) =>
    apis.createData(`configuration/delete_communication_type`, data).then((res) => res);
export const delete_activity_type = (data) =>
    apis.createData(`configuration/delete_activity_type`, data).then((res) => res);
export const activity_type_list = (data) =>
    apis.createData(`configuration/activity_type_list`, data).then((res) => res);
export const delete_activity_status = (data) =>
    apis.createData(`configuration/delete_activity_status`, data).then((res) => res);
export const activity_status_list = (data) =>
    apis.createData(`configuration/activity_status_list`, data).then((res) => res);
export const delete_action_level = (data) =>
    apis.createData(`configuration/delete_action_level`, data).then((res) => res);
export const delete_action_map_lines_view = (data) =>
    apis.createData(`configuration/delete_action_map_lines_view`, data).then((res) => res);
export const delete_anticipated_business = (id) =>
    apis.deleteData(`crm/aniticapitated_businesses/${id}`).then((res) => res);
export const action_type = (data) => apis.createData(`configuration/action_type`, data).then((res) => res);
export const delete_action_type = (data) =>
    apis.createData(`configuration/delete_action_type`, data).then((res) => res);
export const listCommunicationTypes = (data) =>
    apis.createData(`configuration/communication_type_list`, data).then((res) => res);
export const employeeTargetTypeList = (data) =>
    apis.createData(`configuration/employee_target_type_list`, data).then((res) => res);
export const locationCostTypeList = (data) =>
    apis.createData(`configuration/location_cost_type_list`, data).then((res) => res);

export const getSavedFilters = (data) =>
    apis.getDatas(`configuration/saved_filter${parseQueryParams(data)}`).then((res) => res);

export const createSavedFilters = (data) => apis.createData(`configuration/saved_filter`, data).then((res) => res);
export const updateSavedFilters = (id, data) =>
    apis.updateData(`configuration/saved_filter/${id}`, data).then((res) => res);
export const deleteSavedFilters = (id) => apis.deleteData(`configuration/saved_filter/${id}`).then((res) => res);
export const get_work_log_status = (id) => apis.getDatas(`configuration/work_log_status?view=all`).then((res) => res);
//CONFIGERATION APIS END/////////////////////////////////////////////////////////////////////////////////////////////////
// =================================================================================================================== //
// =================================================================================================================== //
//OPERATION APIS START///////////////////////////////////////////////////////////////////////////////////////////////////
// export const getVesselParticulars = (data) =>
// apis.createData(`operation/list_vessel_particulars`, data).then((res) => res);
export const getVesselParticulars = (data, config) =>
    apis.getDatas(`operation/list_vessel_particulars${parseQueryParams(data)}`, config).then((res) => res);

export const create_vessel_particulars = (data) =>
    apis.createData(`operation/create_vessel_particulars`, data).then((res) => res);
export const update_vessel_particulars = (data) =>
    apis.createData(`operation/update_vessel_particulars`, data).then((res) => res);
export const delete_vessel_view = (data) => apis.createData(`operation/delete_vessel_view`, data).then((res) => res);
export const createBooking = (data) => apis.createData(`operation/create_booking`, data).then((res) => res);
export const updateBooking = (data) => apis.createData(`operation/update_booking`, data).then((res) => res);
export const getBookingDetails = (data) => apis.createData(`operation/booking_detail`, data).then((res) => res);
export const delete_customer = (data) => apis.createData(`operation/delete_customer`, data).then((res) => res);
export const updateBookingStatus = (data) =>
    apis.createData(`operation/update_bookings_status`, data).then((res) => res);
export const deleteBooking = (data) => apis.createData(`operation/delete_booking`, data).then((res) => res);
export const list_customer_address = (data) =>
    apis.createData(`operation/list_customer_address`, data).then((res) => res);
export const create_customer_address = (data) =>
    apis.createData(`operation/create_customer_address`, data).then((res) => res);
export const update_customer_address = (data) =>
    apis.createData(`operation/update_customer_address`, data).then((res) => res);
export const delete_customer_address = (data) =>
    apis.createData(`operation/delete_customer_address`, data).then((res) => res);
export const createCustomer = (data) => apis.createData(`operation/create_customer`, data).then((res) => res);
export const update_customer_view = (data) =>
    apis.createData(`operation/update_customer_view`, data).then((res) => res);
// export const getBookingList = (data) => apis.createData(`operation/list_booking`, data).then((res) => res);
export const getBookingList = (data) =>
    apis.getDatas(`operation/list_booking${parseQueryParams(data)}`).then((res) => res);

export const getCalendarList = (data) =>
    apis.getDatas(`operation/booking_calendar_list${parseQueryParams(data)}`).then((res) => res);
export const getBookingSuperintentList = (data) =>
    apis.createData(`operation/list_superintent`, data).then((res) => res);
// export const getBookingTableList = (data) => apis.createData(`operation/list_booking`, data).then((res) => res);
export const getBookingTableList = (data, config) =>
    apis.getDatas(`operation/list_booking${parseQueryParams(data)}`, config).then((res) => res);

export const list_customer_contact = (data) =>
    apis.createData(`operation/list_customer_contact`, data).then((res) => res);
export const create_customer_contact = (data) =>
    apis.createData(`operation/create_customer_contact`, data).then((res) => res);
export const delete_customer_contact = (data) =>
    apis.createData(`operation/delete_customer_contact`, data).then((res) => res);
export const update_customer_contact = (data) =>
    apis.createData(`operation/update_customer_contact`, data).then((res) => res);
export const add_booking_attachment = (data) =>
    apis.createData(`operation/upload_attachement`, data).then((res) => res);
// export const list_customer = (data) => apis.createData(`operation/list_customer`, data).then((res) => res);
export const list_customer = (data, config) =>
    apis.getDatas(`operation/list_customer${parseQueryParams(data)}`, config).then((res) => res);

export const list_headerlist = (data) =>
    apis.getDatas(`operation/operations_headerlist${parseQueryParams(data)}`).then((res) => res);
export const list_departments = (data) => apis.createData(`operation/list_departments`, data).then((res) => res);
export const listCustomerCommunication = (data) =>
    apis.createData(`operation/list_customer_communication`, data).then((res) => res);
export const markAsCompleteInstruction = (data) =>
    apis.createData(`operation/update_instruction_status`, data).then((res) => res);
export const getCostCentres = (data) => apis.getDatas(`system/cost_centre${parseQueryParams(data)}`).then((res) => res);

//OPERATION APIS END/////////////////////////////////////////////////////////////////////////////////////////////////////
// =================================================================================================================== //
// =================================================================================================================== //
//FINANCE APIS START/////////////////////////////////////////////////////////////////////////////////////////////////////
export const createInvoice = (data) => apis.createData(`finance/generate_invoice`, data).then((res) => res);
export const listCustomerTarriffDetails = (data) =>
    apis.createData(`finance/list_customer_tariff_detail`, data).then((res) => res);
export const listCustomerTarriffDetailsBeta = (data) =>
    apis.getDatas(`finance/list_customer_tariff_detail_2${parseQueryParams(data)}`).then((res) => res);
export const createTarrifLines = (data) => apis.createData(`finance/create_tariff_lines`, data).then((res) => res);
export const updateTarrifLines = (data) =>
    apis.createData(`finance/update_customer_tariff_lines`, data).then((res) => res);
export const deleteTarrifLines = (data) =>
    apis.createData(`finance/delete_customer_tariff_lines`, data).then((res) => res);
export const listInvoice = (data) => apis.getDatas(`finance/invoices${parseQueryParams(data)}`).then((res) => res);
export const incentiveLevelslist = (data) => apis.createData(`finance/incentives_level`, data).then((res) => res);
export const employee_incentives_details = (data) =>
    apis.createData(`finance/employee_incentives_details`, data).then((res) => res);
export const update_incentive_status = (data) =>
    apis.createData(`finance/update_incentive_status`, data).then((res) => res);
export const transferInvoice = (data) => apis.createData(`finance/invoice_transfer`, data).then((res) => res);
// export const employeeIncentivesList = (data) => apis.createData(`finance/employee_incentives`, data).then((res) => res);
export const employeeIncentivesList = (data) =>
    apis.getDatas(`finance/employee_incentives${parseQueryParams(data)}`).then((res) => res);
//export const listCustomerTarriff = (data) => apis.createData(`finance/list_customer_tariff`, data).then((res) => res);
export const listCustomerTarriff = (data) =>
    apis.getDatas(`finance/list_customer_tariff${parseQueryParams(data)}`).then((res) => res);

export const updateCustomerTarriff = (data) =>
    apis.createData(`finance/update_customer_tariff`, data).then((res) => res);
export const createCustomerTarriff = (data) =>
    apis.createData(`finance/create_customer_tariff`, data).then((res) => res);
export const deleteCustomerTarriff = (data) =>
    apis.createData(`finance/delete_customer_tariff`, data).then((res) => res);
export const update_invoice_status = (data) =>
    apis.createData(`finance/update_invoice_status`, data).then((res) => res);
export const delete_invoice = (data) => apis.createData(`finance/delete_invoice`, data).then((res) => res);
//FINANCE APIS END///////////////////////////////////////////////////////////////////////////////////////////////////////
// =================================================================================================================== //
// =================================================================================================================== //
//MANAGEMENT APIS START//////////////////////////////////////////////////////////////////////////////////////////////////
// export const getSuppliers = (data) => apis.createData(`management/list_supplier`, data).then((res) => res);
export const getSuppliers = (data, config) =>
    apis.getDatas(`management/list_supplier${parseQueryParams(data)}`, config).then((res) => res);

export const companySalesTargetList = (data) =>
    apis.createData(`management/company_sales_target_list`, data).then((res) => res);
export const employeeTargetList = (data) => apis.createData(`management/list_employee_target`, data).then((res) => res);
export const companySalesTargetCreate = (data) =>
    apis.createData(`management/create_company_sales_target`, data).then((res) => res);
export const companySalesTargetDelete = (data) =>
    apis.createData(`management/delete_multiple_company_sales_target`, data).then((res) => res);
export const companySalesTargetLineCreate = (data) =>
    apis.createData(`management/create_company_sales_target_lines`, data).then((res) => res);
export const companySalesTargetLineDelete = (data) =>
    apis.createData(`management/delete_multiple_company_sales_target_lines`, data).then((res) => res);
export const companySalesTargetLineUpdate = (data) =>
    apis.createData(`management/update_company_sales_target_lines`, data).then((res) => res);
export const companySalesTargetUpdate = (data) =>
    apis.createData(`management/update_company_sales_target`, data).then((res) => res);

export const salesTargetTypeList = (data) =>
    apis.getDatas(`configuration/sale_target_type_list`, data).then((res) => res);
export const getSupplierProducts = (data) =>
    apis.createData(`management/list_supplier_products`, data).then((res) => res);
export const createSupplier = (data) => apis.createData(`management/create_supplier`, data).then((res) => res);
export const createEmployeeTarget = (data) =>
    apis.createData(`management/create_employee_target`, data).then((res) => res);
export const createJobCostHeader = (data) =>
    apis.createData(`management/create_job_cost_header`, data).then((res) => res);
export const updateJobCostHeader = (data) =>
    apis.createData(`management/update_job_cost_header`, data).then((res) => res);
export const updateJobCost = (data) => apis.createData(`management/update_job_cost`, data).then((res) => res);
export const recalculateData = (data) => apis.createData(`management/recalculate`, data).then((res) => res);
export const deleteCompanySalesTargetLines = (data) =>
    apis.createData(`management/delete_multiple_company_sales_target_lines`, data).then((res) => res);
export const updateEmployeeTarget = (data) =>
    apis.createData(`management/create_employee_target`, data).then((res) => res);
export const deletemployeeTarget = (data) =>
    apis.createData(`management/delete_employee_target`, data).then((res) => res);
export const updateSupplier = (data) => apis.createData(`management/update_supplier`, data).then((res) => res);
export const deleteSupplier = (data) => apis.createData(`management/delete_supplier`, data).then((res) => res);
export const deleteJobCost = (data) => apis.createData(`management/delete_job_cost`, data).then((res) => res);
export const job_cost_detail = (data) => apis.createData(`management/job_cost_detail`, data).then((res) => res);
export const listJobCost = (data) => apis.createData(`management/job_cost_list`, data).then((res) => res);
export const list_rate_type = (data) => apis.createData(`management/list_rate_type`, data).then((res) => res);
export const create_job_cost_lines = (data) =>
    apis.createData(`management/create_job_cost_lines`, data).then((res) => res);
export const update_job_cost_lines = (data) =>
    apis.createData(`management/update_job_cost_lines`, data).then((res) => res);
export const delete_job_cost_lines = (data) =>
    apis.createData(`management/delete_job_cost_lines`, data).then((res) => res);
export const job_cost_notes_list = (data) => apis.createData(`management/job_cost_notes_list`, data).then((res) => res);
export const create_job_cost_notes = (data) =>
    apis.createData(`management/create_job_cost_notes`, data).then((res) => res);
export const update_job_cost_notes = (data) =>
    apis.createData(`management/update_job_cost_notes`, data).then((res) => res);
export const delete_job_cost_notes = (data) =>
    apis.createData(`management/delete_job_cost_notes`, data).then((res) => res);
//MANAGEMENT APIS END////////////////////////////////////////////////////////////////////////////////////////////////////
// =================================================================================================================== //
// =================================================================================================================== //
//REPORTS APIS START/////////////////////////////////////////////////////////////////////////////////////////////////////
export const booking_report = (data) => apis.createData(`reports/booking_report`, data).then((res) => res);
export const client_report = (data) => apis.createData(`reports/client_report`, data).then((res) => res);
export const invoice_report = (data) => apis.createData(`reports/invoice_report`, data).then((res) => res);

//REPORTS APIS END///////////////////////////////////////////////////////////////////////////////////////////////////////
export const getUserProfile = (data) => apis.getDatas(`system/user_info`, data).then((res) => res);
export const updateUserPassword = (id, data) => apis.updateData(`system/user_info/${id}`, data).then((res) => res);

export const updateUser = (id, data) => apis.updateData(`system/user_info/${id}`, data).then((res) => res);

// TICKET APIS END
export const getTickets = (data) =>
    apis.getDatas(`system/ticket_management${parseQueryParams(data)}`).then((res) => res);
export const getUserModules = (data) => apis.createData(`system/modules_list`, data).then((res) => res);
export const getTicketLogs = (id) => apis.getDatas(`system/activity-logs?ticket_id=${id}`).then((res) => res);
export const createTicket = (data) => apis.createData(`system/ticket_management`, data).then((res) => res);
export const updateTicket = (id, data) => apis.updateData(`system/ticket_management/${id}`, data).then((res) => res);
export const deleteTicket = (id) => apis.deleteData(`system/ticket_management/${id}`).then((res) => res);
export const approveTicket = (data) => apis.createData(`system/ticket_management_approve`, data).then((res) => res);

// TICKET COMMENT API
export const getTicketComments = (data) => apis.getDatas(`system/ticket-management-comment`, data).then((res) => res);
export const createTicketComment = (data) =>
    apis.createData(`system/ticket-management-comment`, data).then((res) => res);
export const updateTicketComment = (id, data) =>
    apis.updateData(`system/ticket-management-comment/${id}`, data).then((res) => res);
export const deleteTicketComment = (id) => apis.deleteData(`system/ticket-management-comment/${id}`).then((res) => res);
export const getVesselParticularsOptions = (data, config) =>
    apis.getDatas(`operation/list_vessel_particulars_list${parseQueryParams(data)}`, config).then((res) => res);

export const getBookingListOptions = (data) =>
    apis.getDatas(`operation/list_booking_list${parseQueryParams(data)}`).then((res) => res);
