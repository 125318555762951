import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { getCountries } from "../api.functions";

function useCountry(props) {

    const [country, setCountry] = useState([]);

    const fetch = () => {
        // axios.post(`${config.apiRoot}configuration/country_list`)
        getCountries()
            .then(async (res) => {
                let data = [];
                await res.data?.map((value) => {
                    data.push({ value: value?.country_name, name: value?.country_name });
                })
                let dataUser = data.sort((a, b) => a.name.localeCompare(b.name))

                await setCountry(dataUser);
            }).catch((e) => {

            })
    }

    return {
        country,
        fetch
    };
}
export default useCountry