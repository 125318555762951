import React from 'react';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Row } from 'reactstrap';
import TextField from '../../../../../components/textFeild/textFeild';
import axios from 'axios';
import config from '../../../../../config';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { COMMON_API_ERROR } from '../../../../../constants';
import SelectField from '../../../../../components/selectField/selectField';

const validationSchema = Yup.object().shape({
    territory_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    notes: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    // company_id: Yup.string().required('This field is required'),
    // customer_id: Yup.string().required('This field is required'),
});

const TerritoryForms = ({
    actionType = 'Create',
    initialValues,
    modelClose = () => { },
    reload = () => { },
    company = [],
    customer = [],
}) => {
    let _initialValues =
        actionType === 'Create'
            ? {
                territory_name: '',
                notes: '',
                company_id: '1',
                customer_id: '',
                active: '1',
                email: '',
                is_headquarter: false,
                created_by_user: localStorage.getItem('loggedin_user_id'),
            }
            : initialValues;

    const formik = useFormik({
        initialValues: _initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let req;
            values['user_id'] = localStorage.getItem('loggedin_user_id');
            switch (actionType) {
                case 'Create':
                    req = axios.post(`${config.apiRoot}configuration/create_territory`, values);
                    break;
                case 'Edit':
                    req = axios.post(`${config.apiRoot}configuration/update_territory`, values);
            }
            req.then((res) => {
                modelClose();
                reload();
                toast.success(res.data.message);
            }).catch((e) => {
                toast.error(e.response.data?.message || COMMON_API_ERROR);
            });
        },
    });
    console.log(formik.values)

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <TextField
                            label="Territory Name"
                            required={true}
                            name="territory_name"
                            value={formik.values.territory_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.territory_name &&
                                formik.touched.territory_name &&
                                formik.errors.territory_name
                            }
                        />
                    </Col>
                    <Col lg={12}>
                        <TextField
                            label="Email"
                            // required={true}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email && formik.touched.email && formik.errors.email}
                        />
                    </Col>
                    {/* <Col lg={12}>
                        <SelectField
                            label={"Company"}
                            required={true}
                            name={"company_id"}
                            firstLabel={"Select Company"}
                            list={company}
                            value={formik.values.company_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.company_id && formik.touched.company_id && formik.errors.company_id}

                        />
                    </Col>
                    <Col lg={12}>
                        <SelectField
                            label={"Customer"}
                            required={true}
                            name={"customer_id"}
                            firstLabel={"Select Customer"}
                            list={customer}
                            value={formik.values.customer_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.customer_id && formik.touched.customer_id && formik.errors.customer_id}

                        />
                    </Col> */}
                    <Col lg={12}>
                        <TextField
                            label="Notes"
                            type={'textarea'}
                            name="notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.notes && formik.touched.notes && formik.errors.notes}
                        />
                    </Col>
                    <Col lg={12}>
                        <CustomInput
                            type="checkbox"
                            id="is_headquarter"
                            label="Is Headquarter"
                            name='is_headquarter'
                            value={formik.values.is_headquarter || ""}
                            defaultChecked={formik.values.is_headquarter}
                            onChange={() => formik.setFieldValue(`is_headquarter`, !formik.values.is_headquarter)}
                            className="float-left"></CustomInput>
                    </Col>
                    <Col lg={12}>
                        <SelectField
                            label={'Status'}
                            //required={true}
                            name={'status'}
                            firstLabel={'Select Status'}
                            list={[
                                { name: 'Active', value: '1' },
                                { name: 'InActive', value: '0' },
                            ]}
                            value={formik.values.active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.active && formik.touched.active && formik.errors.active}
                        />
                    </Col>
                    <Col lg={12}>
                        <Button type={'submit'}>Submit</Button>
                    </Col>
                </Row>
            </form>
        </div >
    );
};
export default TerritoryForms;
