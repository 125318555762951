import React, { useEffect, useState } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
// import { AddressStyled } from "../contactInfo.style";
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';

async function fetchGuestToken() {
    // console.log('fetch guest token');
    return process.env.REACT_APP_ST_TOKEN || '';
}

const SurveyTeamDashboard = ({ ...rest }) => {
    const [error_message, setError_message] = useState('');

    const history = useHistory();

    useEffect(() => {
        embedDashboard({
            id: process.env.REACT_APP_ST_UUID, // given by the Superset embedding UI
            supersetDomain: 'https://superset-bas-dev.bridge.propelmarine.com',
            mountPoint: document.getElementById('superset-container'), // any html element that can contain an iframe
            // fetchGuestToken: async () => {
            //   const token = await fetchGuestTokenFromBackend({ userName, userType, clientId });
            //   console.log('token', token);
            //   return token;
            // },
            fetchGuestToken,
            dashboardUiConfig: {
                // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
                hideTitle: true,
                filters: {
                    expanded: false,
                },
            },
        });
    }, []);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    overflowX: 'auto',
                }}>
                <div className="my-superset-container" id="superset-container">
                    <iframe id="superset-container" title="BAS Dashboard" />
                </div>
            </div>
            {/* <div style={{ margin: '60px 0', fontSize: '24px', fontWeight: 'bold', textAlign: 'center', borderBottom: '1px solid #333', paddingBottom: '10px' }}>
            Coming Soon !!!
            </div> */}
        </>
    );
};

export default SurveyTeamDashboard;
