import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import uuid from 'react-uuid';
import Loader from '../../../../../../components/Loader/loader';

import { Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { countries } from 'constants/countryList';

export default function UserCard({ selected, setSelected }) {
    const [open, setOpen] = useState(!!selected || true);

    const handleClose = () => {
        setOpen(false);
        setSelected(undefined);
    };
    const countryCode = countries?.find((c) => c.code === selected?.citizenship)?.dial_code;
    const phone = selected.mobile_number != 'null' ? `${countryCode || ''}${selected.mobile_number}` : '';

    return (
        <Modal isOpen={open} toggle={handleClose} size="lg">
            <ModalHeader toggle={handleClose}>
                <div className="row" style={{ flexBasis: '90%', alignItems: 'center' }}>
                    <div className="header-title px-3">
                        {selected.user_first_name} {selected.user_last_name}
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="inner-user-card">
                <div className="inner-card">
                    <div className="inner-tumb">
                        <img
                            src={
                                selected.user_photograph
                                    ? selected.user_photograph
                                    : require('../../../../../../assets/images/users/dummy.png')
                            }
                            alt=""
                        />
                    </div>
                    {console.log(selected)}
                    <div className="inner-details">
                        <span className="inner-category">
                            {selected?.territory?.map((list) => (
                                <div>{list?.territory_name}</div>
                            ))}
                        </span>
                        <h4>
                            {selected.user_first_name} {selected.user_last_name}
                        </h4>
                        <div className="inner_row">
                            <p>Email </p> <p> {selected.user_email ?? ''}</p>
                        </div>
                        <div className="inner_row">
                            <p>Designation </p> <p> {selected.designation ?? ''}</p>
                        </div>
                        <div className="inner_row">
                            <p>Date of Birth </p>{' '}
                            <p> {selected.date_of_birth && moment(selected.date_of_birth).format('DD/MM/YYYY')}</p>
                        </div>

                        <div className="inner_row">
                            <p>Mobile Number </p> <p>{phone ?? ''} </p>
                        </div>
                        {/* <div className="inner_row">
                            <p>Address </p> <p> {selected.address == 'null' ? '' : selected.address}</p>
                        </div> */}
                        <div className="inner_row">
                            <p>MSIC Number </p> <p> {selected.msic_number == 'null' ? '' : selected.msic_number}</p>
                        </div>

                        {selected.attachments?.length > 0 && (
                            <div className="certifications">
                                <h5>Certifications</h5>
                                {selected.attachments.map((attachment) => (
                                    <>
                                        <div>{attachment.certification_name}</div>
                                        <a href={attachment.attachment} target="_blank">
                                            {attachment.attachment?.split('/')?.pop()}
                                        </a>
                                    </>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
