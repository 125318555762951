import { Form, Modal, Badge, Row, Col } from 'react-bootstrap';
import { Button, Input } from 'reactstrap';
import React, { useEffect, useRef, useState } from 'react';
import RichTextEditor from '../../../../components/RichTextEditor';
import { Jodit } from 'jodit-react';

import Creatable from 'react-select/creatable';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import * as FeatherIcon from 'react-feather';
import * as Yup from 'yup';
import {
    getUserModules,
    getTicketLogs,
    getTicketComments,
    createTicketComment,
    deleteTicketComment,
    updateTicketComment,
} from '../../api.functions';
import { Formik, Form as FormikForm } from 'formik';
import StyledTextarea from '../../operations/compoenent/timesheet/style';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import './ticket.css';

function TicketDetails(props) {
    const [headEdit, setHeadEdit] = React.useState(!props.edit);
    const [ticketCommentData, setTicketCommentData] = React.useState('');
    const [ticketCommentDatas, setTicketCommentDatas] = React.useState([]);
    const [data, setData] = React.useState({});
    const [modules, setModules] = React.useState([]);
    const [errors, setErrors] = useState({});
    const [comments, setComments] = React.useState([]);
    const fileref = useRef(null);
    const onDataChange = (value, name, key) => {
        setData((d) => ({ ...d, [name]: value }));
        // setKeyField(keyField + 1);
    };

    useEffect(() => {
        getModules();
    }, []);
    const getLogDetails = (id) => {
        getTicketLogs(id).then((res) => {
            let cmnt = res.data || [];
            setComments(cmnt);
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const res = await getTicketComments();
            setTicketCommentDatas(res.data);
        };

        // call the function
        fetchData()
            // make sure to catch any error
            .catch((e) => {
                toast.error('Failed in comments');
            });
    }, [ticketCommentData]);
    useEffect(() => {
        let module = {
            value: props.editData?.module_id ?? '',
            label: props.editData?.module_name ?? '',
        };
        let status = {
            value: props.editData?.status ?? '',
            label: props.editData?.status
                ? props.editData?.status === 'In Progress'
                    ? 'In-Progress'
                    : props.editData?.status
                : '',
        };
        setData({
            ...props.editData,
            formType: props.edit ? 'existing' : 'new',
            module: module,
            status: status,
        });

        if (props.editData?.ticket_id) {
            getLogDetails(props.editData?.ticket_id);
        }
    }, [props.editData]);
    const getModules = () => {
        getUserModules().then((res) => {
            let mod_all = res.modules;
            let prim_mod = [];

            prim_mod.push({ label: 'Website', value: 'Website' });
            prim_mod.push({ label: 'General', value: 'General' });
            prim_mod.push({ label: 'Power BI', value: 'Power BI' });

            mod_all.forEach((mo) => {
                if (!mo.parent_id || mo.parent_id === '') {
                    prim_mod.push({ label: mo.module_name, value: mo.module_id });
                }
            });

            setModules(prim_mod);
        });
    };
    const handleDeleteAttachment = (k) => {
        let filteredAttachments = data?.attachments.filter((item, ke) => ke !== k);
        onDataChange(filteredAttachments, 'attachments');
    };
    const addComment = (e) => {
        if (e.key === 'Enter') {
            if (data.comment !== '' && data.comment) {
                let cm = comments;
                cm.unshift({
                    activity: 'comment',
                    action_on: moment().format('DD-MMM-YYYY HH:mm'),
                    action_by: 'Adarsh',
                    action_result: data.comment,
                });
                setComments(cm);
                setData({ ...data, comment: '' });
            }
        }
    };
    const fetchTicketComment = () => {
        getTicketComments()
            .then((res) => {
                setTicketCommentDatas(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const deleteComment = (commentId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this comment?',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                deleteTicketComment(commentId)
                    .then((response) => {
                        toast.success('Comment Deleted Successfully');
                        fetchTicketComment();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    };
    const getConvertedFiles = (files) => {
        let attachment_list = data?.attachments || [];
        Object.keys(files).forEach((key) => {
            attachment_list.unshift(files[key]);
        });
        return attachment_list;
    };
    const handleSubmit = () => {
        const requiredFields = {
            statusField: data?.status?.value,
            moduleField: data?.module?.value,
            ticketTitleField: data?.ticket_title,
            ticketDescField: data?.description,
        };
        let error = {};
        Object.keys(requiredFields).forEach((key) => {
            if (!requiredFields[key] || requiredFields[key] === '') {
                error[key] = 'This Field is Required';
            }
        });
        setErrors(error);
        if (Object.keys(error).length === 0) {
            props.saveData(data);
        }
    };

    const validationSchema = Yup.object({
        remarks: Yup.string().required().label('Comment'),
    });

    return (
        <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="pb-0">
                <Modal.Title id="contained-modal-title-vcenter" className="w-100 ">
                    {headEdit ? (
                        <div className="w-100">
                            <Form.Control
                                type="text"
                                name="ticket_title"
                                value={data?.ticket_title ?? ''}
                                onChange={(e) => onDataChange(e.target.value, e.target.name)}
                                placeholder="Enter Title"
                                onBlur={(e) => setHeadEdit(false)}
                                disabled={props.view}
                            />
                            {errors.ticketTitleField && (
                                <p className="text-danger" style={{ fontSize: 12 }}>
                                    {errors.ticketTitleField}
                                </p>
                            )}
                        </div>
                    ) : (
                        <div
                            className={`cursor-pointer ${errors.ticketTitleField ? 'text-danger' : ''}`}
                            style={{ height: 39 }}
                            onClick={(e) => setHeadEdit(true)}>
                            {data?.ticket_title && data?.ticket_title !== ''
                                ? data.ticket_title
                                : 'Enter Title here...'}
                        </div>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <Row>
                    <Col xs={8}>
                        {data?.members?.map((item, k) => (
                            <Badge bg="success" className="mr-2">
                                {item.label}
                            </Badge>
                        ))}

                        <Row>
                            <Col xs={12}>
                                <div className="page-title">
                                    <h5 className={`my-1  ${errors.ticketDescField ? 'text-danger' : ''}`}>
                                        <FeatherIcon.Clipboard style={{ height: 15 }} /> Description
                                    </h5>
                                </div>
                                <div>
                                    {data?.description && (
                                        <RichTextEditor
                                            initialContent={data?.description ?? ''}
                                            onEditorContentChange={(con) => onDataChange(con, 'description')}
                                        />
                                    )}
                                    {!data?.description && (
                                        <RichTextEditor
                                            initialContent={data?.description ?? ''}
                                            onEditorContentChange={(con) => onDataChange(con, 'description')}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
              <Col xs={12}>
                <div className="page-title">
                  <h5 className="my-2">
                    <FeatherIcon.Activity style={{ height: 15 }} />
                    Issue Log
                  </h5>
                </div>
                <div className="w-100">
                  //
                  <div className="py-2">
                                        <Form.Control
                                            type="text"
                                            name="comment"
                                            value={data?.comment ?? ''}
                                            onChange={(e) => onDataChange(e.target.value, e.target.name, e.keyCode)}
                                            placeholder="Add comment"
                                            onKeyDown={addComment}
                                            onBlur={(e) => setHeadEdit(false)}
                                        />
                                    </div>
                                     //
                  <div
                    className=""
                    style={{ maxHeight: 200, overflow: "auto" }}
                  >
                    {comments.map((cmt, k) => {
                      console.log(cmt);
                      return (
                        <div className="w-100 ">
                          {" "}
                          {cmt.action_type === "Update" && (
                            <div className="py-1">
                              <div>
                                <strong>{cmt.action_by ?? "Unknown"}</strong>{" "}
                                {"updated on "}
                                <strong>
                                  {cmt.action_time
                                    ? moment(cmt.action_time).format(
                                        "DD-MM-YYYY HH:mm A"
                                      )
                                    : "--"}
                                </strong>
                              </div>
                              //
                               <p
                                                            className=""
                                                            style={{
                                                                background: '#0d0d0d14',
                                                                padding: 10,
                                                                fontSize: 14,
                                                            }}>
                                                            {cmt.action_result}
                                                        </p> 
                                                      //  
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row> */}
                        <Row>
                            <Col xs={12}>
                                <div className="page-title">
                                    <h5 className="my-2">
                                        {' '}
                                        <FeatherIcon.Paperclip style={{ height: 15 }} />
                                        Attachments
                                    </h5>
                                </div>
                                <div className="py-2 " style={{ maxHeight: 200, overflow: 'auto' }}>
                                    {data?.attachments?.length ? (
                                        data?.attachments.map((item, k) => (
                                            <div
                                                className={`d-flex p-2 ${
                                                    k % 2 === 0 ? 'bg-custom-light-grey' : 'bg-white'
                                                }`}>
                                                <div className="w-100 ">
                                                    <>
                                                        <div>{item?.name}</div>
                                                    </>
                                                </div>
                                                <div
                                                    className="text-info cursor-pointer mr-1"
                                                    onClick={(e) => window.open(item.attachment, '_blank')}>
                                                    <i className="uil uil-download-alt"></i>
                                                </div>
                                                <div
                                                    className="text-danger cursor-pointer"
                                                    onClick={(e) => handleDeleteAttachment(k)}>
                                                    <i className="uil uil-trash-alt mr-1"></i>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <>No Attachment</>
                                    )}
                                </div>
                            </Col>
                            {(props.edit || props.view) && (
                                <Col xs={12}>
                                    <div className="page-title">
                                        <h5 className="my-2">
                                            <FeatherIcon.MessageCircle style={{ height: 15 }} />
                                            Comments(
                                            {
                                                ticketCommentDatas?.filter(
                                                    (commentData) => commentData.ticket === data.ticket_id
                                                ).length
                                            }
                                            )
                                        </h5>
                                    </div>
                                    <div className="py-2 " style={{ maxHeight: 200, overflow: 'auto' }}>
                                        {ticketCommentDatas
                                            ?.filter((commentData) => commentData.ticket === data.ticket_id)
                                            ?.map((ticketComment) => (
                                                <div className="comment_card">
                                                    <div className="comment_wrapper">
                                                        <div className="comment_avatar">
                                                            <div
                                                                style={{
                                                                    height: '1.5rem',
                                                                    width: '1.5rem',
                                                                    borderRadius: '1rem',
                                                                    background: 'steelblue',
                                                                    color: 'white',
                                                                }}>
                                                                <div
                                                                    style={{
                                                                        display: 'grid',
                                                                        placeItems: 'center',
                                                                        height: 'inherit',
                                                                        width: 'inherit',
                                                                    }}>
                                                                    <FeatherIcon.User width="1rem" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="comment_content">
                                                            <div className="user_name">{ticketComment?.username}</div>
                                                            <p>{ticketComment.comment}</p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginTop: '0.2rem',
                                                        }}>
                                                        <div></div>
                                                        {props.view ? null : (
                                                            <div>
                                                                {Number(localStorage.getItem('loggedin_user_id')) ==
                                                                ticketComment?.user_id ? (
                                                                    <FeatherIcon.Trash
                                                                        style={{
                                                                            height: 15,
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() => deleteComment(ticketComment?.id)}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    {props.view ? null : (
                                        <Formik
                                            initialValues={{ remarks: '' }}
                                            onSubmit={(values, { resetForm }) => {
                                                createTicketComment({
                                                    comment: values.remarks,
                                                    ticket: data?.ticket_id,
                                                })
                                                    .then((res) => {
                                                        resetForm({});
                                                        toast.success('Commented successfully');
                                                        setTicketCommentData(res.data);
                                                    })

                                                    .catch((e) => {
                                                        toast.error('Comment Failed');
                                                    });
                                            }}
                                            validationSchema={validationSchema}>
                                            {({
                                                values,
                                                errors,
                                                setFieldValue,
                                                handleChange,
                                                handleBlur,
                                                resetForm,
                                            }) => (
                                                <FormikForm>
                                                    {console.log(values, errors)}
                                                    <Row>
                                                        <Col lg={12}>
                                                            <Input
                                                                name="remarks"
                                                                type="textarea"
                                                                value={values.remarks}
                                                                id="remarks"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                placeholder="Comment..."
                                                            />
                                                            {errors.remarks && (
                                                                <div className="errorMessage">{errors.remarks}</div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Col lg={12} className="mb-none">
                                                        <div
                                                            className="user-form-request-action my-3"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}>
                                                            <div></div>
                                                            <Button type="submit" size="sm">
                                                                Comment
                                                            </Button>
                                                            {/* <Button
                        onClick={() => {
                          setShowCommentModal(false);
                          setShow(false);
                        }}
                        variant="secondary"
                      >
                        Cancel
                      </Button> */}
                                                        </div>
                                                    </Col>
                                                </FormikForm>
                                            )}
                                        </Formik>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col xs={4}>
                        <Row>
                            <Col xs={12}>
                                <div>
                                    <p className="mb-1 mt-3 font-weight-bold">Module</p>
                                    <Creatable
                                        isMulti={false}
                                        options={modules}
                                        value={data.module}
                                        defaultValue={data.module}
                                        isDisabled={props.view}
                                        onChange={(option) => {
                                            onDataChange(option, 'module');
                                        }}
                                        className="react-select"
                                        classNamePrefix="react-select"></Creatable>
                                    {errors.moduleField && (
                                        <p className="text-danger" style={{ fontSize: 12 }}>
                                            {errors.moduleField}
                                        </p>
                                    )}
                                </div>

                                {props.view ? null : (
                                    <div className="py-2 mt-3">
                                        <Button className="w-100 btn-light" onClick={() => fileref.current.click()}>
                                            <FeatherIcon.Paperclip style={{ height: 10 }} /> Add Attachment
                                        </Button>
                                        <input
                                            type="file"
                                            hidden
                                            ref={fileref}
                                            multiple
                                            onChange={(e) =>
                                                onDataChange(getConvertedFiles(e.target.files), 'attachments')
                                            }
                                        />
                                    </div>
                                )}
                                {(props.permissions?.some((t) => t.create === 1 && t.module_id == 69) ||
                                    localStorage.getItem('loggedin_user_role_id') == 1) && (
                                    <div>
                                        {/* <div>
                                            <p className="mb-1 mt-3 font-weight-bold">Due Date</p>
                                            <Flatpickr
                                                placeholder="Due Date"
                                                value={data?.due_date}
                                                options={{ enableTime: false, dateFormat: 'd F Y ' }}
                                                onChange={(date) => {
                                                    onDataChange(date, 'due_date');
                                                }}
                                                className="form-control"
                                            />
                                        </div>
                                        <div>
                                            <p className="mb-1 mt-3 font-weight-bold">Assign to</p>
                                            <Creatable
                                                isMulti={true}
                                                options={data.memberOptions||[]}
                                                defaultValue={data?.members}
                                                value={data?.members}
                                                onChange={(option) => {
                                                    onDataChange(option, 'members');
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"/>
                                        </div> */}
                                        <div>
                                            <p className="mb-1 mt-3 font-weight-bold">Status</p>
                                            <Creatable
                                                isMulti={false}
                                                options={[
                                                    { label: 'Pending', value: 'Pending' },
                                                    { label: 'In-Progress', value: 'In Progress' },
                                                    { label: 'Ready for QA', value: 'Ready for QA' },
                                                    { label: 'Done', value: 'Done' },
                                                    { label: 'Awaiting Approval', value: 'Awaiting Approval' },
                                                    { label: 'Hold', value: 'Hold' },
                                                    { label: 'Awaiting User', value: 'Awaiting User' },
                                                ]}
                                                defaultValue={data?.status}
                                                isDisabled={!props.edit}
                                                value={data?.status}
                                                onChange={(option) => {
                                                    onDataChange(option, 'status');
                                                }}
                                                className="react-select"
                                                classNamePrefix="react-select"></Creatable>
                                            {errors.statusField && (
                                                <p className="text-danger" style={{ fontSize: 12 }}>
                                                    {errors.statusField}
                                                </p>
                                            )}
                                        </div>
                                        {data?.approved_text?.length > 0 && (
                                            <div>
                                                <p className="mb-1 mt-3 font-weight-bold">Approved Message</p>
                                                <Form.Control
                                                    type="text"
                                                    name="approved_text"
                                                    value={data?.approved_text ?? ''}
                                                    onChange={(e) => onDataChange(e.target.value, e.target.name)}
                                                    placeholder="Enter Approve Message"
                                                    onBlur={(e) => setHeadEdit(false)}
                                                    disabled={props.view}
                                                />
                                            </div>
                                        )}
                                        {data?.approved_user?.length > 0 && (
                                            <div>
                                                <p className="mb-1 mt-3 font-weight-bold">Approved By</p>
                                                <p>{data?.approved_user}</p>
                                            </div>
                                        )}
                                        {data?.approved_date && (
                                            <div>
                                                <p className="mb-1 mt-3 font-weight-bold">Approved Date</p>
                                                <p>
                                                    {/* {data?.approved_date} */}
                                                    {moment(data?.approved_date).format('YYYY-MM-DD')}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.onHide()} className="mr-2 btn-secondary">
                    Close
                </Button>

                {props.view ? null : (
                    <Button onClick={() => handleSubmit()} className="btn-primary">
                        {props.edit ? 'Update' : 'Save'}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default TicketDetails;
