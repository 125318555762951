import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, ButtonGroup } from 'reactstrap';
import Swal from 'sweetalert2';

import config from '../../../config';
import { COMMON_API_ERROR } from '../../../constants';
import Form from './compoenent/Forms/userPermission';
import useCompany from './useCompany';
import useCurrencyType from './useCurrencyType';
import useCustomer from './useCustomer';
import useLocation from './useLocation';
import useTeritory from './useTeritory';
import useUserRole from './useUserRole';
import UsersCardView from './compoenent/views/UserCard/UsersCardView';
import UserCard from './compoenent/views/UserCard/UserCard';
import useUserDesignation from './useUserDesignation';

function useUserPermission(props) {
    const defaultValues = {
        user_id: '1',
        currency_type_id: '1',
        company_id: '',
        customer_id: '',
        user_first_name: '',
        user_last_name: '',
        user_role_id: '1',
        user_location: '',
    };
    const [selected, setSelected] = useState(undefined);

    const [customerList, setCustomerList] = useState([]);
    const [customerError, setCustomerError] = useState(null);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(null);
    const [action, setAction] = useState('Create');
    const [initialValues, SetInitialValues] = useState(defaultValues);
    const [refresh, setRefresh] = useState(false)

    const _useLocation = useLocation(props);
    const _useTerritory = useTeritory(props);
    const _useCustomer = useCustomer(props);
    const _useCompany = useCompany(props);
    const _useUserRole = useUserRole();
    const _useUserDesignation = useUserDesignation();
    const _useCurrencyType = useCurrencyType(props);

    useEffect(() => {
        _useLocation.fetch();
        _useTerritory.fetch();
        // _useCustomer.fetch();
        // _useCompany.fetch();
        _useUserRole.fetch();
        // _useCurrencyType.fetch();
    }, []);

    useEffect(() => {
        if (refresh) {
            _useUserDesignation.fetch();
            setRefresh(false)
        }
        else {
            _useUserDesignation.fetch();
        }

    }, [refresh])

    const getLocations = () => {
        let data = _useLocation.customerList,
            list = [];
        if (data) {
            data.map((value) => {
                list.push({ name: value.location_name, value: value.location_id });
            });
        }
        return list.sort((a, b) => a.name.localeCompare(b.name));
    };

    const getTerritories = () => {
        let data = _useTerritory.customerList,
            list = [];

        if (data) {
            data.map((value) => {
                list.push({ label: value.territory_name, value: value.territory_id });
            });
        }
        return list.sort((a, b) => a.label.localeCompare(b.label));
    };
    const getCustomers = () => {
        let data = _useCustomer.customerList,
            list = [];
        if (data) {
            data.map((value) => {
                list.push({ name: value.customer_name, value: value.customer_id });
            });
        }
        return list;
    };

    const getCompany = () => {
        let data = _useCompany.customerList,
            list = [];
        if (data) {
            data.map((value) => {
                list.push({ name: value.company_name, value: value.company_id });
            });
        }
        return list;
    };

    const getUserRole = () => {
        let data = _useUserRole.customerList,
            list = [];
        if (data) {
            data.map((value) => {
                list.push({ label: value.userrole_name, value: value.userrole_id });
            });
        }
        return list.sort((a, b) => a.label.localeCompare(b.label));
    };
    const getUserDesignation = () => {
        let data = _useUserDesignation.customerList,
            list = [];
        if (data) {
            data.map((value) => {
                list.push({ label: value.designation, value: value.id });
            });
        }
        return list.sort((a, b) => a.label.localeCompare(b.label));
    };
    const getCurrencyType = () => {
        let data = _useCurrencyType.customerList,
            list = [];
        if (data) {
            data.map((value) => {
                list.push({
                    label: value.currency_type_code,
                    value: value.currency_type_id,
                });
            });
        }
        return list.sort((a, b) => a.label.localeCompare(b.label));
    };

    const fetch = (parameter) => {
        setCustomerLoading(true);
        const defaultPayload = {
            user_name: '',
            customer_id: '',
            user_role_id: '',
            active: '',
            user_id: localStorage.getItem('loggedin_user_id'),
        };
        const payload = parameter?.payload || defaultPayload;
        axios
            .post(`${config.apiRoot}system/list_user`, payload)
            .then((res) => {
                setCustomerLoading(false);
                setCustomerList(res?.data.data);
            })
            .catch(() => {
                setCustomerLoading(false);
                setCustomerError(COMMON_API_ERROR);
            });
    };
    const getTerritoryNames = (userData) => {
        const namesArray =
            userData?.territory?.map((place) => {
                return place.territory_name;
            }) ?? [];
        const names = namesArray.join(', ');
        return names;
    };

    const customerColumns = [
        {
            dataField: 'sl.no',
            text: 'Sl no.',
            headerStyle: { width: '80px' },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let rowNumber =
                    (localStorage.getItem('page') - 1) * localStorage.getItem('sizePerPage') + (rowIndex + 1);

                return rowNumber;
            },
            // sort: true,
        },
        {
            dataField: 'user_first_name',
            text: 'First Name',
            sort: true,
        },
        {
            dataField: 'user_last_name',
            text: 'Last Name',
            sort: true,
        },
        {
            dataField: 'user_email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'user_role',
            text: 'User Role',
            sort: true,
        },

        {
            dataField: 'user_location',
            text: 'Territory',
            formatter: (row, rowContent) => {
                return (
                    <div>{getTerritoryNames(rowContent)}</div>

                    // < div > { _useLocation.customerList.find((loc) => loc.location_id === rowContent)?.location_name }</ >
                );
            },
        },
        {
            dataField: 'active',
            text: 'Status',
            formatter: (item, row) => {
                if (Boolean(item)) return <div style={{ color: 'green' }}>Active</div>;
                return <div style={{ color: 'red' }}>Inactive</div>;
            },
        },
        {
            dataField: 'user_profile_id',
            text: '',
            headerStyle: { width: '50px' },
            formatter: (rowContent, row) => {
                return row.is_system_user ? (
                    <></>
                ) : (
                    <ButtonGroup>
                        <Button className="btn btn-primary" onClick={() => setSelected(row)}>
                            View
                        </Button>
                        {sessionStorage.getItem('update') === '1' && (
                            <Button
                                className="btn btn-info"
                                onClick={() => handleEditClick(row, "Edit Users & Permissions", localStorage.getItem('user_id'))}>
                                Edit
                            </Button>
                        )}
                        {sessionStorage.getItem('delete') === '1' && (
                            <Button className="btn btn-danger" onClick={() => onDelete(rowContent)}>
                                Delete
                            </Button>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const defaultSorted = [
        {
            dataField: 'user_first_name',
            order: 'asc',
        },
    ];

    const handleClose = () => {
        setVisible(false);
        SetInitialValues(defaultValues);
    };

    const ModalContent = () => {
        return (
            <Form
                reload={fetch}
                initialValues={initialValues}
                actionType={action}
                currencyType={getCurrencyType()}
                userRole={getUserRole()}
                userDesignation={getUserDesignation()}
                location={getLocations()}
                territories={getTerritories()}
                modelClose={handleClose}
                loading={_useUserRole?.loading}
            />
        );
    };

    const CardViewModalContent = () => {
        return (
            <>
                <UserCard selected={selected} setSelected={setSelected} />
            </>
        );
    };

    const openModel = (title = 'Add Users & Permissions', action = 'Create') => {
        setTitle(title);
        setVisible(true);
        setAction(action);

        _useCustomer.fetch();
        _useTerritory.fetch();
        _useCompany.fetch();
        _useUserRole.fetch();
        _useCurrencyType.fetch();
    };

    const handleEditClick = (row, title = "Edit Users & Permissions") => {
        openModel(title, 'Edit');
        SetInitialValues(row);
    };

    const onDelete = (rowContent) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete? ',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let payload = {
                    user_id: rowContent,
                };
                axios
                    .post(`${config.apiRoot}system/remove_user`, payload)
                    .then((res) => {
                        fetch();
                        toast.success(res.data.data?.message);
                    })
                    .catch((e) => {
                        toast.error(COMMON_API_ERROR);
                    });
                // toast.error(COMMON_API_ERROR);
            }
        });
    };

    return {
        fetch,
        customerList,
        customerError,
        customerLoading,
        customerColumns,
        defaultSorted,
        ModalContent,
        openModel,
        onDelete,
        handleEditClick,
        CardViewModalContent,
        visible,
        title,
        keyField: 'user_profile_id',
        handleClose,
        getTerritories,
        getUserRole,
        getUserDesignation,
        selected,
        _useUserDesignation,
        setRefresh
    };
}

export default useUserPermission;
