import moment from 'moment';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Select from 'react-select';
import uuid from 'react-uuid';
import Loader from '../../../../../../components/Loader/loader';

import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    CustomInput,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
} from 'reactstrap';
import TextField from '../../../../../../components/textFeild/textFeild';
import useUserPermission from '../../../useUserPermision';
import SelectSearch from './selectSearch';
import HeaderNav from '../../../../../../components/headerNav/headerNav';
import {
    get_certificationList,
    get_certifications,
    get_organization_list,
} from '../../../../../../pages/sections/api.functions';
import './style.css';
import UserCard from './UserCard';
import * as FeatherIcon from 'react-feather';
import { countries } from 'constants/countryList';
import ModalSettings from 'components/modalSettings/modal';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import classnames from 'classnames';
import { listCountries } from 'helpers/api.functions';
import { debounce } from 'throttle-debounce';
import { connect } from 'react-redux';
import { isLoadingCertificate } from 'redux/notification/actions';

const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    margin: 25px 0;

    label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 18px;
        font-weight: 700;

        input[type='checkbox'] {
            height: 20px;
            width: 20px;
        }
    }
`;

const UsersCardView = ({
    open = true,
    toggleModal,
    calData,
    toggleModalPopup,
    title,
    setOpen,
    isLoadingCertificate,
    loadingCertificate,
    ...rest
}) => {
    var _useUserPermision = useUserPermission();
    var isLoading = _useUserPermision.customerLoading;
    var territoriesList = _useUserPermision.getTerritories();
    // var userRoleList = _useUserPermision.getUserRole();
    var userDesignationList = _useUserPermision.getUserDesignation();

    const collator = new Intl.Collator('en');

    const [userData, setUserData] = useState([]);
    const [searchedData, setSearchedData] = useState();
    const [search, setSearch] = useState();
    const [userRoles, setUserRoles] = useState([]);
    const [userDesignations, setUserDesignations] = useState([]);
    const [territories, setTerritories] = useState([]);
    const [countrys, setCountrys] = useState([]);
    const [citizenships, setCitizenships] = useState([]);
    const [certifications, setCertifications] = useState([]);
    const [certificationData, setCertificationData] = useState([]);
    const [selected, setSelected] = useState(undefined);

    const [headquarters, setHeadquarters] = useState(null);
    const [userByCountry, setUserByCountry] = useState(null);
    const [userByTerritory, setUserByTerritory] = useState(null);
    const [currentData, setCurrentData] = useState(null);

    const [loading, setLoading] = useState(false);
    const [isCountry, setIsCountry] = useState(true);
    const [currentKeyName, setCurrentKeyName] = useState('country_name');
    const [currentName, setCurrentName] = useState('country');
    const [countryList, setCountryList] = useState([]);

    const [modal, setModal] = useState(false);

    const arrayCountries = [];
    if (searchedData) {
        searchedData.forEach((value) =>
            value.territory.forEach((terr) => {
                if (!arrayCountries.includes(terr.territory_name)) {
                    arrayCountries.push(terr.territory_name);
                }
            })
        );
    }

    const fetchCertifications = () => {
        // let payload = { user: data?.user_profile_id || '' };
        let payload = '';
        get_certificationList(payload)
            .then((res) => {
                let results = res.data;
                setCertificationData(results);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchOrganisations = async (query) => {
        setLoading(true);
        try {
            const payload = await get_organization_list(query);
            let results = payload;
            if (results?.length > 0) {
                results.map((item) => {
                    if (Object.keys(item)?.[0] === 'headquarters') {
                        setHeadquarters(item);
                    } else if (Object.keys(item)?.[0] === 'country') {
                        setUserByCountry(item);
                        setCurrentData(item);
                    } else if (Object.keys(item)?.[0] === 'territory') {
                        setUserByTerritory(item);
                    }
                });
                setLoading(false);
            }
        } catch (error) {
            toast.error('Error Fetching Organisations');
            setLoading(false);
        }
    };
    const fetchCountryList = () => {
        let payload = { active: 1, country_name: '' };
        let data = [];
        listCountries(payload)
            .then(async (res) => {
                const sortData = res.data.sort((x, y) => collator.compare(x.country_name, y.country_name));
                sortData.map((item, key) => {
                    data.push({ value: item?.country_id, label: item?.country_name });
                });
                setCountryList(data);
            })
            .catch((e) => {
                console.log(e);
            });
        return {};
    };
    useEffect(() => {
        if (isCountry) {
            setCurrentData(userByCountry);
            setCurrentKeyName('country_name');
            setCurrentName('country');
        } else {
            setCurrentData(userByTerritory);
            setCurrentKeyName('territory_name');
            setCurrentName('territory');
        }
    }, [isCountry, userByCountry, userByTerritory]);

    useEffect(() => {
        // setUserData(_useUserPermision.customerList);
        // setSearchedData(_useUserPermision.customerList);
        fetchCertifications();
        fetchCountryList();
    }, []);



    const handleClose = () => {
        setOpen(false);
    };

    const selfID = localStorage?.getItem('loggedin_user_id');
    const selfRole = JSON.parse(localStorage?.getItem('loggedin_user_role'));

    const debounceSearch = useCallback(
        debounce(1000, (se) => {
            setSearch(se);
        }),
        []
    );

    useEffect(() => {
        const payload = {
            user_name: search || '',
            customer_id: '',
            user_role_id: userRoles?.map((userRole) => userRole.value) || '',
            designation: userDesignations?.map((userDesignation) => userDesignation.label) || '',
            active: '',
            country_id: countrys?.map((country) => country.value) || '',
            // citizenship: (citizenships && citizenships?.map((citizen) => citizen.code)) || '',
            certifications:
                (certifications && certifications?.map((certification) => certification.certification_name)) || '',
            territory_id: territories?.map((territory) => territory.value) || '',
            user_id: localStorage.getItem('loggedin_user_id'),
        };
        if (loadingCertificate) {
            fetchCertifications();
            _useUserPermision.setRefresh(true);
            // setUserDesignationList(_useUserPermision.getUserDesignation())
            fetchOrganisations(payload);
            isLoadingCertificate(false);
        }
        fetchOrganisations(payload);
    }, [userRoles, userDesignations, territories, citizenships, certifications, countrys, search, loadingCertificate]);

    const copyToClipboard = (data) => {
        const { user_first_name, user_last_name, date_of_birth, mobile_number, territory, msic_number } = data;
        const countryCode = countries?.find((c) => c.code === data?.citizenship)?.dial_code;
        const phone = mobile_number != 'null' ? `${countryCode || ''}${mobile_number}` : '';

        const copyData = `Full Name: ${user_first_name || ''} ${user_last_name || ''} \nDOB: ${(date_of_birth && moment(date_of_birth).format('DD/MM/YYYY')) || ''
            } \nPhone:${phone} \nEmail: ${territory?.[0]?.email || ''} \nMSIC Number: ${msic_number !== 'null' ? msic_number : ''
            } `;

        navigator.clipboard
            .writeText(copyData)
            .then(() => {
                toast.success('Text copied to clipboard');
                // Optionally, you can show a success message or perform any other desired action
            })
            .catch((error) => {
                console.error('Error copying text to clipboard:', error);
                // Optionally, you can show an error message or handle the error in another way
            });
    };
    const GetHeadQuaters = ({ data }) => {
        return <>
            {
                data.headquarters?.map((headquarter, i) => {

                    const newA = headquarter?.user_profile?.filter(i => typeof i?.headquarters_priority === "number" && i?.headquarters_priority !== 0)?.sort((a, b) => {
                        if (a.headquarters_priority === b.headquarters_priority) { return collator.compare(a.user_first_name, b.user_first_name) }
                        else {
                            return a.headquarters_priority - b.headquarters_priority;
                        }
                    });
                    const newB = headquarter?.user_profile?.filter(a => {
                        return !newA.some(newa => newa.user_profile_id === a.user_profile_id);
                    }).sort((a, b) => a.user_first_name.localeCompare(b.user_first_name));
                    const newC = [...newA, ...newB];
                    // ?.sort((x, y) => collator.compare(x.user_first_name, y.user_first_name))
                    return newC?.map((data) => (
                        <div className="user_card" key={i}>
                            <div className="image_content">
                                <img
                                    // src={require("../../../../../assets/images/business_person.png")}
                                    src={
                                        data.user_photograph
                                            ? data.user_photograph
                                            : require('../../../../../../assets/images/users/dummy.png')
                                    }
                                />
                            </div>
                            <div className="content">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        // marginBottom: '10px',
                                    }}>
                                    <p className="full_name">
                                        {data.user_first_name} {data.user_last_name}
                                    </p>
                                   

                                    <div>
                                        <i onClick={() => copyToClipboard(data)}>
                                            <FeatherIcon.Copy width="17" height="17" />
                                        </i>
                                        <i
                                            className="icon ml-1 view_card"
                                            onClick={() => setSelected(data)}>
                                            <FeatherIcon.Eye width="17" height="17" />
                                        </i>
                                        {data?.user_id === Number(selfID) ||
                                            selfRole?.userrole_name === 'Admin' ||
                                            selfRole?.userrole_name === 'Super Admin' ? (
                                            <i
                                                className="icon ml-1 view_card"
                                                onClick={() =>
                                                    _useUserPermision?.handleEditClick(
                                                        data,
                                                        'Edit User',
                                                        localStorage.getItem('user_id')
                                                    )
                                                }>
                                                <FeatherIcon.Edit width="17" height="17" />
                                            </i>
                                        ) : null}
                                    </div>
                                </div>
                                <div>
                                     <p className="short_email">
                                        {data.designation == "Null" ? "" : data.designation}
                                    </p>
                                </div>

                                <div class="focus-content">
                                    <div className="content_email">
                                        <p className="short_email">E : {data?.user_email}</p>
                                        <a
                                            href={`mailto:${data?.user_email} `}
                                            style={{ color: '#6c757d' }}>
                                            <i
                                                className="icon ml-1 view_card "
                                            // onClick={() => setSelected(data)}
                                            >
                                                <FeatherIcon.Mail width="20" height="20" />
                                            </i>
                                        </a>
                                    </div>
                                    <div className="mobile_number">
                                        <p>
                                            M :{' '}
                                            {
                                                countries?.find(
                                                    (c) => c.code === data?.citizenship
                                                )?.dial_code
                                            }
                                            {data?.mobile_number}
                                        </p>
                                        <i
                                            className="icon ml-1 view_card"
                                        // onClick={() => setSelected(data)}
                                        >
                                            <FeatherIcon.PhoneCall width="20" height="20" />
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                )
            }</>

    }

    const GetUserByPriorities = ({ headquarter, i, currentKeyName, currentPriority }) => {
        const newA = headquarter?.user_profile?.filter(i => typeof i?.[`${currentPriority}`] === "number" && i?.[`${currentPriority}`] !== 0)?.sort((a, b) => {
            if (a[`${currentPriority}`] === b[`${currentPriority}`]) { return collator.compare(a.user_first_name, b.user_first_name) }
            else {
                return a[`${currentPriority}`] - b[`${currentPriority}`];
            }
        });
        const newB = headquarter?.user_profile?.filter(a => {
            return !newA.some(newa => newa.user_profile_id === a.user_profile_id);
        }).sort((a, b) => a.user_first_name.localeCompare(b.user_first_name));
        const newC = [...newA, ...newB];
        return <>
            {headquarter?.user_profile.length < 1 ? null : (
                <div className="user_card_wrapper">
                    <div className="user-card-container">
                        <div className="card_title">
                            <h4>{headquarter?.[`${currentKeyName}`]} </h4>
                        </div>
                        <div className="user_cards">
                            {newC?.map((data) => (
                                <div className="user_card" key={i}>
                                    <div className="image_content">
                                        <img
                                            // src={require("../../../../../assets/images/business_person.png")}
                                            src={
                                                data.user_photograph
                                                    ? data.user_photograph
                                                    : require('../../../../../../assets/images/users/dummy.png')
                                            }
                                        />
                                    </div>
                                    <div className="content">
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginBottom: '10px',
                                            }}>
                                            <p className="full_name">
                                                {data.user_first_name} {data.user_last_name}
                                            </p>
                                            <div>
                                                <i onClick={() => copyToClipboard(data)}>
                                                    <FeatherIcon.Copy
                                                        width="17"
                                                        height="17"
                                                    />
                                                </i>
                                                <i
                                                    className="icon ml-1 view_card"
                                                    onClick={() => setSelected(data)}>
                                                    <FeatherIcon.Eye
                                                        width="17"
                                                        height="17"
                                                    />
                                                </i>
                                                {data?.user_id === Number(selfID) ||
                                                    selfRole?.userrole_name === 'Admin' ||
                                                    selfRole?.userrole_name ===
                                                    'Super Admin' ? (
                                                    <i
                                                        className="icon ml-1 view_card"
                                                        onClick={() =>
                                                            _useUserPermision?.handleEditClick(
                                                                data,
                                                                'Edit User',
                                                                localStorage.getItem(
                                                                    'user_id'
                                                                )
                                                            )
                                                        }>
                                                        <FeatherIcon.Edit
                                                            width="17"
                                                            height="17"
                                                        />
                                                    </i>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div class="focus-content">
                                            <div className="content_email">
                                                <p className="short_email">
                                                    E : {data?.user_email}
                                                </p>
                                                <a
                                                    href={`mailto:${data?.user_email} `}
                                                    style={{ color: '#6c757d' }}>
                                                    <i
                                                        className="icon ml-1 view_card "
                                                    // onClick={() => setSelected(data)}
                                                    >
                                                        <FeatherIcon.Mail
                                                            width="20"
                                                            height="20"
                                                        />
                                                    </i>
                                                </a>
                                            </div>
                                            <div className="mobile_number">
                                                <p>
                                                    M :{' '}
                                                    {
                                                        countries?.find(
                                                            (c) =>
                                                                c.code === data?.citizenship
                                                        )?.dial_code
                                                    }
                                                    {data?.mobile_number}
                                                </p>
                                                <i
                                                    className="icon ml-1 view_card"
                                                // onClick={() => setSelected(data)}
                                                >
                                                    <FeatherIcon.PhoneCall
                                                        width="20"
                                                        height="20"
                                                    />
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    }

    return (
        <>
            <ModalSettings {..._useUserPermision} size={'lg'} />
            <HeaderNav navList={rest.navList} selectedIndex={rest.tab} onNavClick={rest.onNavClick} />
            <div class="row my-3" style={{ flexBasis: '90%', alignItems: 'center' }}>
                {/* <div>Users View</div> */}

                <div class="col-2">
                    <Select
                        // className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Designation"
                        isMulti={true}
                        options={userDesignationList}
                        value={userDesignations}
                        // isSearchable={true}
                        onChange={(value) => setUserDesignations(value)}
                    />
                </div>
                {isCountry ? (
                    <div class="col-2">
                        <Select
                            // className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Country"
                            isMulti={true}
                            options={countryList}
                            value={countrys}
                            // isSearchable={true}
                            onChange={(value) => setCountrys(value)}
                        />
                    </div>
                ) : (
                    <div class="col-2">
                        <Select
                            // className="react-select"
                            classNamePrefix="react-select"
                            placeholder="Territory"
                            isMulti={true}
                            options={territoriesList}
                            value={territories}
                            // isSearchable={true}
                            onChange={(value) => setTerritories(value)}
                        />
                    </div>
                )}

                <div class="col-2">
                    <Select
                        // className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Certifications"
                        isMulti={true}
                        options={certificationData}
                        value={certifications}
                        getOptionLabel={(option) => option.certification_name}
                        getOptionValue={(option) => option.certification_name}
                        // isSearchable={true}
                        onChange={(value) => setCertifications(value)}
                    />
                </div>
                <div class="col-2 search-bar">
                    <TextField
                        placeholder="Search by Name"
                        id="myInput"
                        onChange={(e) => debounceSearch(e.target.value)}
                    />
                </div>
                <div
                    style={{
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '0.7rem',
                        fontWeight: 'lighter',
                    }}
                    onClick={() => {
                        setTerritories([]);
                        setUserRoles([]);
                        setCertifications([]);
                        setCitizenships([]);
                        setCountrys([]);
                        setUserDesignations([]);
                        setSearch('');
                        const inputElement = document.getElementById('myInput');
                        if (inputElement) {
                            inputElement.value = '';
                        }
                    }}>
                    Clear All
                </div>
            </div>

            {isLoading || loading ? (
                <Loader />
            ) : (
                <>
                    <div className="user_card_wrapper">
                        <div className="user-card-container">
                            <div className="card_title">
                                <h4>Head Office </h4>
                            </div>
                            <div className="user_cards">
                                {
                                    !!headquarters && <GetHeadQuaters data={headquarters} />
                                }
                                {/* {headquarters &&
                                    getHeadquarters({ data: headquarters })
                                } */}
                            </div>
                        </div>
                    </div>
                    {/* <RadioContainer>
                        <label>
                            <input
                                type="checkbox"
                                checked={isCountry}
                                onChange={() => setIsCountry(true)}
                            />
                            Country
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={!isCountry}
                                onChange={() => setIsCountry(false)}
                            />
                            Territory
                        </label>
                    </RadioContainer> */}
                    <Nav tabs pills style={{ padding: '10px 0' }}>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: isCountry, 'no-border-bottom': isCountry })}
                                onClick={() => setIsCountry(true)}
                                style={{ cursor: 'pointer' }}>
                                Country
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: !isCountry, 'no-border-bottom': !isCountry })}
                                onClick={() => setIsCountry(false)}
                                style={{ cursor: 'pointer' }}>
                                Territory
                            </NavLink>
                        </NavItem>
                    </Nav>
                    {userByCountry &&
                        currentData[currentName]
                            ?.sort((a, b) => a[currentKeyName].localeCompare(b[currentKeyName]))
                            .map((headquarter, i) => (
                                <GetUserByPriorities headquarter={headquarter} i={i} currentKeyName={currentKeyName} currentPriority={currentName === 'country' ? "country_priority" : 'organizations_priority'} />
                            ))}
                </>
            )}

            {selected && <UserCard selected={selected} setSelected={setSelected} />}
        </>
    );
};

const mapStateToProps = ({ Notifications: { loadingCertificate } }) => ({
    loadingCertificate,
});

const mapDispatchToProps = {
    isLoadingCertificate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(UsersCardView);
