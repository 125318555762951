import axios from 'axios';
import config from '../../../config';
import { useEffect, useState } from 'react';
import { COMMON_API_ERROR } from '../../../constants';
import moment from 'moment';
import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CompanyForm from './compoenent/Forms/company';
import useCurrency from './useCurrency';
import useCountry from './useCountry';

function useCompany(props) {
    const _useCurrencyType = useCurrency(props);
    const _useCountry = useCountry(props);
    const defaultValues = {
        company_name: '',
        currency: '',
        company_logo: '',
        remarks: '',
        address1: '',
        address2: '',
        suburb: '',
        city: '',
        country: '',
        email: '',
        notes: '',
    };

    useEffect(() => {
        // _useCurrencyType.fetch();
        // _useCountry.fetch();
        // ModalContent()
    }, []);

    const [customerList, setCustomerList] = useState([]);
    const [customerError, setCustomerError] = useState(null);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(null);
    const [action, setAction] = useState('Add');
    const [initialValues, SetInitialValues] = useState(defaultValues);

    const fetch = () => {
        setCustomerLoading(true);
        let payload = {
            customer_id: '',
            customer_name: '',
        };
        axios
            .post(`${config.apiRoot}configuration/company_list`, payload)
            .then((res) => {
                setCustomerLoading(false);
                setCustomerList(res?.data.data);
            })
            .catch(() => {
                setCustomerLoading(false);
                setCustomerError(COMMON_API_ERROR);
            });
    };

    const keyField = 'company_id';

    const customerColumns = [
        {
            dataField: 'sl.no',
            text: 'Sl no.',
            headerStyle: { width: '65px' },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
            },
            sort: true,
        },
        {
            dataField: 'company_name',
            text: 'Company Name',
            sort: true,
        },
        {
            dataField: 'remarks',
            text: 'Remarks',
            sort: true,
        },
        {
            dataField: 'country',
            text: 'Country',
            sort: true,
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'created_date',
            text: 'Created At',
            formatter: (value) => {
                if (Boolean(value)) return moment(value, 'YYYY-MM-DD').format('DD MMMM YYYY');
            },
        },
        {
            dataField: 'active',
            text: 'Status',
            formatter: (item, row) => {
                if (Boolean(item)) return <div style={{ color: 'green' }}>Active</div>;
                return <div style={{ color: 'red' }}>Inactive</div>;
            },
        },
        {
            dataField: 'company_id',
            text: '',
            headerStyle: { width: '5px' },
            formatter: (rowContent, row) => {
                return (
                    <ButtonGroup>
                        <Button className="btn btn-info" onClick={() => handleEditClick(row)}>
                            Edit
                        </Button>
                        <Button className="btn btn-danger" onClick={() => onDelete(rowContent)}>
                            Delete
                        </Button>
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleClose = () => {
        setVisible(false);
        SetInitialValues(defaultValues);
    };

    const ModalContent = () => {
        return (
            <CompanyForm
                reload={fetch}
                initialValues={initialValues}
                actionType={action}
                modelClose={handleClose}
                Currency={_useCurrencyType.currency}
                country={_useCountry.country}
            />
        );
    };

    const openModel = (title = 'Add Company', action = 'Add') => {
        setTitle(title);
        setVisible(true);
        setAction(action);
        _useCurrencyType.fetch();
        _useCountry.fetch();
    };

    const handleEditClick = (row) => {
        openModel('Edit Company', 'Edit');
        SetInitialValues(row);
    };

    const onDelete = (rowContent) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete? ',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                // let payload = {
                //     location_id: rowContent
                // };
                // axios.post(`${config.apiRoot}configuration/delete_location`, payload)
                //     .then((res) => {
                //         fetch();
                //         toast.success(res.data.data?.message);
                //     })
                //     .catch((e) => {
                //         toast.error(COMMON_API_ERROR);
                //     })
                toast.error(COMMON_API_ERROR);
            }
        });
    };

    return {
        fetch,
        customerList,
        customerError,
        customerLoading,
        customerColumns,
        visible,
        ModalContent,
        handleClose,
        title,
        openModel,
        initialValues,
        keyField,
    };
}

export default useCompany;
