import React from "react";
import { useFormik } from "formik";
import TextField from "../../../../../components/textFeild/textFeild";
import { Row, Col } from "reactstrap";
import SelectField from "../../../../../components/selectField/selectField";
import { Button } from "react-bootstrap";
import axios from "axios";
import config from "../../../../../config";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { COMMON_API_ERROR } from "../../../../../constants";

const validationSchema = Yup.object().shape({
    company_name: Yup.string().required().label('Company'),
    currency: Yup.string().required().label('Currency'),
    address1: Yup.string().required().label('Address1'),
    country: Yup.string().required().label('Country'),
    city: Yup.string().required().label('City'),
});

const CompanyForm = ({ country = [], actionType = "Add", modelClose = () => { }, initialValues, reload = () => { }, Currency = [] }) => {

    let _initialValues = actionType === "Add" ? {
        "company_name": "",
        "currency": "",
        "company_logo": "",
        "remarks": "",
        "address1": "",
        "address2": "",
        "suburb": "",
        "city": "",
        "country": "",
        "email": "",
        "notes": "",
        "active": "1",
        "created_by_user": localStorage.getItem('loggedin_user_id'),
        "telephone": "",
        "website": "",

    } : initialValues;

    const formik = useFormik({
        initialValues: _initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let req;
            switch (actionType) {
                case "Add":
                    req = axios.post(`${config.apiRoot}configuration/create_location`, values);
                    break;
                case "Edit":
                    req = axios.post(`${config.apiRoot}configuration/update_location_view`, values);
                    break;
                default:
                    req = axios.post(`${config.apiRoot}configuration/create_location`, values);
                    break;
            }
            req.then((res) => {
                modelClose();
                reload();
                toast.success(res.data.data.message);
            })
                .catch((e) => {
                    toast.error(e.response.data.message || COMMON_API_ERROR);
                })
            // toast.error(COMMON_API_ERROR);
        }
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit} id="settingsCompany">
                <Row>
                    <Col lg={6}>
                        <TextField
                            label="Company Name"
                            required={true}
                            name="company_name"
                            value={formik.values.company_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.company_name && formik.touched.company_name && formik.errors.company_name}
                        />
                    </Col>
                    <Col lg={6}>
                        <SelectField
                            label={"Currency"}
                            required={true}
                            name={"currency"}
                            firstLabel={"Select Currency"}
                            list={Currency}
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.currency && formik.touched.currency && formik.errors.currency}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Remarks"
                            required={false}
                            name="remarks"
                            value={formik.values.remarks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.remarks && formik.touched.remarks && formik.errors.remarks}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Email"
                            required={false}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.email && formik.touched.email && formik.errors.email}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Address Line 1"
                            required={true}
                            name="address1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.address1 && formik.touched.address1 && formik.errors.address1}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Address Line 2"
                            required={false}
                            name="address2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.address2 && formik.touched.address2 && formik.errors.address2}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Suburb"
                            required={false}
                            name="suburb"
                            value={formik.values.suburb}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.suburb && formik.touched.suburb && formik.errors.suburb}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="City"
                            required={true}
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.city && formik.touched.city && formik.errors.city}
                        />
                    </Col>

                    <Col lg={6}>
                        <SelectField
                            label={"Country"}
                            required={true}
                            name={"country"}
                            firstLabel={"Select Country"}
                            list={country}
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.country && formik.touched.country && formik.errors.country}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Telephone"
                            required={false}
                            name="telephone"
                            value={formik.values.telephone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.telephone && formik.touched.telephone && formik.errors.telephone}
                        />
                    </Col>
                    <Col lg={6}>
                        <TextField
                            label="Website Details"
                            required={false}
                            name="website"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.website && formik.touched.website && formik.errors.website}
                        />
                    </Col>
                    <Col lg={6}>
                        <SelectField
                            label={"Active"}
                            required={false}
                            name={"active"}
                            firstLabel={"Select Status"}
                            list={[
                                { name: "Active", value: "1" },
                                { name: "InActive", value: "0" },
                            ]}
                            value={formik.values.active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        // error={formik.errors.active && formik.touched.active && formik.errors.active}
                        />
                    </Col>
                    <Col lg={12}>
                        <TextField
                            label="Notes"
                            type={"textarea"}
                            name="notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.notes && formik.touched.notes && formik.errors.notes}
                        />
                    </Col>

                    <Col lg={12}>
                        <Button id={"btnSubmit"} type={"submit"}>Submit</Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};

export default CompanyForm;