import React, { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { CardBody, Card, ModalBody, Modal, ModalHeader } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from 'components/Loader/loader';
import { useHistory } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import ConvertDate from 'utils/timeZone';

const DashboardTransaction = (props) => {
    const NoDataIndication = () => (
        <div className="EmptyBlock">
            <div className="rect_Emplty">No Data Available</div>
        </div>
    );

    const history = useHistory();
    const { SearchBar } = Search;
    const [openNote, setOpenNote] = useState(false);
    const [noteDetail, setNoteDetail] = useState('');

    const columns = [
        {
            dataField: 'booking_number',
            text: 'Booking Number',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return (
                    <div
                        style={{ color: 'steelblue', cursor: 'pointer' }}
                        onClick={() => window.open(`/apps/bookingdetails/${row.booking_id}`, '_blank')}>
                        {rowContent}
                    </div>
                );
            },
        },
        {
            dataField: 'vessel_name',
            text: 'Vessel',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return <div>{rowContent || '-'}</div>;
            },
        },
        // {
        //     dataField: 'customer_name',
        //     text: 'Customer',
        //     sort: true,
        //     headerClasses: 'id-custom-cell',
        // },

        // {
        //     dataField: 'schedule_date',
        //     text: 'Schedule Survey Date',
        //     sort: true,
        //     headerClasses: 'id-custom-cell',
        //     formatter: (rowContent, row) => {
        //         let date = rowContent?.split(',');
        //         let testDate = date?.map((individualDate) => {
        //             return ConvertDate(individualDate);
        //         });
        //         return <div>{testDate?.join(',')}</div>;
        //     },
        // },

        {
            dataField: 'subsolutions_name',
            text: 'SubSolution',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'location_name',
            text: 'Location',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
    ].filter((item) => !item.hidden);

    return (
        <Card>
            <h6 style={{ fontWeight: 'bold', marginLeft: '20px' }}>{props?.title}</h6>

            <CardBody>
                {props?.isBookingLoading ? (
                    <Loader />
                ) : (
                    <div
                        className="dashboard-table"
                        style={{
                            maxHeight: '500px',
                            overflowY: 'auto',
                        }}>
                        <ToolkitProvider
                            bootstrap4
                            keyField="activity_id"
                            data={props?.data || []}
                            columns={columns}
                            search>
                            {(props) => (
                                <React.Fragment>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        striped={true}
                                        wrapperClasses="table-responsive"
                                        noDataIndication={() => <NoDataIndication />}
                                    />
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    </div>
                )}

                {/* <div style={{ marginTop: '15px' }}>Total count : {props.count}</div> */}
            </CardBody>
        </Card>
    );
};

export default DashboardTransaction;
