import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';

const LineChartFinance = (props) => {
    const { viewCard = true, title, performanceChartTab } = props;

    const monthly = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const quarterly = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];
    const halfYearly = ['Jan-Jun', 'Jul-Dec'];
    // const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    // const day = props.data?.map((list) => moment(list?.day).format('ddd, DD MMM'));
    // const week = props.data?.map((list) => 'week ' + moment(list?.week).week());
    // const month = props.data?.map((list) => moment(list?.month).format('MMM YYYY'));
    const series = [
        {
            // name: performanceChartTab === 'revenue' ? 'Revenue' : 'Jobs',
            name: 'Revenue',
            // data: props.data,
            data: props?.data?.data?.map((list) => list?.total || 0) || [],
        },
    ];
    const options = {
        markers: {
            size: 5,
            colors: ['#008ffb'],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 7,
            },
        },
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false,
            },
            toolbar: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: title,
            align: 'left',
            style: {
                fontWeight: 'bold',
            },
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories:
                props?.format === 'month'
                    ? monthly
                    : props?.format === 'quarter'
                    ? quarterly
                    : props?.format === 'six_month'
                    ? halfYearly
                    : props?.format === 'yearly'
                    ? props?.data?.data?.map((list) => list?.month || '')
                    : monthly || [],
            // categories: props.data?.data?.map((list) => `Month ${list.month}`),
        },
    };

    // const data = props.data
    //     ?.sort((a, b) => {
    //         return a.month - b.month;
    //     })
    //     ?.map((list) => list.count);
    return (
        <div>
            <div className="chart_based_on_app_dates">
                <Chart options={options} series={series} className="apex-charts" height={296} />
            </div>
        </div>
    );
};

export default LineChartFinance;
