

import React from "react";
import { useField, useFormikContext } from "formik";
import RichTextEditor from '../components/RichTextEditor';





const Texteditor = (props) => {
    const { setFieldValue } = useFormikContext();

    const [field] = useField(props);
    const initialContent = props.initialContent;



    return (
        <RichTextEditor onEditorContentChange={(content) => { setFieldValue(field.name, content); }} initialContent={initialContent} />

    );

}


export default (Texteditor);
