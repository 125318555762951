import Loader from 'components/Loader/loader';
import React, { useEffect, useState } from 'react';
import PaginationComponent from 'react-reactstrap-pagination';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { getNotifications, readNotifications } from '../api.functions';
import NotificationDetails from './NotificationDetails';
import SingleNotification from './SingleNotification';
import { connect } from 'react-redux';
import { getNotification, getSocketResponse } from 'redux/notification/actions';

const NewNotificationText = styled.div`
    width: 100%;
    color: #047cc4 !important;
`;
const InboxDiv = styled.div`
    width: 45%;
    padding: 1.2rem;
    background-color: #fff;
    position: relative;

    .__notification__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        gap: 1rem;
    }
`;
const ActiveRecord = styled.div`
    width: 55%;
    padding: 1.2rem;
    background-color: #fff;
`;

const Inbox = ({ setTotalInbox, refresh, setRefresh, eventRes, getSocketResponse }) => {
    const [open, setOpen] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [result, setResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [is_Deleted, setIs_Deleted] = useState(false);
    const [isNewNotification, setIsNewNotification] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [isReadCount, setIsReadCount] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const fetchNotifications = (page_size = '5', page = '1') => {
        setIsLoading(true);
        getNotifications('', '', page_size, page)
            .then((res) => {
                const data = res || [];
                setResult(data);
                setNotifications(data?.results);
                setIsLoading(false);
                setTotalInbox(data?.count);
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
            });
    };
    const readNotification = (data, id) => {
        // setLoading(true);

        readNotifications(data, id)
            .then((res) => {
                const data = res || [];
                // setResponse(data);
                // setLoading(false);
                // if (data?.response_code === 200) {
                //     toast.success(data?.message);
                // }
                localStorage.setItem("from_read", "true11")

                if (data?.message === 'Archived Successfully') {
                    // toast.error(data?.message);
                    setIs_Deleted(true);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error(data?.message);
                // setLoading(false);
            });
    };


    useEffect(() => {
        if (refresh) {
            fetchNotifications(5, currentPage);
            setRefresh(false)
        }
        fetchNotifications(5, currentPage);
        setIs_Deleted(false);
        setOpen('');
        const userDetails = JSON.parse(localStorage.getItem('br_user'));
        setAvatar(userDetails?.avatar);


        // getNotification("", "", 5, 1)
    }, [currentPage, refresh, is_Deleted]);

    useEffect(() => {
        if (eventRes?.count && !eventRes?.isRead) {
            setIsNewNotification(true);
            const timeoutId = setTimeout(() => {
                setIsNewNotification(false);
            }, 5000);
            return () => clearTimeout(timeoutId);
        }
    }, [eventRes]);


    if (isLoading) {
        return <Loader />;
    }
    const setCurrentPageNo = (e) => {
        setCurrentPage(e);
    };
    return (
        <>
            {isNewNotification && (
                <NewNotificationText>
                    You have new notifications. Please refresh for the latest notifications.
                </NewNotificationText>
            )}
            {notifications?.length <= 0 ? (
                <h3>No Notifications</h3>
            ) : (
                <>

                    <div style={{ display: 'flex' }}>
                        <InboxDiv>
                            <div className="__notification__list">
                                {notifications?.map((d) => (
                                    <SingleNotification
                                        id={d.id}
                                        key={d.id}
                                        title={d?.subject}
                                        avatar={avatar}
                                        time={d.created_date}
                                        project={d.project}
                                        type={d.module}
                                        isDeleted={d.archived}
                                        isRead={d.is_read}
                                        setOpen={setOpen}
                                        obj={d}
                                        readNotification={readNotification}
                                        severity_type_name={d?.severity_type_name}
                                        due_date={d?.due_date}
                                        schedule_survey_date={d?.schedule_survey_date}
                                        vessel_name={d?.vessel_name}
                                    />
                                ))}
                            </div>
                        </InboxDiv>

                        <ActiveRecord>
                            {notifications
                                ?.filter((d) => d.id === open)
                                ?.map((fiterData) => (
                                    <NotificationDetails
                                        id={fiterData.id}
                                        key={fiterData.id}
                                        title={fiterData?.subject}
                                        avatar={avatar}
                                        time={fiterData.created_date}
                                        project={fiterData.project}
                                        type={fiterData.module}
                                        isDeleted={fiterData.archived}
                                        isRead={fiterData.is_read}
                                        setOpen={setOpen}
                                        obj={fiterData}
                                        details={fiterData?.details}
                                        subject={fiterData?.subject}
                                        readNotification={readNotification}
                                        severity_type_name={fiterData?.severity_type_name}
                                        due_date={fiterData?.due_date}
                                        schedule_survey_date={fiterData?.schedule_survey_date}
                                        vessel_name={fiterData?.vessel_name}
                                    />
                                ))}
                        </ActiveRecord>
                    </div>
                    {result?.count > 5 && (
                        <PaginationComponent
                            totalItems={result?.count}
                            pageSize={5}
                            onSelect={setCurrentPageNo}
                            defaultActivePage={currentPage}
                        />
                    )}
                </>
            )}
        </>
    );
};

const mapStateToProps = ({ Notifications: { loading, notificationList, error, eventRes } }) => ({
    loading,
    notificationList,
    error,
    eventRes,
});

const mapDispatchToProps = {
    getNotification,
    getSocketResponse
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Inbox);
