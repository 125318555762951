import React from 'react';

function CustomLoaderComponent(props) {
    return (
        <>
            {props.open && (
                <>
                    <div className="loader-37"></div>
                </>
            )}
        </>
    );
}

export default CustomLoaderComponent;
