import React from 'react';
import LineChartAppointment from 'pages/charts/LineChartAppointment';
import { Button, ButtonGroup, Card, CardBody } from 'reactstrap';
import LoaderBeta from 'components/LoaderBeta/loader';

const PerformanceRevenue = ({
    title,
    setPASelected,
    appointmentPerformance,
    isLoadingAppointmentPerformance,
    pASelected,
    performanceChartTab,
}) => {
    return (
        <div
            style={{
                minHeight: '395px',
            }}>
            <div>
                {isLoadingAppointmentPerformance ? (
                    <LoaderBeta height="280px" />
                ) : (
                    <LineChartAppointment
                        title={title}
                        data={appointmentPerformance}
                        format={pASelected}
                        performanceChartTab={performanceChartTab}
                    />
                )}

                <div
                    className="app_btn_grps"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <ButtonGroup size="sm" className="my-2">
                        <Button
                            color="primary"
                            outline
                            onClick={() => setPASelected('day')}
                            active={pASelected === 'day'}>
                            Day
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => setPASelected('week')}
                            active={pASelected === 'week'}>
                            Week
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => setPASelected('month')}
                            active={pASelected === 'month'}>
                            Month
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

export default PerformanceRevenue;
