import React, { useState } from 'react';
import styled from 'styled-components';
import { MdDelete } from 'react-icons/md';
import { BiUndo } from 'react-icons/bi';
import { TiTick } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import { Badge, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Addactivity from 'pages/sections/addactivity';
import { getRateList } from 'pages/dashboard/api.functions';
import TicketDetails from 'pages/apps/ticketingSystem/manage';
import { getTickets, getUserList, getUserModules, getUserRole } from 'pages/apps/api.functions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import userAvatar from 'assets/images/users/defaultUser.png';

const SingleNoficationDiv = styled.div`
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding: 0.3rem;
    /* height: 90px; */
    display: flex;
    gap: 1.2rem;
    align-items: flex-start;
    position: relative;
    width: 100%;

    .__is_new {
        position: absolute;
        height: 10px;
        width: 10px;
        background-color: tomato;
        border-radius: 50%;
        right: 2%;
        top: 18%;
    }
    .__is_Read {
        position: absolute;
        right: 2%;
        top: 10%;
    }
    .__delete {
        position: absolute;
        right: 1%;
        bottom: 18%;
        cursor: pointer;
    }
    .__img {
        width: 40px;
        img {
            max-width: 100%;
            border-radius: 50%;
            border: 1px solid #f3f4f7;
        }
    }
    .__notifications_details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
        justify-content: center;
        .__des {
            .__title {
                font-size: 1rem;
                font-weight: 700;
                color: #3b414e;
                cursor: pointer;
            }
            .__notification_time {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 0.4rem;
                .__dot {
                    width: 4px;
                    height: 4px;
                    background-color: #6c757d;
                    border-radius: 50%;
                }
                span {
                    font-size: 0.65rem !important;
                    font-weight: 600;
                }
            }
        }
    }
    .__actions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.6rem;
        button {
            background-color: aliceblue;
        }
        span {
            font-size: 0.75rem;
            font-weight: 600;
        }
        .__badget_link {
            background-color: #f3f4f7;
            padding: 0.2rem 0.5rem;
            border-radius: 0.2rem;
            font-size: 0.65rem;
            font-weight: 600;
            color: #3b414e;
            text-transform: capitalize;
            cursor: pointer;
        }
    }
`;

const SingleNotification = ({
    id,
    title,
    avatar,
    time,
    project,
    type,
    isDeleted,
    isRead,
    setOpen,
    obj,
    readNotification,
    severity_type_name,
    due_date,
    vessel_name,
    schedule_survey_date,
    deleteNotification,
}) => {
    const [is_Read, setIs_Read] = useState(isRead);
    const [openModal, setOpenModal] = useState(false);
    const [records, setRecords] = useState([]);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [customerError, setCustomerError] = useState('');

    //for ticket
    const [permissions, setPermissions] = React.useState([]);
    const [ticketlist, setTicketList] = React.useState([]);
    const [openDetails, setOpenDetails] = React.useState(false);
    const [openEditDetails, setOpenEditDetails] = React.useState(false);
    const [openViewDetails, setOpenViewDetails] = React.useState(false);
    const [EditDetails, setEditDetails] = React.useState({ status: 'Pending' });
    const [data, setData] = React.useState({
        status: { label: 'Pending', value: 'Pending' },
    });
    const [modules, setModules] = React.useState([]);
    const [filterUserOptions, setFilterUserOptions] = useState([{ label: 'All', value: 'all' }]);
    const [filterModulesOptions, setFilterModulesOptions] = useState([{ label: 'All', value: 'all' }]);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const history = useHistory();

    // ticket permissions
    const getPermissionList = () => {
        let payload = {
            active: '',
            userrole_id: JSON.parse(localStorage.getItem('loggedin_user_role')).userrole_id,
        };

        getUserRole(payload)
            .then((res) => {
                setPermissions(res?.data[0]?.permissions);
            })
            .catch(() => {});
    };
    const getModules = () => {
        getUserModules().then((res) => {
            let mod_all = res.modules;
            let prim_mod = [{ label: 'All', value: 'all' }];

            mod_all.forEach((mo) => {
                if (!mo.parent_id || mo.parent_id === '') {
                    prim_mod.push({ label: mo.module_name, value: mo.module_id });
                }
            });
            setFilterModulesOptions(prim_mod);
        });
    };
    const getUsers = () => {
        let payload = {
            user_role_id: '',
            user_name: '',
            customer_id: '',
            active: '1',
        };
        getUserList(payload).then((res) => {
            let mod_all = res.data;
            let prim_mod = [{ label: 'All', value: 'all' }];

            mod_all.forEach((mo) => {
                prim_mod.push({ value: mo.user_profile_id, label: mo.user_first_name + ' ' + mo.user_last_name });
            });
            setFilterUserOptions(prim_mod);
        });
    };

    const getTicketList = () => {
        let payload = {
            active: '',
            userrole_id: JSON.parse(localStorage.getItem('loggedin_user_role')).userrole_id,
            module_id: 'all',
            user_id: 'all',
            approved_status: 'all',
            ticket_id: obj['ticket_module'],
        };

        getTickets(payload)
            .then((res) => {
                setTicketList(res.results);
            })
            .catch(() => {});
    };

    const onOpenMoal = () => {
        setData({});
        setOpenDetails(true);
        setOpenEditDetails(false);
        setOpenViewDetails(false);
    };
    const onOpenEditModal = (data) => {
        setOpenEditDetails(true);
        setOpenDetails(true);
    };
    const onOpenViewModal = (data) => {
        setOpenViewDetails(true);
        setOpenEditDetails(false);
        setOpenDetails(true);
    };
    const onCloseMoal = () => {
        onOpenEditModal(false);
        setOpenDetails(false);
        setOpenEditDetails(false);
        setOpenViewDetails(false);

        setEditDetails({ status: 'Pending' });
        getTicketList();
    };

    const durationInSecond = (time) => {
        const now = new Date().getTime();
        const past = new Date(time).getTime();
        const duration = now - past;
        return duration / 1000;
    };
    const timeInsecond = durationInSecond(time);

    function secondsToDhms(timeInsecond) {
        timeInsecond = Number(timeInsecond);
        const d = Math.floor(timeInsecond / (3600 * 24));
        const h = Math.floor((timeInsecond % (3600 * 24)) / 3600);
        const m = Math.floor((timeInsecond % 3600) / 60);
        const s = Math.floor(timeInsecond % 60);

        const dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : '';
        const hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
        const mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ' minutes, ') : '';
        const sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
        if (dDisplay) {
            return dDisplay;
        } else if (hDisplay) {
            return hDisplay;
        } else if (mDisplay) {
            return mDisplay;
        } else if (sDisplay) {
            return sDisplay;
        }
    }
    const module_id =
        type === 'ticket'
            ? `/ticketing-system/${obj['ticket_module']}`
            : type === 'Booking'
            ? `/apps/bookingdetails/${obj['booking_module']}`
            : type === 'customer'
            ? obj['customer_module']
            : type === 'Activity'
            ? `/apps/Activity/${obj['activity_module']}`
            : '#';

    const toggleModal = () => {
        setOpenModal(!openModal);

        if (openModal) {
            getrateListList();
        }
    };
    const getrateListList = () => {
        let data = {
            sub_solution_id: '',
            location_id: '',
            booking_id: '',
            customer_id: '',
            activity_id: obj['activity_module'],

            activity_type_name: '',
            user_id: localStorage.getItem('loggedin_user_role_id') == 1 ? '' : localStorage.getItem('loggedin_user_id'),
            // if user role id is 1 then user is Admin and admin can view all list
            user_role_id: localStorage.getItem('loggedin_user_role_id'),
        };

        getRateList(data)
            .then((response) => {
                if (response.response_code == 200) {
                    setRecords(response.data);
                    setCustomerLoading(false);
                    setCustomerError('');
                    // console.log(response.data.data, 'list_activity');
                }
            })
            .catch((error) => {
                setCustomerError(error);
                setCustomerLoading(false);
            });
    };
    const openModalWithSize = (size) => {
        toggleModal();
    };

    function editBooking(content) {
        console.log(content, 'bjghf');
        openModalWithSize();
        let userdetails = [];
        let terrDetails = [];
        if (content.assignee_user_details) {
            content.assignee_user_details.forEach((element) => {
                userdetails.push({
                    label: element.user_first_name + ' ' + element.user_last_name,
                    value: element.user_id,
                });
            });
        }
        if (content.territories) {
            content.territories.forEach((element) => {
                terrDetails.push({
                    label: element.territory_name,
                    value: element.territory_id,
                });
            });
        }
        content.assignedTolist = userdetails;
        content.territory = terrDetails;
    }

    useEffect(() => {
        if (is_Read) {
            setOpen(id);
        }
        if (type === 'Activity') {
            getrateListList();
        }
        if (type === 'ticket') {
            getPermissionList();
            getTicketList();
        }
    }, []);

    // useEffect(() => {
    //     document.body.addEventListener('click', () => {
    //         setIsOpenModal(false);
    //         setOpenModal(false)
    //     })
    // })

    return (
        <SingleNoficationDiv>
            {!is_Read ? (
                <div className="__is_new"></div>
            ) : (
                <div className="__is_Read">
                    <TiTick size={20} />
                    <Badge
                        style={{ cursor: 'pointer    ' }}
                        onClick={() => {
                            is_Read && readNotification({ read: 'false' }, id);
                            setIs_Read(false);
                            // setOpen(id);
                        }}>
                        Unread
                    </Badge>
                </div>
            )}
            <div className="__delete">
                {!isDeleted ? (
                    <MdDelete
                        onClick={() => {
                            !isDeleted && readNotification({ archive: 'true' }, id);
                        }}
                        size={20}
                    />
                ) : (
                    <BiUndo
                        onClick={() => {
                            isDeleted && readNotification({ archive: 'false' }, id);
                        }}
                        size={20}
                    />
                )}
                {/* {isDeleted && <MdDelete
                    onClick={() => {
                        deleteNotification({}, id);
                    }}
                    size={20}
                />} */}
            </div>

            <div className="__img">
                <img src={avatar || userAvatar} alt="" />
            </div>
            <div className="__notifications_details">
                <div className="__des">
                    <span
                        onClick={() => {
                            !is_Read && readNotification({ read: 'true' }, id);
                            setIs_Read(true);
                            setOpen(id);
                        }}
                        style={{ color: is_Read ? 'gray' : 'black' }}
                        className="__title">
                        {title}
                    </span>
                    <div className="__notification_time">
                        <span>{secondsToDhms(timeInsecond)} ago</span> <div className="__dot"></div>{' '}
                        <span>{project}</span>
                    </div>
                </div>
                <div className="__actions">
                    <Badge
                        color="info"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (type === 'Activity') {
                                records.length > 0 && editBooking(records?.[0]);
                            }
                            if (type === 'ticket') {
                                onOpenViewModal(true);
                                setEditDetails(ticketlist?.[0]);
                            }
                            if (type === 'Booking') {
                                // setIsOpenModal(true)
                                history.push(`/apps/bookingdetails/${obj['booking_module']}`);
                            }
                        }}>
                        {type}
                    </Badge>
                    {type === 'Activity' && (
                        <>
                            <span
                                style={{
                                    color:
                                        severity_type_name === 'High'
                                            ? 'tomato'
                                            : severity_type_name === 'Medium'
                                            ? 'orange'
                                            : severity_type_name === 'Low'
                                            ? 'gray'
                                            : '',
                                }}>
                                {severity_type_name}
                            </span>
                            <span style={{ color: 'skyblue' }}>{due_date}</span>
                        </>
                    )}
                    {type === 'Booking' && (
                        <>
                            <span style={{ color: 'green' }}>{vessel_name}</span>
                            <span style={{ color: 'gray' }}>
                                {moment(schedule_survey_date)?.utc().format('YYYY-MM-DD')}
                            </span>
                        </>
                    )}
                </div>
            </div>
            <Modal isOpen={openModal} size="lg">
                <ModalHeader>{type}</ModalHeader>
                <ModalBody>
                    <Addactivity
                        toggleModal={toggleModal}
                        getrateListList={getrateListList}
                        // listAll={listAll}
                        content={records?.length > 0 ? records?.[0] : {}}
                        // booking_id={booking_id}
                    />
                </ModalBody>
            </Modal>
            <TicketDetails
                permissions={permissions}
                show={openDetails}
                onHide={() => onCloseMoal()}
                // saveData={saveData}
                edit={openEditDetails}
                view={openViewDetails}
                editData={EditDetails ? EditDetails : { status: 'Pending' }}
                modules={modules}
            />

            <Modal isOpen={isOpenModal} size="lg" onClick={(e) => e.stopPropagation()}>
                <ModalBody>
                    <iframe
                        src={`${window.location.origin}/apps/bookingdetails/${obj['booking_module']}?notify`}
                        style={{ width: '100%', height: '500px', border: 'none' }}
                        frameBorder={0}></iframe>
                </ModalBody>
                <ModalFooter style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(false)}>
                    Close
                </ModalFooter>
            </Modal>
        </SingleNoficationDiv>
    );
};
export default SingleNotification;
