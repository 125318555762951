import React from 'react';
import Chart from 'react-apexcharts';
import { FaTimes } from 'react-icons/fa';

const TreeMap = (props) => {
    const {
        viewCard = true,
        setStatistics,
        setWorklogMissingData,
        setSelectedSolution,
        setSelectedSubSolution,
        setSuperIntendentId,
    } = props;

    const options = {
        legend: {
            show: false,
        },
        treemap: {
            tile: 'squarify', // Change the tiling algorithm to squarify
            squarify: {
                ratio: 4.5, // Increase the aspect ratio to make small boxes larger
                padding: 2, // Increase the padding to make the boxes more spaced out
            },
        },
        chart: {
            height: 200,
            type: 'treemap',
            toolbar: false,
            zoom: {
                enabled: true,
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setSelectedSubSolution(undefined);
                    setSuperIntendentId('');
                    setSelectedSolution(config.w.config.customData[config.dataPointIndex]);

                    setWorklogMissingData([]);
                    setStatistics([]);
                },
            },
        },
        // title: {
        //     text: 'Total Business By Revenue',
        //     // align: 'center',
        // },
        colors: [
            '#3B93A5',
            '#F7B844',
            '#ADD8C7',
            '#5C3C65',
            '#eb847c',
            '#C12666',
            '#D43F97',
            '#1E5D8C',
            '#421243',
            '#7F94B0',
            '#EF6537',
            '#C0ADDB',
        ],
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false,
            },
        },

        // chart: {
        //     type: 'treemap',
        //     stacked: props.stacked ?? false,
        //     toolbar: {
        //         show: false,
        //     },
        // },
        // colors: props.colors ?? ['#f00', '#0f0'],

        dataLabels: {
            // enabled: true,
            // style: {
            //     fontSize: '140px',
            // },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: (props.data?.xAxis || []) ?? [],
            axisBorder: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 1,
            },
            borderColor: '#f3f4f7',
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
    };

    const data =
        (props?.data &&
            props.data?.solutions &&
            props.data?.solutions?.map((solution) => {
                return { x: solution?.solutions_name || '', y: solution?.total_count || '' };
            })) ||
        [];

    return (
        <>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
                    <h6 style={{ margin: '0' }}>{props?.title}</h6>
                    {props?.selectedSolution && (
                        <span
                            onClick={() => setSelectedSolution(undefined)}
                            style={{
                                color: '#333',
                                borderRadius: '8px',
                                fontSize: '0.65rem',
                                padding: '0.2rem',
                                background: 'rgba(78, 130, 145, 0.3)',
                                cursor: 'pointer',
                                margin: '0 0.5rem',
                            }}>
                            {props.selectedSolution?.solutions_name}
                            <FaTimes />
                        </span>
                    )}
                </div>

                <Chart
                    options={{ ...options, customData: props?.data?.solutions }}
                    series={[{ data }]}
                    type="treemap"
                    className="apex-charts"
                    height={200}
                />
            </div>
        </>
    );
};

export default TreeMap;
