import { useState } from "react";
import axios from "axios";
import config from "../../../config";

const useCustomer = (props) => {

    const [customerList, setCustomerList] = useState();

    const fetch = () => {
        let payload = {
            customer_id: "",
            customer_name: "",
            "active": "",
            paginate: false,
        };
        axios.post(`${config.apiRoot}operation/list_customer`, payload)
            .then((res) => {
                setCustomerList(res.data.data?.data);
            })
            .catch((e) => {

            })
    }

    return {
        fetch,
        customerList
    };
}

export default useCustomer;