import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Row, CustomInput } from 'reactstrap';
import * as Yup from 'yup';
import SelectField from '../../../../../components/selectField/selectField';
import TextField from '../../../../../components/textFeild/textFeild';
import config from '../../../../../config';

const validationSchema = Yup.object().shape({
    currency_type_code: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    currency_type_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required'),
    banking_details: Yup.string().required('This field is required'),
    // company_id: Yup.string().required('This field is required'),
    notes: Yup.string().nullable(),
});
const CurrencyTypeForm = ({
    actionType = 'Create',
    initialValues,
    modelClose = () => {},
    reload = () => {},
    customer = [],
    company = [],
}) => {
    let _initialValues =
        actionType === 'Create'
            ? {
                  currency_type_code: '',
                  currency_type_name: '',
                  customer_id: '',
                  notes: '',
                  banking_details: '',
                  company_id: '1',
                  active: '1',
                  conversion_rate: '1',
                  base_rate: 0,
                  created_by_user: localStorage.getItem('loggedin_user_id'),
              }
            : initialValues;

    const formik = useFormik({
        initialValues: _initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let req;
            values['user_id'] = localStorage.getItem('loggedin_user_id');
            switch (actionType) {
                case 'Create':
                    req = axios.post(`${config.apiRoot}configuration/create_currency_type`, values);
                    break;
                case 'Edit':
                    req = axios.post(`${config.apiRoot}configuration/update_currency_type`, values);
            }
            req.then((res) => {
                modelClose();
                reload();
                toast.success(res.data.message);
            }).catch((e) => {
                toast.error(e.response.data.message);
            });
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={12}>
                        <TextField
                            label="Currency Name"
                            required={true}
                            name="currency_type_name"
                            value={formik.values.currency_type_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.currency_type_name &&
                                formik.touched.currency_type_name &&
                                formik.errors.currency_type_name
                            }
                        />
                    </Col>
                    <Col lg={12}>
                        <TextField
                            label="Currency Code"
                            required={true}
                            name="currency_type_code"
                            value={formik.values.currency_type_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.currency_type_code &&
                                formik.touched.currency_type_code &&
                                formik.errors.currency_type_code
                            }
                        />
                    </Col>
                    {/* <Col lg={12}>
                        <SelectField
                            label={"Customer"}
                            required={true}
                            name={"customer_id"}
                            firstLabel={"Select Customer"}
                            list={customer}
                            value={formik.values.customer_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.customer_id && formik.touched.customer_id && formik.errors.customer_id}
                        />
                    </Col>
                    <Col lg={12}>
                        <SelectField
                            label={"Company"}
                            required={true}
                            name={"company_id"}
                            firstLabel={"Select Company"}
                            list={company}
                            value={formik.values.company_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.company_id && formik.touched.company_id && formik.errors.company_id}
                        />
                    </Col> */}
                    <Col lg={12}>
                        <TextField
                            label="Notes"
                            type={'textarea'}
                            name="notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Col>
                    <Col lg={12}>
                        <SelectField
                            label={'Status'}
                            //required={true}
                            name={'active'}
                            firstLabel={'Select Status'}
                            list={[
                                { name: 'Active', value: '1' },
                                { name: 'InActive', value: '0' },
                            ]}
                            value={formik.values.active}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.active && formik.touched.active && formik.errors.active}
                        />
                    </Col>
                    <Col lg={12}>
                        <TextField
                            label="Banking Details"
                            type={'textarea'}
                            style={{ height: '200px' }}
                            required={true}
                            name="banking_details"
                            value={formik.values.banking_details}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.banking_details &&
                                formik.touched.banking_details &&
                                formik.errors.banking_details
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} xl={4} sm={4}>
                        <TextField
                            label="Conversion Rate"
                            required={true}
                            name="conversion_rate"
                            value={formik.values.conversion_rate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.errors.conversion_rate &&
                                formik.touched.conversion_rate &&
                                formik.errors.conversion_rate
                            }
                        />
                    </Col>
                    {/* <Col lg={6} xl={4} sm={4}>
                        <div className="form-group" style={{ marginLeft: '20px', marginTop: '50px' }}>
                            <CustomInput
                                type="checkbox"
                                onChange={(v) => {
                                    formik.setFieldValue('base_rate', v.currentTarget.checked == true ? 1 : 0);
                                }}
                                onBlur={formik.handleBlur}
                                name="base_rate"
                                id="base_rate"
                                defaultValue="1"
                                defaultChecked={formik.values.base_rate == 1 ? true : false}
                                className="pl-1"
                                label="Is Base Rate"
                            />
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    <Col lg={12}>
                        <Button type={'submit'}>Submit</Button>
                    </Col>
                </Row>
            </form>
        </div>
    );
};
export default CurrencyTypeForm;
