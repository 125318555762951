import React from 'react';
import TvDashboard from './TvDashboard';

const TVShow = () => {
    return (
        <div
            style={{
                height: '100%',
                overflow: 'hidden',
            }}>
            <TvDashboard />
        </div>
    );
};

export default TVShow;
