import React from 'react';
import { Input } from 'reactstrap';
import './selectField.css';

const SelectField = ({ label, required, error, firstLabel = false, list = [], ...rest }) => {
    return (
        <div>
            {Boolean(label) && <p className="mb-1 mt-3 font-weight-bold">{`${label} ${required ? '*' : ''}`}</p>}
            <div className="form-group">
                <Input className="form-control" placeholder={label} type="select" {...rest}>
                    <option>{firstLabel}</option>
                    {list.map((value, index, array) => (
                        <option key={index} {...value} disabled={value?.isDisabled || false}>
                            {value.name}
                        </option>
                    ))}
                </Input>
                {error && <div className="errorMessage">{error}</div>}
            </div>
        </div>
    );
};

export default SelectField;
