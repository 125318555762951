
import * as actionTypes from "./constants";

const getNotificationsReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.FetchNotifications_REQUEST:
            return {
                loading: true,
            };

        case actionTypes.FetchNotificationsSuccess:
            return {
                loading: false,
                notificationList: action.payload,
            };

        case actionTypes.FetchNotificationsFailure:
            return {
                loading: false,
                error: action.payload,
            };
        case actionTypes.SOCKETRESPONSE:
            return {
                loading: false,
                eventRes: action.payload,
            };
        case actionTypes.ISLOADING:
            return {
                loading: false,
                loadingCertificate: action.payload,
            };
        case actionTypes.ClearNotifications:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export default getNotificationsReducer;