import React from 'react';
import { Spinner, Card, CardBody } from 'reactstrap';
import './loader.css';

const LoaderBeta = ({ fromModal = false, fullheight = false, height = '50vh' }) => {
    return (
        <div className={`${fromModal ? '' : 'card'} ${fullheight ? 'h-100' : ''}`}>
            <CardBody className="loaderContainer" style={{ height }}>
                <Spinner>Loading...</Spinner>
            </CardBody>
        </div>
    );
};

export default LoaderBeta;
