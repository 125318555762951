import apis from '../apis/api.actions';
import { parseQueryParams } from '../utils/formValidate';

export const getUserRole = (data) => apis.createData(`system/list_user_role`, data).then((res) => res);

export const listLocation = (data) => apis.createData(`configuration/location_list`, data).then((res) => res);

export const getCountries = (data) => apis.createData(`configuration/country_list`).then((res) => res);
export const getCustomers = (data) => apis.createData(`operation/list_customer`, data).then((res) => res);

export const getCurrencyTypes = (data) => apis.createData(`configuration/currency_type_list`, data).then((res) => res);
export const getInvoiceTypes = (data) => apis.createData(`configuration/invoice_type_list`, data).then((res) => res);

export const getLocation = (data) => apis.createData(`configuration/location_list`, data).then((res) => res);
export const getVesselParticulars = (data) =>
    apis.createData(`operation/list_vessel_particulars`, data).then((res) => res);
export const createBooking = (data) => apis.createData(`operation/create_booking`, data).then((res) => res);
export const updateBooking = (data) => apis.createData(`operation/update_booking`, data).then((res) => res);
export const getBookingDetails = (data) => apis.createData(`operation/booking_detail`, data).then((res) => res);
export const getBookingStatusList = (data) =>
    apis.createData(`configuration/bookings_status_list`, data).then((res) => res);
export const updateBookingStatus = (data) =>
    apis.createData(`operation/update_bookings_status`, data).then((res) => res);
export const deleteBooking = (data) => apis.createData(`operation/delete_booking`, data).then((res) => res);

export const createInvoice = (data) => apis.createData(`finance/generate_invoice`, data).then((res) => res);
export const createCustomer = (data) => apis.createData(`operation/create_customer`, data).then((res) => res);
export const getBookingList = (data) => apis.createData(`operation/list_booking`, data).then((res) => res);
export const getBookingLocationList = (data) => apis.createData(`configuration/location_list`, data).then((res) => res);

export const getBookingSuperintentList = (data) =>
    apis.createData(`operation/list_superintent`, data).then((res) => res);

export const listCustomerTarriffDetails = (data) =>
    apis.createData(`finance/list_customer_tariff_detail`, data).then((res) => res);
export const createTarrifLines = (data) => apis.createData(`finance/create_tariff_lines`, data).then((res) => res);
export const updateTarrifLines = (data) =>
    apis.createData(`finance/update_customer_tariff_lines`, data).then((res) => res);
export const deleteTarrifLines = (data) =>
    apis.createData(`finance/delete_customer_tariff_lines`, data).then((res) => res);
export const listTarrifRateTypes = (data) =>
    apis.createData(`configuration/tarrif_rate_type_list`, data).then((res) => res);
export const listSubSolutions = (data) => apis.createData(`configuration/sub_solutions_list`, data).then((res) => res);
export const listInvoice = (data) => apis.getDatas(`finance/invoices${parseQueryParams(data)}`).then((res) => res);
export const transferInvoice = (data) => apis.createData(`finance/invoice_transfer`, data).then((res) => res);
export const listCustomerTarriff = (data) => apis.createData(`finance/list_customer_tariff`, data).then((res) => res);
export const updateCustomerTarriff = (data) =>
    apis.createData(`finance/update_customer_tariff`, data).then((res) => res);
export const createCustomerTarriff = (data) =>
    apis.createData(`finance/create_customer_tariff`, data).then((res) => res);
export const deleteCustomerTarriff = (data) =>
    apis.createData(`finance/delete_customer_tariff`, data).then((res) => res);
export const getTerritory = (data) => apis.createData(`configuration/territory_list`, data).then((res) => res);
export const getBookingTableList = (data) => apis.createData(`operation/list_booking`, data).then((res) => res);

export const getSuppliers = (data) => apis.createData(`management/list_supplier`, data).then((res) => res);

export const getSupplierProducts = () => apis.createData(`management/list_supplier_products`).then((res) => res);
export const createSupplier = (data) => apis.createData(`management/create_supplier`, data).then((res) => res);
export const updateSupplier = (data) => apis.createData(`management/update_supplier`, data).then((res) => res);
export const deleteSupplier = (data) => apis.createData(`management/delete_supplier`, data).then((res) => res);
export const getVesselType = (data) => apis.createData(`configuration/vessel_type_list`, data).then((res) => res);

export const listCustomer = (data) => apis.createData(`operation/list_customer`, data).then((res) => res);
export const listCustomerCommunication = (data) =>
    apis.createData(`operation/list_customer_communication`, data).then((res) => res);
export const listCurrencyTypes = (data) => apis.createData(`configuration/currency_type_list`, data).then((res) => res);
export const listInvoiceTypes = (data) => apis.createData(`configuration/invoice_type_list`, data).then((res) => res);
export const listCountries = (data) => apis.createData(`configuration/country_list`, data).then((res) => res);
export const listTerritories = (data) => apis.createData(`configuration/territory_list`, data).then((res) => res);
export const listCommunicationTypes = (data) =>
    apis.createData(`configuration/communication_type_list`, data).then((res) => res);

export const checkNotification = () => apis.getDatas(`system/count`).then(res => res);
