import React, { useRef, useState } from 'react';
import { Formik, FieldArray, ErrorMessage, Field } from 'formik';
import TextField from '../../../../../../components/textFeild/textFeild';
import { Row, Col, Label } from 'reactstrap';
import * as Yup from 'yup';
import Button from '../../../../../../components/button/button';
import * as FeatherIcon from 'react-feather';
import axios from 'axios';
import config from '../../../../../../config';
import moment from 'moment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { COMMON_API_ERROR } from '../../../../../../constants';
import ImageModal from './cropper/ImageModal';
import {
    update_certifications,
    delete_certifications,
    get_certifications,
    delete_single_certification_file,
    reset_qr_code,
} from '../../../../../../pages/sections/api.functions';
import { countries } from 'constants/countryList';
import Select from 'components/select/select';
import { connect } from 'react-redux';
import { isLoadingCertificate } from 'redux/notification/actions';

const DetailInfo = ({
    onSubmit,
    statusList,
    companyList,
    editDetails,
    type,
    onEditSubmit,
    currecyList,
    handleAction,
    data,
    initialValues,
    vesselTypes,
    actionType,
    certificationItems,
    certificationValues,
    onFileRemove,
    reload,
    modelClose,
    selfRole,
    isLoadingCertificate,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [newAction, setNewAction] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingReset, setIsSubmittingReset] = useState(false);
    const [removeCertificate, setRemoveCertificate] = useState(false);
    const [certificationData, setCertificationData] = useState([]);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    const deleteCertificate = (certificateId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete this certification?',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                delete_certifications(certificateId)
                    .then((response) => {
                        if (response.response_code == '200') {
                            setRemoveCertificate(true);
                            toast.success('Certification Deleted Successfully');
                            // window.location.reload();
                            // this.fetchComments();
                            // fetchCertifications();
                            // setRemoveCertificate(false);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        // setRemoveCertificate(false);
                    });
            }
        });
    };

    const regenerate_status = () => {
        reset_qr_code({ status: false, user_id: data?.user_id })
            .then((response) => {
                toast.success('Successfully Reset the qr');
            })
            .catch((error) => {
                toast.error('Erroring reseting the qr code');
            });
    };

    const updateCertifications = (value) => {
        setIsUpdating(true);
        update_certifications(value)
            .then((response) => {
                if (response?.response_code == '200') {
                    isLoadingCertificate(true);
                    setIsUpdating(false);
                    modelClose();
                    reload();
                }
                isLoadingCertificate(false);
                fetchCertifications();
            })
            .catch(function (error) {
                toast.error(error.response?.data?.message ?? COMMON_API_ERROR);
                setIsUpdating(false);
            });
    };

    const onChangeHandler = (file) => {
        console.log(file);
    };
    const fetchCertifications = () => {
        let payload = { user: data?.user_profile_id };
        get_certifications(payload)
            .then((res) => {
                setCertificationData(res.results);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const validationSchema = Yup.object().shape({
        // imageFile:
        //     actionType === 'Edit'
        //         ? Yup.mixed().label('Photograph')
        //         : Yup.mixed()
        //               .label('Photograph')
        //               .required()
        //               .test('no-empty', 'You must add an image.', async function validate(item) {
        //                   if (item?.length === 0) return false;
        //                   const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        //                   if (!supportedFormats.includes(item && item[0]?.type))
        //                       return this.createError({
        //                           message: 'Wrong image format. Upload jpeg/jgp/png',
        //                       });
        //                   if (Number(item[0]?.size) > 512 * 1024)
        //                       return this.createError({
        //                           message: 'Wrong file size. Expecting maximum file size of 512 KB.',
        //                       });
        //                   const getImageWidthAndHeight = async (provideFile) => {
        //                       const imgDimensions = { width: null, height: null };
        //                       return new Promise((resolve) => {
        //                           const reader = new FileReader();
        //                           reader.readAsDataURL(provideFile);
        //                           reader.onload = function () {
        //                               const img = new Image();
        //                               if (typeof reader.result === 'string') img.src = reader.result;
        //                               img.onload = function (event) {
        //                                   imgDimensions.width = img.width;
        //                                   imgDimensions.height = img.height;
        //                                   resolve(imgDimensions);
        //                               };
        //                           };
        //                       });
        //                   };
        //                   const { width, height } = await getImageWidthAndHeight(item && item[0]);
        //                   if (width === 482 && height === 820) return true;
        //                   return this.createError({
        //                       message: 'Wrong file width and height. Expecting 482 * 820 pixels',
        //                   });
        //               }),
    });

    return (
        <Formik
            initialValues={data}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                const targetButton = document.getElementById('target-button');
                if (targetButton) {
                    targetButton.click();
                }
                // handleAction(values, 1);
                setIsSubmitting(true);

                let req;
                let certificationArr = [];
                values['user_id'] = localStorage.getItem('loggedin_user_id');

                const finalValues = new FormData();
                finalValues.append('company_id', values.company_id ?? 1);
                finalValues.append('customer_id', values.customer_id);
                finalValues.append('user_first_name', values.user_first_name);
                finalValues.append('user_last_name', values.user_last_name);
                if (values?.user_profile_id) {
                    finalValues.append('user_profile_id', values.user_profile_id);
                }
                finalValues.append('user_role_id', values.user_role_id?.value?.toString());
                finalValues.append('territory', JSON.stringify(values.territory));
                finalValues.append('user_email', values.user_email);
                finalValues.append('active', values.active?.value);
                finalValues.append('created_by_user', values.created_by_user);
                finalValues.append('is_superintendent', values.is_superintendent);
                finalValues.append('is_bivac', values.is_bivac);
                finalValues.append('password', values.password);
                finalValues.append('confirm_password', values.confirm_password);
                if (values.imageFile?.length > 0) {
                    finalValues.append('photograph', values.imageFile[0]);
                } else {
                    finalValues.append('existing', values.fileBanner === '' ? false : true);
                }
                finalValues.append('dob', moment(values.date_of_birth).format('YYYY-MM-DD'));
                finalValues.append('date_of_joining', moment(values.date_of_joining).format('YYYY-MM-DD'));
                // values?.citizenship?.code &&
                finalValues.append('citizenship', values?.citizenship?.code);
                finalValues.append('mobile_number', values.mobile_number === '' ? null : values.mobile_number);
                finalValues.append('address', values.address === '' ? null : values.address);
                finalValues.append('msic_number', values.msic_number === '' ? null : values.msic_number);
                finalValues.append('designation', values.designation === '' ? null : values.designation);
                finalValues.append('country_priority', Number(values.country_priority));
                finalValues.append('headquarters_priority', Number(values.headquarters_priority));
                finalValues.append('organizations_priority', Number(values.organizations_priority));
                finalValues.append('web', true);
                if (actionType === 'Edit' && values.certifications) {
                    Object.entries(values.certifications).map((item) => {
                        item?.[1]?.id &&
                            certificationArr.push({
                                id: item?.[1]?.id,
                                name: item?.[1]?.certification_name,
                            });
                    });
                    finalValues.append('certifications', JSON.stringify(certificationArr));
                }
                if (actionType === 'Create' && values.certifications) {
                    Object.entries(values.certifications).map((item) => {
                        Object.entries(item[1]).map((itemm) => {
                            if (
                                itemm[1] == null ||
                                itemm[1] == 0 ||
                                itemm[0].endsWith('_url') ||
                                (typeof itemm[1] === 'string' && itemm[1].startsWith('/media/'))
                            ) {
                            } else if (`${itemm[0]}` === 'certification_name') {
                                finalValues.append(`${itemm[0]}`, itemm[1]);
                            } else {
                                finalValues.append(`${itemm[0]}`, itemm[1]?.['0']);
                            }
                        });
                    });
                }

                switch (actionType) {
                    case 'Create':
                        req = axios.post(`${config.apiRoot}system/create_user`, finalValues);
                        break;
                    case 'Edit':
                        req = axios.post(`${config.apiRoot}system/update_user`, finalValues);
                }
                req.then((res) => {
                    if (res.status == '200') {
                        if (!values?.images?.[0]?.certification_name.length) {
                            isLoadingCertificate(true);
                            reload();
                            toast.success(res.data.message);
                            setIsSubmitting(false);
                            modelClose();
                        }
                    }
                }).catch((e) => {
                    toast.error(e?.response?.data?.message || COMMON_API_ERROR);
                    setIsSubmitting(false);
                });
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) => {
                if (certificationData.length) {
                    data.certifications = certificationData;
                }
                const handleFileChange = (event, index) => {
                    const file = event.target.files[0];
                    setFieldValue(`images[${index}].file`, file);
                };
                return (
                    <>
                        <ImageModal
                            modalIsOpen={modalIsOpen}
                            closeModal={() => {
                                setModalIsOpen((prevState) => !prevState);
                            }}
                            image={values.imageFile}
                            onCropImage={
                                (croppedImg) => setFieldValue('imageFile', Object.values({ croppedImg }))
                                // console.log(
                                //     croppedImg,
                                //     { croppedImgName: croppedImg.name },
                                //     Object.values({ croppedImg })
                                // )
                            }
                            ratio={height / width <= 0.5 ? true : false}
                        />
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col lg={12}>
                                    <Label>Photograph</Label>
                                    <div className="form-control-file">
                                        <p className="mb-1 mt-3 font-weight-bold d-flex align-items-center">
                                            <label htmlFor="filechooser">
                                                <FeatherIcon.Paperclip style={{ height: 14 }} />
                                                <span> Attach Document (Max 15MB) </span>
                                            </label>
                                        </p>
                                        <input
                                            label="Attach Document"
                                            name="customer_name"
                                            type="file"
                                            multiple
                                            id="filechooser"
                                            hidden
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                setFieldValue('imageFile', e.target.files);
                                                setFieldValue('fileBanner', '');
                                                setModalIsOpen(true);
                                            }}
                                        />
                                        {values.imageFile !== null &&
                                            values.imageFile !== undefined &&
                                            values.imageFile !== '' && (
                                                <>
                                                    {Object.keys(values.imageFile).map((key) => {
                                                        return (
                                                            <div className="p-2 bg-light">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>{values.imageFile[key].name}</div>
                                                                    <div
                                                                        className="text-danger cursor-pointer"
                                                                        onClick={(e) =>
                                                                            setFieldValue(
                                                                                'imageFile',
                                                                                onFileRemove(values.imageFile, key)
                                                                            )
                                                                        }>
                                                                        <i className="uil uil-trash-alt mr-1"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}
                                    </div>
                                    {values.fileBanner && (
                                        <div className="p-2 bg-light">
                                            <div className="d-flex justify-content-between">
                                                <div
                                                    className="cursor-pointer text-primary"
                                                    onClick={(e) => window.open(values.fileBanner, '_blank')}>
                                                    {values?.fileBanner?.split('/')?.pop()}
                                                </div>
                                                <div
                                                    className="text-danger cursor-pointer"
                                                    // onClick={(e) => this.handleDeleteAttachment(file.id)}
                                                    onClick={(e) => {
                                                        setFieldValue('imageFile', []);
                                                        setFieldValue('fileBanner', '');
                                                    }}>
                                                    <i className="uil uil-trash-alt mr-1"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {errors.imageFile && <div className="errorMessage">{errors.imageFile}</div>}
                                    {}
                                </Col>
                                <Col lg={6}>
                                    <TextField
                                        label="Date of Birth"
                                        // required={true}
                                        name="date_of_birth"
                                        type="date"
                                        value={values.date_of_birth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.date_of_birth && touched.date_of_birth && errors.date_of_birth}
                                    />
                                </Col>
                                {console.log('vakes >>>???? ', values)}
                                <Col lg={6}>
                                    <TextField
                                        label="Date of Joining"
                                        // required={true}
                                        name="date_of_joining"
                                        type="date"
                                        value={values.date_of_joining}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={
                                            errors.date_of_joining && touched.date_of_joining && errors.date_of_joining
                                        }
                                    />
                                </Col>

                                {/* <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Citizenship</p>
                                    <Select
                                        options={countries}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={(v) => {
                                            setFieldValue('citizenship', v);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.code}
                                        value={values.citizenship}
                                    />
                                    {errors.citizenship && touched.citizenship && errors.citizenship && (
                                        <div className="errorMessage">{errors.citizenship}</div>
                                    )}
                                </Col> */}
                                {/* <Col lg={6}>
                                    <TextField
                                        label="Citizenship"
                                        // required={true}
                                        name="citizenship"
                                        type="number"
                                        value={values.citizenship}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.citizenship && touched.citizenship && errors.citizenship}
                                    />
                                </Col> */}
                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Mobile Number</p>

                                    <div style={{ display: 'flex', width: '100%', gap: '3px' }}>
                                        <div style={{ width: '30%' }}>
                                            <Select
                                                options={countries}
                                                isClearable={false}
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                onChange={(v) => {
                                                    setFieldValue('citizenship', v);
                                                }}
                                                getOptionLabel={(option) => option.dial_code}
                                                getOptionValue={(option) => option.code}
                                                value={values.citizenship}
                                            />
                                        </div>
                                        <div style={{ flex: '1' }}>
                                            <TextField
                                                label=""
                                                // required={true}
                                                name="mobile_number"
                                                type="number"
                                                value={values.mobile_number}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={
                                                    errors.mobile_number &&
                                                    touched.mobile_number &&
                                                    errors.mobile_number
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <TextField
                                        label="Address"
                                        // required={true}
                                        name="address"
                                        type="text"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.address && touched.address && errors.address}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <TextField
                                        label="MSIC Number"
                                        // required={true}
                                        name="msic_number"
                                        type="text"
                                        value={values.msic_number}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.msic_number && touched.msic_number && errors.msic_number}
                                    />
                                </Col>
                                <Col lg={6}>
                                    <TextField
                                        label="Designation"
                                        // required={true}
                                        name="designation"
                                        value={values.designation}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.designation && touched.designation && errors.designation}
                                    />
                                </Col>
                                {actionType === 'Edit' && (
                                    <Col lg={12}>
                                        <Col lg={12}>
                                            <Label>Certifications</Label>
                                        </Col>
                                        <FieldArray name="images">
                                            {({ push, remove }) => (
                                                <>
                                                    {values.images.map((image, index) => {
                                                        return (
                                                            <Row className="mb-2" key={index}>
                                                                <Col lg={6}>
                                                                    <TextField
                                                                        name={`images[${index}].certification_name`}
                                                                        required={true}
                                                                        type="text"
                                                                        placeholder="Name"
                                                                        value={values.images[index]?.certification_name}
                                                                        onChange={(e) => {
                                                                            const { value } = e.target;
                                                                            setFieldValue(
                                                                                `images[${index}].certification_name`,
                                                                                value
                                                                            );
                                                                        }}
                                                                    />
                                                                </Col>

                                                                <Col lg={5}>
                                                                    <div className="form-control-file">
                                                                        <p className="mb-1 mt-3 font-weight-bold d-flex align-items-center">
                                                                            <label
                                                                                htmlFor={`images[${index}].attachment`}>
                                                                                <FeatherIcon.Paperclip
                                                                                    style={{ height: 14 }}
                                                                                />
                                                                                <span> Attach Document</span>
                                                                            </label>
                                                                        </p>
                                                                        <input
                                                                            name={`images[${index}].attachment`}
                                                                            id={`images[${index}].attachment`}
                                                                            type="file"
                                                                            hidden
                                                                            onChange={(event) => {
                                                                                const file =
                                                                                    event.currentTarget.files[0];
                                                                                setFieldValue(
                                                                                    `images[${index}].attachment`,
                                                                                    file
                                                                                );
                                                                            }}
                                                                        />
                                                                        {values.images[index]?.attachment && (
                                                                            <div className="mt-2">
                                                                                <p className="mb-1 mt-3 text-danger cursor-pointer">
                                                                                    <span
                                                                                        className="cursor-pointer text-primary"
                                                                                        onClick={(e) =>
                                                                                            window.open(
                                                                                                values.images[index]
                                                                                                    ?.attachment,
                                                                                                '_blank'
                                                                                            )
                                                                                        }>
                                                                                        {image.id &&
                                                                                        !image.attachment?.name
                                                                                            ? values.images[
                                                                                                  index
                                                                                              ]?.attachment
                                                                                                  ?.split('/')
                                                                                                  ?.pop()
                                                                                            : values.images[index]
                                                                                                  ?.attachment.name}
                                                                                    </span>
                                                                                    <span className="ml-2">
                                                                                        <i
                                                                                            className="uil uil-trash-alt cursor-pointer"
                                                                                            onClick={async () => {
                                                                                                if (image?.id) {
                                                                                                    try {
                                                                                                        const res =
                                                                                                            await delete_single_certification_file(
                                                                                                                image?.id
                                                                                                            );
                                                                                                        setFieldValue(
                                                                                                            `images[${index}].attachment`,
                                                                                                            null
                                                                                                        );
                                                                                                    } catch (error) {
                                                                                                        toast.error(
                                                                                                            'Error in delete file'
                                                                                                        );
                                                                                                    }
                                                                                                } else {
                                                                                                    setFieldValue(
                                                                                                        `images[${index}].attachment`,
                                                                                                        null
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                                <ErrorMessage
                                                                    name={`images[${index}].certification_name`}
                                                                    component="div"
                                                                />
                                                                <i
                                                                    className="uil uil-trash-alt mr-1 cursor-pointer"
                                                                    onClick={() => {
                                                                        if (image?.id) {
                                                                            Swal.fire({
                                                                                title: 'Are you sure?',
                                                                                text: 'Are you sure that you want to delete this certification?',
                                                                                icon: 'warning',

                                                                                showCancelButton: true,
                                                                                confirmButtonColor: '#3085d6',
                                                                                cancelButtonColor: '#d33',
                                                                                confirmButtonText: 'Yes, delete it!',
                                                                            }).then((willDelete) => {
                                                                                if (willDelete.isConfirmed) {
                                                                                    delete_certifications(
                                                                                        values.images[`${index}`].id
                                                                                    )
                                                                                        .then((response) => {
                                                                                            if (
                                                                                                response.response_code ==
                                                                                                '200'
                                                                                            ) {
                                                                                                toast.success(
                                                                                                    'Certification Deleted Successfully'
                                                                                                );
                                                                                                remove(index);
                                                                                                // window.location.reload();
                                                                                                // this.fetchComments();
                                                                                                // fetchCertifications();
                                                                                                // setRemoveCertificate(false);
                                                                                            }
                                                                                        })
                                                                                        .catch(function (error) {
                                                                                            console.log(error);
                                                                                            // setRemoveCertificate(false);
                                                                                        });
                                                                                }
                                                                            });
                                                                        }
                                                                        if (!image?.id) {
                                                                            remove(index);
                                                                        }
                                                                    }}></i>
                                                            </Row>
                                                        );
                                                    })}
                                                    <Button
                                                        size="sm"
                                                        type="button"
                                                        onClick={() =>
                                                            push({ certification_name: '', attachment: null })
                                                        }
                                                        className="mb-3"
                                                        title="Add"
                                                    />
                                                </>
                                            )}
                                        </FieldArray>
                                        <Button
                                            type="button"
                                            id="target-button"
                                            className="btn btn-success mr-2 mt-3 float-right"
                                            style={{
                                                padding: '0.4rem 1rem',
                                                fontSize: '0.9rem',
                                                margin: '0.5rem 0',
                                                visibility: 'hidden',
                                            }}
                                            onClick={(e) => {
                                                const data = values.images?.map((key) => {
                                                    return key;
                                                });
                                                const value = new FormData();

                                                data.map((list) => {
                                                    if (!list.id && list.certification_name?.length) {
                                                        value.append('user_id', list.user || values?.user_profile_id);
                                                        value.append('id', null);

                                                        if (list?.certification_name?.length > 0) {
                                                            value.append('certification_name', list.certification_name);
                                                        }
                                                        if (list.attachment || list.attachment) {
                                                            value.append(
                                                                'attachment',
                                                                list.attachment || list.attachment
                                                            );
                                                        }
                                                        if (!list.attachment) {
                                                            value.append('attachment', list.certification_name);
                                                        }
                                                    }
                                                    if (list.id) {
                                                        value.append('user_id', list.user || values?.user_profile_id);
                                                        value.append('id', list.id);

                                                        if (list.certification_name) {
                                                            value.append('certification_name', list.certification_name);
                                                        }
                                                        if (list.attachment?.name) {
                                                            value.append('attachment', list.attachment);
                                                        }
                                                        if (list.attachment && !list.attachment?.name) {
                                                            value.append('attachment', list.id);
                                                        }
                                                        if (!list.attachment) {
                                                            value.append('attachment', list.id);
                                                        }
                                                    }
                                                });
                                                if (!values?.images?.[0]?.certification_name?.length) {
                                                    return;
                                                }
                                                updateCertifications(value);
                                            }}
                                            title={isUpdating ? 'Posting' : 'Post'}
                                            disabled={isUpdating}
                                            // disabled={

                                            // }
                                        />
                                    </Col>
                                )}
                            </Row>
                            <br />
                            {selfRole?.userrole_name === 'Super Admin' || selfRole?.userrole_name === 'Admin' ? (
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col>
                                        <Button
                                            type="button"
                                            disabled={isSubmittingReset}
                                            loading={isSubmittingReset}
                                            onClick={regenerate_status}
                                            title="Reset QR Code"
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                ''
                            )}

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {selfRole?.userrole_name === 'Super Admin' || selfRole?.userrole_name === 'Admin' ? (
                                    <Button
                                        onClick={(e) => handleAction(values, 2)}
                                        disabled={isSubmitting}
                                        title="Back"
                                        varient="secondary"
                                    />
                                ) : null}
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting || isUpdating}
                                    title="Submit"
                                />
                            </div>
                        </form>
                    </>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = ({ Notifications: { loadingCertificate } }) => ({
    loadingCertificate,
});

const mapDispatchToProps = {
    isLoadingCertificate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(DetailInfo);
