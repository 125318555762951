import { Modal as ModalComponent, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React from 'react';
import './modal.css';

const ModalSettings = ({
    title,
    ModalContent,
    footer,
    visible = false,
    size = 'lg',
    handleClose = () => {},
    ...rest
}) => {
    return (
        <ModalComponent isOpen={visible} toggle={handleClose} {...rest} size="lg">
            {Boolean(title) && (
                <ModalHeader>
                    <div>{title}</div>{' '}
                    <div onClick={handleClose} className="closeIcon">
                        X
                    </div>
                </ModalHeader>
            )}
            <ModalBody>
                <ModalContent />
            </ModalBody>
            {Boolean(footer) && <ModalFooter>{footer}</ModalFooter>}
        </ModalComponent>
    );
};

export default ModalSettings;
