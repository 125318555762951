import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { getCurrencyTypes } from "../api.functions";

function useCurrency(props) {

    const [currency, setCurrency] = useState([]);

    const fetch = () => {
        // axios.post(`${config.apiRoot}configuration/currency_type_list`,{"active":"1"})
        const payload = { "active": "1" }

        getCurrencyTypes(payload)
            .then(async (res) => {
                let data = [];
                await res.data.data?.data.map((value) => {
                    data.push({ value: value?.currency_type_id, name: (value?.currency_type_name + ' (' + value?.currency_type_code + ')') });
                })
                let dataUser = data.sort((a, b) => a.name.localeCompare(b.name))

                await setCurrency(dataUser);
            }).catch((e) => {

            })
    }

    return {
        currency,
        fetch
    };
}
export default useCurrency