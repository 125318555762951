import React from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

const LineChartBeta = (props) => {
    const { viewCard = true, title, performanceChartTab, performanceFilterTabs, yearlyPerformance } = props;
    const series = [
        {
            name: performanceFilterTabs.sub_type === 'Revenue' ? 'Revenue' : 'Bookings',
            // data: props.data,
            data: props?.data
                ?.sort((a, b) => {
                    return a?.month - b?.month;
                })
                .map((list) =>
                    performanceFilterTabs?.fiscal_type && yearlyPerformance.is_revenue_hidden
                        ? Math?.trunc(list?.count)
                        : performanceFilterTabs?.sub_type === 'Revenue' || performanceFilterTabs?.fiscal_type
                        ? Math?.trunc(list?.total)
                        : Math?.trunc(list?.count)
                ),
        },
    ];

    const options = {
        markers: {
            size: 5,
            colors: ['#008ffb'],
            strokeColors: '#fff',
            strokeWidth: 2,
            hover: {
                size: 7,
            },
        },
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false,
            },
            toolbar: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: title,
            align: 'left',
        },

        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

            categories: props?.data?.map((list) => list?.month_name || '') || [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        },
    };

    // const data = props.data ?? [];
    const data = props?.data
        ?.sort((a, b) => {
            return a?.month - b?.month;
        })
        .map((list) => list?.count);

    return (
        <div>
            <Chart options={options} series={series} className="apex-charts" height={296} />
        </div>
    );
};

export default LineChartBeta;
