import React from 'react';
import LineChartAppointment from 'pages/charts/LineChartAppointment';
import { Button, ButtonGroup } from 'reactstrap';
import LineChartFinance from 'pages/charts/LineChartFinance';
import LoaderBeta from 'components/LoaderBeta/loader';

const FinancePerformance = ({
    title,
    setFPStatus,
    financialPerformance,
    fPStatus,
    setInitialFinanceStatus,
    financialPerformanceLoading,
}) => {
    return (
        <div
            style={{
                minHeight: '395px',
            }}>
            <div>
                {financialPerformanceLoading ? (
                    <LoaderBeta height="300px" />
                ) : (
                    <LineChartFinance title={title} data={financialPerformance} format={fPStatus} />
                )}
                <div
                    className="app_btn_grps"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <ButtonGroup size="sm" className="my-2">
                        <Button
                            color="primary"
                            outline
                            onClick={() => {
                                setFPStatus('month');
                                setInitialFinanceStatus(false);
                            }}
                            active={fPStatus === 'month'}>
                            Month
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => {
                                setFPStatus('quarter');
                                setInitialFinanceStatus(false);
                            }}
                            active={fPStatus === 'quarter'}>
                            Quarter
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => {
                                setFPStatus('six_month');
                                setInitialFinanceStatus(false);
                            }}
                            active={fPStatus === 'six_month'}>
                            Semi Yearly
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => {
                                setFPStatus('yearly');
                                setInitialFinanceStatus(false);
                            }}
                            active={fPStatus === 'yearly'}>
                            Yearly
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

export default FinancePerformance;
