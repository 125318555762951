import JoditEditor from 'jodit-react';
import React, { useRef, useMemo } from 'react';

const RichTextEditor = ({ initialContent = '', onEditorContentChange = () => {} }) => {
    const editor = useRef(null);

    return useMemo(
        () => (
            <JoditEditor
                ref={editor}
                value={initialContent ?? ''}
                config={{ askBeforePasteFromWord: false, askBeforePasteHTML: false }}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => onEditorContentChange(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => onEditorContentChange(newContent)}
            />
        ),
        []
    );
};
export default RichTextEditor;
