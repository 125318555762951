// @flow
import apis from 'apis/api.actions';
import * as actionTypes from './constants';

export const getNotification = (data, archive, page_size, page) => async (dispatch) => {
    try {
        dispatch({ type: actionTypes.FetchNotifications_REQUEST });

        const data1 = await apis.getDatas(`system/notify?archive=${archive}&page_size=${page_size}&page=${page}`, data);

        dispatch({ type: actionTypes.FetchNotificationsSuccess, payload: data1 });
    } catch (error) {
        dispatch({
            type: actionTypes.FetchNotificationsFailure,
            payload: error.response.data.message,
        });
    }
};
export const getSocketResponse = (data) => (dispatch) => {
    console.log(data);
    dispatch({ type: actionTypes.SOCKETRESPONSE, payload: data });
}
export const isLoadingCertificate = (data) => (dispatch) => {
    console.log(data);
    dispatch({ type: actionTypes.ISLOADING, payload: data });
}


