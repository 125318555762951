import React from 'react';
import SupersetDashboard from './SupersetDashboard';
import DashboardBeta from './DashboardDelta/dashboardBeta';
import SurveyTeamDashboard from './SurveyTeamDashboard';

const MainDashboard = () => {
    const selfRole = JSON.parse(localStorage?.getItem('loggedin_user_role'));
    const permissions = JSON.parse(localStorage?.getItem('permissions'));
    return (
        <div>
            {selfRole?.userrole_name === 'Super Admin' ||
            permissions?.some((t) => t.read == 1 && t.module_id == 73) ? (
                <SupersetDashboard />
            ) : selfRole?.userrole_name === 'Survey Team' ? (
                <SurveyTeamDashboard />
            ) : (
                <DashboardBeta />
            )}
        </div>
    );
};

export default MainDashboard;
