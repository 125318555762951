import React from "react";
import { Input } from "reactstrap";
import "./textField.css"

const TextField = ({ label, required, error = null, ...rest }) => {

    return (
        <div>
            {Boolean(label) && <p className="mb-1 mt-3 font-weight-bold">{`${label} ${required ? "*" : ""}`}</p>}
            <div className="form-group">
                <Input
                    className="form-control"
                    placeholder={label}
                    {...rest}
                />
                {error && <div className="errorMessage" >{error}</div>}
            </div>

        </div>
    )
}

export default TextField;