import React from 'react';
import LineChartBeta from 'pages/charts/LineChartBeta';
import { Card, CardBody, CustomInput, Dropdown, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import Loader from 'components/Loader/loader';

const PerformanceCalendarFiscal = ({
    dropdownPerformanceOpen,
    togglePerformance,
    yearlyPerformance,
    countryList,
    performanceChartTab,
    performanceFilterTabs,
    performanceSheduledLoading,
    setPerformanceFilterTabs,
}) => {
    const isCalendarType = performanceFilterTabs.type === 'Calendar';

    return (
        <div style={{ height: '395px' }}>
            <div>
                <div className="line_charts">
                    <div className="line_charts_header" style={{ justifyContent: 'space-between' }}>
                        <div>
                            <h6 style={{ margin: '0', fontWeight: 'bold' }}>
                                {` ${
                                    JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name ===
                                        'Superintendent' ||
                                    JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team'
                                        ? 'Booking'
                                        : ''
                                } Performance through month`}
                            </h6>
                            <div>
                                <span>
                                    {performanceFilterTabs
                                        ? `(${performanceFilterTabs.type}/ 
                                    ${
                                        isCalendarType
                                            ? performanceFilterTabs.sub_type
                                            : performanceFilterTabs.fiscal_type?.country_name ?? ''
                                    }
                                    )`
                                        : ''}
                                </span>
                            </div>
                            {!isCalendarType && yearlyPerformance?.total_rate && !performanceSheduledLoading && (
                                <span>Total amount: {yearlyPerformance?.total_rate}</span>
                            )}
                        </div>
                        <div>
                            <Dropdown
                                isOpen={dropdownPerformanceOpen}
                                toggle={togglePerformance}
                                style={{
                                    display: 'inline',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: 'steelblue',
                                }}>
                                {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ||
                                JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name ===
                                    'Survey Team' ? null : (
                                    <DropdownToggle data-toggle="dropdown" tag="span">
                                        <FeatherIcon.Filter className="icon-dual icon-xs ml-2" style={{}} />
                                    </DropdownToggle>
                                )}
                                <DropdownMenu
                                    right
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        padding: '16px',
                                        height: '300px',
                                        overflowY: 'auto',
                                    }}>
                                    <CustomInput
                                        type="checkbox"
                                        id="calendarCheckbox"
                                        label="Calendar Year"
                                        checked={performanceFilterTabs.type === 'Calendar' ? true : false}
                                        onClick={(e) =>
                                            yearlyPerformance?.is_revenue_hidden
                                                ? setPerformanceFilterTabs({
                                                      ...performanceFilterTabs,
                                                      type: 'Calendar',
                                                      fiscal_type: '',
                                                      sub_type: 'Bookings',
                                                  })
                                                : setPerformanceFilterTabs({
                                                      ...performanceFilterTabs,
                                                      type: 'Calendar',
                                                      fiscal_type: '',
                                                      sub_type: 'Revenue',
                                                  })
                                        }
                                    />
                                    <CustomInput
                                        type="checkbox"
                                        id="fiscalCheckbox"
                                        label="Fiscal Year"
                                        checked={performanceFilterTabs.type === 'Fiscal' ? true : false}
                                        onClick={(e) =>
                                            setPerformanceFilterTabs({ ...performanceFilterTabs, type: 'Fiscal' })
                                        }
                                    />

                                    <hr />
                                    {performanceFilterTabs.type === 'Calendar' ? (
                                        <div>
                                            {yearlyPerformance?.is_revenue_hidden === false && (
                                                <div
                                                    className={`line_chart_tab ${
                                                        performanceFilterTabs.sub_type === 'Revenue' ? 'active' : ''
                                                    }`}
                                                    onClick={() =>
                                                        setPerformanceFilterTabs({
                                                            ...performanceFilterTabs,
                                                            sub_type: 'Revenue',
                                                        })
                                                    }>
                                                    Revenue
                                                </div>
                                            )}

                                            {yearlyPerformance?.is_revenue_hidden === false ? (
                                                <div
                                                    className={`line_chart_tab ${
                                                        performanceFilterTabs.sub_type === 'Bookings' ? 'active' : ''
                                                    }`}
                                                    onClick={() =>
                                                        setPerformanceFilterTabs({
                                                            ...performanceFilterTabs,
                                                            sub_type: 'Bookings',
                                                        })
                                                    }>
                                                    Bookings
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : null}
                                    {performanceFilterTabs.type === 'Fiscal'
                                        ? countryList?.map((country) => (
                                              <div
                                                  className={`line_chart_tab ${
                                                      country === performanceFilterTabs.fiscal_type ? 'active' : ''
                                                  }`}
                                                  onClick={() =>
                                                      setPerformanceFilterTabs({
                                                          ...performanceFilterTabs,
                                                          fiscal_type: country,
                                                      })
                                                  }>
                                                  {country.country_name}
                                              </div>
                                          ))
                                        : null}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {performanceSheduledLoading ? (
                    <div className="card" style={{ height: '270px' }}>
                        <CardBody className="loaderContainerTest">
                            <Spinner>Loading...</Spinner>
                        </CardBody>
                    </div>
                ) : (
                    yearlyPerformance && (
                        <LineChartBeta
                            // title={` ${
                            //     yearlyPerformance?.is_revenue_hidden ? 'Booking' : ''
                            // } Performance through month`}
                            data={yearlyPerformance?.data}
                            yearlyPerformance={yearlyPerformance}
                            performanceChartTab={performanceChartTab}
                            performanceFilterTabs={performanceFilterTabs}
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default PerformanceCalendarFiscal;
