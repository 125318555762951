import apis from '../../apis/api.actions';
import { parseQueryParams } from '../../utils/formValidate';

// export const getBookingTableList = (data) => apis.createData(`operation/list_booking`, data).then((res) => res);
export const getBookingTableList = (data) =>
    apis.getDatas(`operation/list_booking${parseQueryParams(data)}`).then((res) => res);
export const getTerritory = (data) => apis.createData(`configuration/territory_list`, data).then((res) => res);
export const booking_subsolution_detail = (data) =>
    apis.createData(`operation/booking_subsolution_detail`, data).then((res) => res);
export const list_company_user_type = (data) =>
    apis.createData(`operation/list_company_user_type`, data).then((res) => res);
export const createTimeLog = (data) => apis.createData(`operation/instruction_timelogs`, data).then((res) => res);
export const updateTimeLog = (timelog_id, data) =>
    apis.updateData(`operation/instruction_timelogs/${timelog_id}`, data).then((res) => res);
export const deleteTimeLog = (timelog_id) =>
    apis.deleteData(`operation/instruction_timelogs/${timelog_id}`).then((res) => res);
export const getCurrencyTypes = (data) => apis.createData(`configuration/currency_type_list`, data).then((res) => res);
export const getTaskLogList = (data) => apis.createData(`management/timesheet_list`, data).then((res) => res);
export const getTimesheetId = (data) => apis.getDatas(`management/timesheet_list_new/${data}/`).then((res) => res);
export const updateTimeSheet = (timesheet_id, data) =>
    apis.updateData(`configuration/work_log_time_sheets/${timesheet_id}`, data).then((res) => res);
export const createTaskTimeLog = (data) => apis.createData(`management/task_timelogs`, data).then((res) => res);
export const update_sub_solution_booking = (data) =>
    apis.createData(`operation/update_sub_solution_booking`, data).then((res) => res);
export const update_superintent = (data) => apis.createData(`operation/update_superintent`, data).then((res) => res);
export const add_booking = (data) => apis.createData(`operation/add_booking`, data).then((res) => res);
export const booking_detail = (data) => apis.createData(`operation/booking_detail`, data).then((res) => res);
export const list_superintent = (data) =>
    apis.createData(`operation/list_superintent${parseQueryParams(data)}`, data).then((res) => res);
export const delete_subsolution_booking = (data) =>
    apis.createData(`operation/delete_subsolution_booking`, data).then((res) => res);
export const updateTaskTimeLog = (timelog_id, data) =>
    apis.updateData(`management/task_timelogs/${timelog_id}`, data).then((res) => res);
export const deleteTaskTimeLog = (timelog_id) =>
    apis.deleteData(`management/task_timelogs/${timelog_id}`).then((res) => res);
export const update_eta_view = (data) => apis.createData(`management/update_eta_view`, data).then((res) => res);

export const list_activity_comments = (data) =>
    apis.getDatas(`operation/activity_comments${parseQueryParams(data)}`).then((res) => res);
export const list_tariff_new = (data) => apis.createData(`finance/list_tariff_new`, data).then((res) => res);
export const update_tariff = (data) => apis.createData(`finance/update_tariff`, data).then((res) => res);
export const create_tariff = (data) => apis.createData(`finance/create_tariff`, data).then((res) => res);
export const list_source = (data) => apis.getDatas(`operation/list_source${parseQueryParams(data)}`).then((res) => res);
export const create_activity_comments = (data) =>
    apis.createData(`operation/activity_comments`, data).then((res) => res);
export const update_activity = (data) => apis.createData(`operation/update_activity`, data).then((res) => res);
export const create_activity = (data) => apis.createData(`operation/create_activity`, data).then((res) => res);
export const delete_activity_attachments = (id) =>
    apis.deleteData(`operation/activity-attachments/${id}`).then((res) => res);
export const add_activity_attachments = (data) =>
    apis.createData(`operation/activity-attachments`, data).then((res) => res);
export const list_user = (data) => apis.createData(`system/list_user`, data).then((res) => res);
export const list_user_view_based_permission = (data) =>
    apis.createData(`system/list_user_based`, data).then((res) => res);

export const update_certifications = (data) => apis.createData(`system/certifications`, data).then((res) => res);

export const reset_qr_code = (data) => apis.createData('system/regenerate-qr/', data).then((res) => res);
export const get_certifications = (data) =>
    apis.getDatas(`system/certifications${parseQueryParams(data)}`).then((res) => res);
export const get_certificationList = () => apis.getDatas(`system/certifications-list`).then((res) => res);

export const get_organization_list = (data) =>
    apis.getDatas(`system/list-organization${parseQueryParams(data)}`).then((res) => res);

export const delete_certifications = (id) => apis.deleteData(`system/certifications/${id}`).then((res) => res);
export const delete_single_certification_file = (id) =>
    apis.deleteData(`system/certifications/${id}/delete_certification`).then((res) => res);

export const severity_types_list = (data) =>
    apis.createData(`configuration/severity_types_list`, data).then((res) => res);
export const activity_status_list = (data) =>
    apis.createData(`configuration/activity_status_list`, data).then((res) => res);
export const activity_type_list = (data) =>
    apis.createData(`configuration/activity_type_list`, data).then((res) => res);
export const update_activity_comments = (id, data) =>
    apis.updateData(`operation/activity_comments/${id}`, data).then((res) => res);
export const delete_activity_comments = (id, data) =>
    apis.deleteData(`operation/activity_comments/${id}`).then((res) => res);

export const add_booking_contractor_rate = (data) =>
    apis.createData(`operation/booking_contractor_rates`, data).then((res) => res);

export const update_booking_contractor_rate = (id, data) =>
    apis.updateData(`operation/booking_contractor_rates/${id}`, data).then((res) => res);
export const delete_booking_contractor_rate = (id) =>
    apis.deleteData(`operation/booking_contractor_rates/${id}`).then((res) => res);
export const get_booking_contractor_rate = (data) =>
    apis.getDatas(`operation/booking_contractor_rates${parseQueryParams(data)}`).then((res) => res);
export const billing_agreement_type_list = () =>
    apis.getDatas(`configuration/billing_agreement_type_list`).then((res) => res);
export const sub_solutions_list = (data) =>
    apis.createData(`configuration/sub_solutions_list`, data).then((res) => res);
export const bookings_status_list = (data) =>
    apis.createData(`configuration/bookings_status_list`, data).then((res) => res);
export const tarrif_rate_type_list = (data) =>
    apis.createData(`configuration/tarrif_rate_type_list`, data).then((res) => res);
export const create_invoice = (data) => apis.createData(`finance/create_invoice`, data).then((res) => res);
export const delete_tariff = (data) => apis.createData(`finance/delete_tariff`, data).then((res) => res);
export const list_customer_tariff_lines = (data) =>
    apis.createData(`finance/list_customer_tariff_lines`, data).then((res) => res);
export const calculate_grand_total_view = (data) =>
    apis.createData(`finance/calculate_grand_total_view`, data).then((res) => res);
export const fetch_new_rates = (data) => apis.createData(`finance/fetch_new_rates`, data).then((res) => res);
export const fetch_new_scopes = (data) => apis.createData(`operation/fetch-subsolution-scope`, data).then((res) => res);
export const fetch_new_worklogs = (data) =>
    apis.createData(`operation/fetch-subsolution-worklog`, data).then((res) => res);
export const update_worklog = (data) => apis.createData(`management/update_timesheet`, data).then((res) => res);

//check before edit/delete

export const check_booking_edit = (data) =>
    apis.getDatas(`incentives/incentive_solution_mapping_list/${parseQueryParams(data)}`, data).then((res) => res);

//  calendar apis
export const get_bookings_status = (data) =>
    apis.getDatas(`operation/calendar/get_bookings_status/`, data).then((res) => res);
export const get_superintent_users = (data) =>
    apis.getDatas(`operation/calendar/get_superintent_users/`, data).then((res) => res);
export const get_bookings = (data) =>
    apis.getDatas(`operation/calendar/get_bookings/${parseQueryParams(data)}`, data).then((res) => res);

export const get_booking_id = (id) => apis.getDatas(`operation/calendar/get_booking/${id}/`, id).then((res) => res);
export const get_saved_calendar_filter = (data) =>
    apis.getDatas(`operation/calendar/saved_filter/`, data).then((res) => res);

// ----------------- new version calendar APIS-------------------------
// export const get_bookings_new2 = (data) =>
//     apis.getDatas(`operation/calendar/calendar-data-web${parseQueryParams(data)}`, data).then((res) => res); // beta

export const get_bookings_new = (data) => {
    const location = window?.location?.pathname;
    if (location?.includes('beta-calendar')) {
        return apis.getDatas(`operation/calendar/calendar-data-web${parseQueryParams(data)}`, data).then((res) => res); // beta
    } else {
        return apis.getDatas(`operation/calendar/get_bookings_new/${parseQueryParams(data)}`, data).then((res) => res);
    }
}; // org
export const get_booking_id_new = ({ id, data }) =>
    apis.getDatas(`operation/calendar/get_booking_new/${id}/${parseQueryParams(data)}`, id).then((res) => res);
// ----------------------- END -------------------------

export const get_customers = (data) => apis.getDatas(`operation/calendar/get_customers/`, data).then((res) => res);
export const get_locations = (data) => apis.getDatas(`operation/calendar/get_locations/`, data).then((res) => res);
export const get_country_list = (data) =>
    apis.getDatas(`operation/calendar/get_country_lists/`, data).then((res) => res);
export const get_territory_list = (data) =>
    apis.getDatas(`operation/calendar/get_territory_lists/`, data).then((res) => res);
export const get_saved_calendar_filter_detail = (id) =>
    apis.getDatas(`operation/calendar/saved_filter/${id}/`, id).then((res) => res);

export const get_sub_solutions = (data) =>
    apis.getDatas(`operation/calendar/get_subsolution/`, data).then((res) => res);
export const display_option_view = (data) =>
    apis.getDatas(`operation/calendar/display_option_view/`, data).then((res) => res);
export const chain_option_view = (data) =>
    apis.getDatas(`operation/calendar/chain_option_view/`, data).then((res) => res);
export const updateBookingDetails = (data) =>
    apis.createData(`operation/calendar/update_booking_detail_line/`, data).then((res) => res);
