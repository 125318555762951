import axios from "axios";
import config from "../../../config";
import { useState } from "react";
import { getUserRole } from "../api.functions";

const useUserRole = () => {

    const [customerList, setCustomerList] = useState();
    const [loading, setLoading] = useState(false);

    const fetch = () => {
        setLoading(true)
        // axios.post(`${config.apiRoot}system/list_user_role`)
        getUserRole({ user_id: localStorage.getItem('loggedin_user_id') })
            .then((res) => {
                setCustomerList(res?.data);
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
    }

    return {
        fetch,
        customerList,
        loading
    }

}

export default useUserRole;