import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { withRouter } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Label,
    FormGroup,
    Button,
    Alert,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';

import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Mail, Key, Lock } from 'react-feather';

import { loginUser } from '../../redux/actions';
import { isUserAuthenticated } from '../../helpers/authUtils';
import Loader from '../../components/Loader';
import logo from '../../assets/images/logo.png';
import apis from 'apis/api.actions';
// import { SentOtpToken } from 'pages/apps/api.functions';

const SendOTP = ({ loading, success, error }) => {
    const [enteredToken, setEnteredToken] = useState('');
    const renderRedirectToRoot = () => {
        let isAuthTokenValid = isUserAuthenticated();

        if (isAuthTokenValid) {
            window.location.href = '/dashboard';
        }
    };

    // const handleValidSubmit = async (event, values) => {
    //     // let token = values.token;
    //     // this.props.loginUser(this.state.username, this.state.password, token, this.props.history);

    //     const getLoginOTP = await apis
    //         .createData(`system/2factor-qa/`, { otp_code: values.token })
    //         .then((res) => res)
    //         .catch((err) => toast.error(err?.response?.message || 'Something went wrong.'));
    //     console.log(getLoginOTP, 'KSDLFNMSDKF');

    // };

    const handleValidSubmit = async (event, values) => {
        try {
            const getLoginOTP = await apis.createData(`system/2factor-qa-verify/`, { otp_code: values.token });
            console.log(getLoginOTP?.response, 'getLogin ');
            // console.log(getLoginOTP?.response?, 'getLogin ');
            // Check the response status code here
            if (getLoginOTP?.response?.status >= 400 && getLoginOTP?.response?.status < 600) {
                // Handle the 400 error here
                // For example, show an error message or take appropriate action
                toast.error(getLoginOTP?.response?.message || 'Invalid token');

                console.log('API returned a 400 status code:', getLoginOTP);
            } else {
                // Handle the successful response
                localStorage.setItem('br_login_response', JSON.stringify(getLoginOTP));
                window.location.href = '/dashboard';
                toast.error('Successfully loggedin');
            }
        } catch (err) {
            // Handle other errors that occurred during the API call
            console.error('An error occurred during the API call:', err);
            toast.error(err?.response?.message || 'Something went wrong.');
        }
    };
    // const buttonLabel = this.state.remainingTime > 0 ? `Wait ${this.state.remainingTime} sec` : '';

    return (
        <React.Fragment>
            {renderRedirectToRoot()}

            {/* {(this._isMounted || !isAuthTokenValid) && ( */}
            <>
                <Container>
                    <div
                        // style={{ backgroundColor: '#ffffff' }}
                        // className="login-page-header-logo"
                        // style={{ width: '35%', left: '0.5%', marginTop: '5%', backgroundColor: '#ffffff' }}
                        style={{
                            maxWidth: '400px',
                            padding: '40px ',
                            margin: 'auto',
                            marginTop: '10%',
                            backgroundColor: '#ffffff',
                            borderRadius: '5px',
                        }}>
                        <div style={{ margin: 'auto', width: '60%', textAlign: 'center' }}>
                            <img
                                src="/icons8-telegram-app-50.png"
                                style={{ margin: '40px;', width: '25px;', height: '25px;' }}
                            />
                        </div>
                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '10px' }}>
                                Please check your Microsoft authenticator app for the QR code.
                            </p>
                        </div>
                        {/* <Row className="justify-content-center"> */}
                        {/* <Col style={{ width: '100%' }}>
                                        <Card className="login_component_card1">
                                            <CardBody className="p-4"> */}
                        {loading && <Loader />}

                        {error && (
                            <Alert color="danger" isOpen={error ? true : false}>
                                <div>{error}</div>
                            </Alert>
                        )}

                        {success && (
                            <Alert color="success" isOpen={success ? true : false}>
                                <div>{this.props.success}</div>
                            </Alert>
                        )}
                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                            <p>
                                To log in, enter the security code we sent to authenticator app
                                {/* {this.state.email} */}, will expire in 30 seconds
                            </p>
                            <p
                                style={{
                                    top: '200px',
                                    left: 0,
                                    width: '100%',
                                    textAlign: 'center',
                                }}></p>
                        </div>
                        <div style={{ margin: 'auto' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ float: 'left' }}>Your 6-digit code</p>
                            </div>

                            <AvForm
                                onValidSubmit={handleValidSubmit}
                                // className="authentication-form"
                            >
                                <AvGroup>
                                    <InputGroup style={{ position: 'relative' }}>
                                        <AvInput
                                            type="text"
                                            name="token"
                                            id="token"
                                            placeholder="Enter your otp"
                                            value={enteredToken}
                                            onChange={(e) => setEnteredToken(e.target.value)}
                                            required
                                            style={{
                                                width: '100%',
                                                padding: '12px',
                                                display: 'inline-block',
                                                border: '1px solid black',
                                                boxSizing: 'border-box',
                                            }}
                                            maxLength={6}
                                        />
                                        {/* {buttonLabel ? (
                                            <p
                                                style={{
                                                    fontWeight: '500',
                                                    fontSize: '13px',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    right: '5px',
                                                }}>
                                                {buttonLabel}
                                            </p>
                                        ) : ( */}
                                        {/* <a
                                            style={{
                                                // fontWeight: '500',
                                                // fontSize: '13px',
                                                position: 'absolute',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                right: '5px',
                                            }}
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // this.send_token();
                                                // this.handleClick();
                                            }}>
                                            {' '}
                                            <img
                                                                height={23}
                                                                width={23}
                                                                src="/icons8-telegram-app-50.png"
                                                                style={{ background: 'rgb(51, 73, 119)' }}
                                                            />
                                                            
                                            Resend Token
                                        </a> */}
                                        {/* )} */}
                                    </InputGroup>

                                    <FormGroup className="form-group mb-0 mt-3 text-center">
                                        <button
                                            className="btn btn-primary"
                                            style={{
                                                width: '100%',
                                                // padding: '14px 20px',
                                                // margin: '8px 0',

                                                // cursor: 'pointer',

                                                // fontWeight: 'bold',
                                                // // color: 'black',
                                                // fontSize: '15px',
                                            }}
                                            type="submit">
                                            Submit
                                            {/* {this.state.loading_page && <Loader></Loader>} */}
                                        </button>
                                    </FormGroup>

                                    <AvFeedback>This field is invalid</AvFeedback>
                                </AvGroup>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}>
                                    {/* <div>
                                        <a href="/details_about_otp" target="_blank">
                                            Learn more
                                        </a>
                                    </div> */}
                                    <div>
                                        {/* <a
                                            // style={{
                                            //     // fontWeight: '500',
                                            //     // fontSize: '13px',
                                            //     position: 'absolute',
                                            //     top: '50%',
                                            //     transform: 'translateY(-50%)',
                                            //     right: '5px',
                                            // }}
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // this.send_token();
                                                // this.handleClick();
                                            }}>
                                            {' '}
                                           
                                            Resend Token
                                        </a> */}
                                    </div>
                                </div>

                                {/* <button
                                                        style={{
                                                            textDecoration: 'none',
                                                            background: 'none',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            fontSize: '16px',
                                                        }}
                                                        onClick={() => {
                                                            this.send_token();
                                                            this.handleClick();
                                                        }}>
                                                        Resend Token
                                                    </button> */}

                                {/* <a href="#" style={{ textDecoration: 'none' }}>
                                                Learn more
                                            </a> */}
                            </AvForm>
                        </div>
                        {/* </CardBody>
                                        </Card>
                                    </Col> */}
                        {/* </Row> */}
                    </div>

                    <div className="login-page-footer-copyright">
                        <p className="mb-0">
                            © {new Date().getFullYear()}{' '}
                            <a href="https://propelmarine.com/" target="_blank">
                                Propel Marine Pty Ltd.
                            </a>
                            <i className="mdi mdi-heart text-danger"></i> All rights reserved.
                        </p>
                    </div>
                </Container>
                {/* <div className="account-pages" style={{ width: '65%' }}></div> */}
            </>
            {/* )} */}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;

    return { user, loading, error };
};

// export default connect(mapStateToProps, { loginUser })(Login);

export default connect(mapStateToProps, { loginUser })(SendOTP);

// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { Redirect, Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

// import { withRouter } from 'react-router-dom';
// import {
//     Container,
//     Row,
//     Col,
//     Card,
//     CardBody,
//     Label,
//     FormGroup,
//     Button,
//     Alert,
//     InputGroup,
//     InputGroupAddon,
// } from 'reactstrap';

// import { toast } from 'react-toastify';
// import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
// import { Mail, Key, Lock } from 'react-feather';

// import { loginUser } from '../../redux/actions';
// import { isUserAuthenticated } from '../../helpers/authUtils';
// import Loader from '../../components/Loader';
// import logo from '../../assets/images/logo.png';
// // import { SentOtpToken } from 'pages/apps/api.functions';

// class SendOTP extends Component {
//     _isMounted = false;

//     constructor(props) {
//         // console.log(props);
//         super(props);
//         this.handleValidSubmit = this.handleValidSubmit.bind(this);
//         this.state = {
//             username: '',
//             password: '',
//             email: '',
//         };
//         this.state = {
//             show_token_page: false,
//         };
//         this.state = {
//             loading_page: false,
//         };
//         this.state = {
//             isButtonDisabled: false,
//             remainingTime: 180,
//             // remainingTime: 5,
//         };
//         this.handleClick = this.handleClick.bind(this);
//     }

//     handleClick() {
//         this.setState({ isButtonDisabled: true, remainingTime: 180 });
//         // this.setState({ isButtonDisabled: true, remainingTime: 5 });
//     }

//     componentDidMount() {
//         this._isMounted = true;
//         document.body.classList.add('authentication-bg');

//         const { location } = this.props;
//         const { state } = location;

//         this.setState({ username: state.userdata.username });
//         this.setState({ password: state.userdata.password });
//         this.setState({ email: state.userdata.email });

//         this.timerID = setInterval(() => {
//             if (this.state.remainingTime > 0 && this.state.isButtonDisabled) {
//                 this.setState({ remainingTime: this.state.remainingTime - 1 });
//             } else {
//                 this.setState({ isButtonDisabled: false });
//             }
//         }, 1000);
//         this.handleClick();
//     }
//     componentWillUnmount() {
//         this._isMounted = false;
//         document.body.classList.remove('authentication-bg');
//         clearInterval(this.timerID);
//     }
//     /**
//      * Handles the submit
//      */
//     handleValidSubmit = (event, values) => {
//         let token = values.token;

//         this.props.loginUser(this.state.username, this.state.password, token, this.props.history);
//     };

//     // send_token = (event, values) => {
//     //     this.setState({ loading_page: true });

//     //     const data = { username: this.state.username, password: this.state.password };

//     //     SentOtpToken(data)
//     //         .then((res) => {
//     //             toast.success('Token send to Email');
//     //             this.setState({ loading_page: false });
//     //         })
//     //         .catch((err) => {
//     //             toast.error('Wait for a three minutes');
//     //             this.setState({ loading_page: false });
//     //         });
//     // };

//     /**
//      * Redirect to root
//      */
//     renderRedirectToRoot = () => {
//         const isAuthTokenValid = isUserAuthenticated();

//         if (isAuthTokenValid) {
//             window.location.href = '/dashboard';
//         }
//     };

//     render() {
//         const isAuthTokenValid = isUserAuthenticated();
//         localStorage.setItem('operations', 'false');

//         const buttonLabel = this.state.remainingTime > 0 ? `Wait ${this.state.remainingTime} sec` : '';

//         return (
//             <React.Fragment>
//                 {this.renderRedirectToRoot()}

//                 {(this._isMounted || !isAuthTokenValid) && (
//                     <>
//                         <Container>
//                             <div
//                                 // style={{ backgroundColor: '#ffffff' }}
//                                 // className="login-page-header-logo"
//                                 // style={{ width: '35%', left: '0.5%', marginTop: '5%', backgroundColor: '#ffffff' }}
//                                 style={{
//                                     maxWidth: '370px',
//                                     padding: '40px ',
//                                     margin: 'auto',
//                                     marginTop: '10%',
//                                     backgroundColor: '#ffffff',
//                                     borderRadius: '5px',
//                                 }}>
//                                 <div style={{ margin: 'auto', width: '50%', textAlign: 'center' }}>
//                                     <img
//                                         src="/icons8-telegram-app-50.png"
//                                         style={{ margin: '40px;', width: '25px;', height: '25px;' }}
//                                     />
//                                 </div>
//                                 <div style={{ margin: 'auto', textAlign: 'center' }}>
//                                     <p style={{ fontWeight: 'bold', fontSize: '20px', marginTop: '10px' }}>
//                                         We just sent you an e-mail
//                                     </p>
//                                 </div>
//                                 {/* <Row className="justify-content-center"> */}
//                                 {/* <Col style={{ width: '100%' }}>
//                                     <Card className="login_component_card1">
//                                         <CardBody className="p-4"> */}
//                                 {this.props.loading && <Loader />}

//                                 {this.props.error && (
//                                     <Alert color="danger" isOpen={this.props.error ? true : false}>
//                                         <div>{this.props.error}</div>
//                                     </Alert>
//                                 )}

//                                 {this.props.success && (
//                                     <Alert color="success" isOpen={this.props.success ? true : false}>
//                                         <div>{this.props.success}</div>
//                                     </Alert>
//                                 )}
//                                 <div style={{ margin: 'auto', textAlign: 'center' }}>
//                                     <p>
//                                         To log in, enter the security code we sent to {this.state.email} will expire in
//                                         3 minutes
//                                     </p>
//                                     <p
//                                         style={{
//                                             top: '200px',
//                                             left: 0,
//                                             width: '100%',
//                                             textAlign: 'center',
//                                         }}></p>
//                                 </div>
//                                 <div style={{ margin: 'auto' }}>
//                                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                                         <p style={{ float: 'left' }}>Your 6-digit code</p>
//                                     </div>

//                                     <AvForm
//                                         onValidSubmit={this.handleValidSubmit}
//                                         // className="authentication-form"
//                                     >
//                                         <AvGroup>
//                                             <InputGroup style={{ position: 'relative' }}>
//                                                 <AvInput
//                                                     type="text"
//                                                     name="token"
//                                                     id="token"
//                                                     placeholder="Enter your otp"
//                                                     value={this.state.token}
//                                                     required
//                                                     style={{
//                                                         width: '100%',
//                                                         padding: '12px',
//                                                         display: 'inline-block',
//                                                         border: '1px solid black',
//                                                         boxSizing: 'border-box',
//                                                     }}
//                                                 />
//                                                 {buttonLabel ? (
//                                                     <p
//                                                         style={{
//                                                             fontWeight: '500',
//                                                             fontSize: '13px',
//                                                             position: 'absolute',
//                                                             top: '50%',
//                                                             transform: 'translateY(-50%)',
//                                                             right: '5px',
//                                                         }}>
//                                                         {buttonLabel}
//                                                     </p>
//                                                 ) : (
//                                                     <a
//                                                         style={{
//                                                             // fontWeight: '500',
//                                                             // fontSize: '13px',
//                                                             position: 'absolute',
//                                                             top: '50%',
//                                                             transform: 'translateY(-50%)',
//                                                             right: '5px',
//                                                         }}
//                                                         href="/"
//                                                         onClick={(e) => {
//                                                             e.preventDefault();
//                                                             // this.send_token();
//                                                             this.handleClick();
//                                                         }}>
//                                                         {' '}
//                                                         {/* <img
//                                                             height={23}
//                                                             width={23}
//                                                             src="/icons8-telegram-app-50.png"
//                                                             style={{ background: 'rgb(51, 73, 119)' }}
//                                                         />
//                                                          */}
//                                                         Resend Token
//                                                     </a>
//                                                 )}
//                                             </InputGroup>

//                                             <FormGroup className="form-group mb-0 mt-3 text-center">
//                                                 <Button
//                                                     style={{
//                                                         width: '100%',
//                                                         backgroundColor: '#334977',
//                                                         color: 'white',
//                                                         // padding: '14px 20px',
//                                                         // margin: '8px 0',

//                                                         cursor: 'pointer',

//                                                         fontWeight: 'bold',
//                                                         // color: 'black',
//                                                         fontSize: '15px',
//                                                     }}
//                                                     type="submit">
//                                                     Submit
//                                                     {this.state.loading_page && <Loader></Loader>}
//                                                 </Button>
//                                             </FormGroup>

//                                             <AvFeedback>This field is invalid</AvFeedback>
//                                         </AvGroup>
//                                         <div
//                                             style={{
//                                                 textAlign: 'center',
//                                             }}>
//                                             <a href="/details_about_otp" target="_blank">
//                                                 Learn more
//                                             </a>
//                                         </div>

//                                         {/* <button
//                                                     style={{
//                                                         textDecoration: 'none',
//                                                         background: 'none',
//                                                         border: 'none',
//                                                         cursor: 'pointer',
//                                                         fontSize: '16px',
//                                                     }}
//                                                     onClick={() => {
//                                                         this.send_token();
//                                                         this.handleClick();
//                                                     }}>
//                                                     Resend Token
//                                                 </button> */}

//                                         {/* <a href="#" style={{ textDecoration: 'none' }}>
//                                             Learn more
//                                         </a> */}
//                                     </AvForm>
//                                 </div>
//                                 {/* </CardBody>
//                                     </Card>
//                                 </Col> */}
//                                 {/* </Row> */}
//                             </div>

//                             <div className="login-page-footer-copyright">
//                                 <p className="mb-0">
// © {new Date().getFullYear()} <a src="https://propelmarine.com/"> Propel Marine Pty Ltd.</a>
// <i className="mdi mdi-heart text-danger"></i> All rights reserved.
// </p>
//                             </div>
//                         </Container>
//                         {/* <div className="account-pages" style={{ width: '65%' }}></div> */}
//                     </>
//                 )}
//             </React.Fragment>
//         );
//     }
// }

// const mapStateToProps = (state) => {
//     const { user, loading, error } = state.Auth;

//     return { user, loading, error };
// };

// // export default connect(mapStateToProps, { loginUser })(Login);

// export default connect(mapStateToProps, { loginUser })(SendOTP);
