import React, { useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    FormGroup,
    CustomInput,
    Button,
} from 'reactstrap';
import moment from 'moment';
import Loader from '../../../components/Loader';
import {
    getSuperIntendentDashboardDelta,
    getReportDashboardDelta,
    getBookingDashboardDelta,
    getSubSolutionsDashboardDeltaIntial,
    getFilteredSolutionDatas,
    getFilteredSubSolutionDatas,
    getFilteredCustomersDatas,
    getFilteredLocationDatas,
    getReportDashboardDeltaAppointment,
    getReportDashboardDeltaScheduled,
    getCountrysSchedule,
    getFinancePerformanceScheduled,
    getSubSolutionsDashboardDeltaRevenue,
    getDashJobStatus,
    getSuperIntendentWorklog,
    getSuperDashBoard,
} from '../api.functions';
import TreeMap from 'pages/charts/TreeMap';

import TasksBeta from './TasksBeta';
import DashboardTodo from './dashboardTodo';
import { RiRefreshLine } from 'react-icons/ri';
import BarChartBetaRevenue from 'pages/charts/BarChartBetaRevenue';
import BarChartBetaJobs from 'pages/charts/BarChartBetaJobs';
import Flatpickr from 'react-flatpickr';

import { debounce } from 'throttle-debounce';
import DashboardSuperintendentTable from './superintendent/table';
import PerformanceRevenue from './PerformanceRevenue';
import Statistics from './statistics/statistics';
import Tiles from './statistics/tiles';
import PerformanceCalendarFiscal from './Performance/PerformanceCalendarFiscal';
import FinancePerformance from './FinancePerformance';
import * as FeatherIcon from 'react-feather';
import LoaderBeta from 'components/LoaderBeta/loader';
import DashTable from './dashboardBetaSuper';
import { toast } from 'react-toastify';

const DashboardBeta = (props) => {
    const [dashReport, setDashReport] = useState(undefined);
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [refresh, setRefresh] = useState(false);

    const [pASelected, setPASelected] = React.useState('day');
    const [fPStatus, setFPStatus] = React.useState('month');

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownPerformanceOpen, setDropdownPerformanceOpen] = useState(false);
    const [barTabSelection, setBarTabSelection] = useState({ label: 'Subsolution', value: 'subsolution' });
    const [countryList, setCountryList] = useState([]);
    const [initialFinanceStatus, setInitialFinanceStatus] = useState(true);

    // new
    const [super_dash, setSuper_dash] = useState(null);

    const [dashDataTable, setDashDataTable] = useState({ name: '', data: [] });

    //solutions
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getCountrysSchedule().then((res) => setCountryList(res?.dataset));
        }, 2.3);

        return () => {
            clearTimeout(timeOutId);
        };
    }, []);
    useEffect(() => {
        fetchSuperData();
    }, []);

    const fetchSuperData = async () => {
        setIsStatisticsLoading(true);
        try {
            const data = await getSuperDashBoard({
                user_id: JSON.parse(localStorage.getItem('loggedin_user_id')),
            });
            if (data?.response_code === 200) {
                setSuper_dash(data);
                setIsStatisticsLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Something went wrong, please try again later.');
            setIsStatisticsLoading(false);
        }
    };

    const [selectedSolution, setSelectedSolution] = useState(undefined);
    //sub solutions

    const [selectedSubSolution, setSelectedSubSolution] = useState(undefined);
    const [subSolutionLoading, setSubSolutionLoading] = useState(false);

    const [isStatisticsLoading, setIsStatisticsLoading] = useState(false);
    const [isLoadingAppointmentPerformance, setIsLoadingAppointmentPerformance] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const togglePerformance = () => setDropdownPerformanceOpen((prevState) => !prevState);
    const [dashData, setDashData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [todaysJobCount, setTodaysJobCount] = useState('');
    const [superintendantCount, setSuperintendantCount] = useState('');
    const [locationCount, setLocationCount] = useState('');
    const [vesselCount, setVesselCount] = useState('');
    const [yearlyPerformance, setYearlyPerformance] = useState([]);
    const [appointmentPerformance, setAppointmentPerformance] = useState([]);
    const [financialPerformance, setFinancialPerformance] = useState([]);
    const [financialPerformanceLoading, setFinancialPerformanceLoading] = useState(false);

    const [jobStatus, setJobStatus] = useState('');
    const barChartRef = useRef();
    const [statistics, setStatistics] = useState([]);
    const [subSolutionItems, setSubSolutionItems] = useState([]);
    const [subsolutionCount, setSubsolutionCount] = useState([]);
    const [subSolutionsJobs, setSubSolutionJobs] = useState([]);
    const [lineChartTab, setLineChartTab] = useState('jobs');
    const [performanceFilterTabs, setPerformanceFilterTabs] = useState({
        type: 'Calendar',
        sub_type:
            JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team' ||
            JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent'
                ? 'Bookings'
                : 'Revenue',
        fiscal_type: countryList?.[0] || '',
    });
    const [performanceSheduledLoading, setPerformanceSheduledLoading] = useState(false);
    const [searchByRevenew, setSearchByRevenew] = useState('');
    const [jobStatusId, setJobStatusId] = useState(undefined);
    const [bookingStatusToday, setBookingStatusToday] = useState(false);
    const [bookingStatusReceivedToday, setBookingStatusReceivedToday] = useState(false);
    const [bookingStatusMonth, setBookingStatusMonth] = useState(false);
    const [overallDatas, setOverallDatas] = useState([]);
    //superintendent
    const [superIntendentData, setSuperIntendentData] = useState([]);
    const [superIntendentType, setSuperIntendentType] = useState('propel');
    const [superIntendentId, setSuperIntendentId] = useState('');
    const [worklogMissingStatus, setWorklogMissingStatus] = useState(false);
    const [timelogMissingStatus, setTimelogMissingStatus] = useState(false);
    const [worklogMissingData, setWorklogMissingData] = useState([]);
    const [worklogMissingLoading, setWorklogMissingLoading] = useState(false);
    //transation
    const transactionRef = useRef();
    const transactionRef1 = useRef();
    const [bookingDatas, setBookingDatas] = useState([]);
    const [missingWorkLogCount, setMissingWorkLogCount] = useState(0);
    const [isBookingLoading, setIsBookingLoading] = useState(true);

    const completedJobCount = jobStatus?.data && jobStatus?.data?.filter((list) => list.booking_status_id === 5);
    const totalJobsOrderCount = jobStatus?.data && jobStatus?.data?.filter((list) => list.booking_status_id !== 4);
    const totalJobsCount = totalJobsOrderCount && totalJobsOrderCount?.reduce((acc, item) => acc + item.count, 0);

    // let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeZone = 'Australia/Perth';

    var subsolution_query = {};
    if (barTabSelection.value === 'subsolution') {
        subsolution_query = {
            subsolution_id: selectedSubSolution ? selectedSubSolution.id : '',
        };
    } else if (barTabSelection.value === 'territory') {
        subsolution_query = {
            territory_id: selectedSubSolution ? selectedSubSolution.id : '',
        };
    } else if (barTabSelection.value === 'customer') {
        subsolution_query = {
            customer_id: selectedSubSolution ? selectedSubSolution.id : '',
        };
    } else if (barTabSelection.value === 'location') {
        subsolution_query = {
            location_id: selectedSubSolution ? selectedSubSolution.id : '',
        };
    } else {
        subsolution_query = {
            subsolution_id: '',
            territory_id: '',
            customer_id: '',
            location_id: '',
        };
    }

    const handleJobStatus = (job_status) => {
        transactionRef.current.scrollIntoView({
            behavior: 'smooth',
        });
        setJobStatusId(job_status);
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            handleFetchSuperData();
        }, 1);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [
        selectedSolution,
        selectedSubSolution,
        endDate,
        bookingStatusReceivedToday,
        bookingStatusMonth,
        bookingStatusToday,
        superIntendentType,
    ]);

    const fetchWorklogMissing = () => {
        setWorklogMissingLoading(true);
        const data = {
            start_date: startDate || '',
            end_date: endDate || '',
            // subsolution_id: selectedSubSolution ? selectedSubSolution.id : '',
            ...subsolution_query,
            solution_id: selectedSolution ? selectedSolution.solutions_id : '',
            received_today: bookingStatusReceivedToday || '',
            month_booking: bookingStatusMonth || '',
            today: bookingStatusToday || '',
            time_zone: timeZone,
            key: superIntendentType || '',
        };
        getSuperIntendentWorklog(data)
            .then((response) => {
                setWorklogMissingData(response);
                setWorklogMissingLoading(false);
            })
            .catch((error) => {
                setWorklogMissingLoading(false);
            });
    };

    const handleFetchSuperData = () => {
        setIsLoading(true);
        const data = {
            start_date: startDate || '',
            end_date: endDate || '',
            // subsolution_id: selectedSubSolution ? selectedSubSolution.id : '',
            ...subsolution_query,
            solution_id: selectedSolution ? selectedSolution.solutions_id : '',
            received_today: bookingStatusReceivedToday || '',
            month_booking: bookingStatusMonth || '',
            today: bookingStatusToday || '',
            time_zone: timeZone,
            key: superIntendentType || '',
        };
        getSuperIntendentDashboardDelta(data)
            .then((response) => {
                setSuperIntendentData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            handleFetchBooking();
        }, 1.1);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [
        selectedSolution,
        selectedSubSolution,
        bookingStatusReceivedToday,
        jobStatusId,
        superIntendentId,
        endDate,
        worklogMissingStatus,
        timelogMissingStatus,
        bookingStatusMonth,
        bookingStatusToday,
    ]);

    const handleFetchBooking = (job_status) => {
        setIsBookingLoading(true);
        const data = {
            start_date: startDate || '',
            end_date: endDate || '',
            job_status: jobStatusId ? jobStatusId?.booking_status_id : '',
            selected_id: selectedSubSolution ? selectedSubSolution?.id : '',
            feature: barTabSelection?.value,
            solution_id: selectedSolution ? selectedSolution?.solutions_id : '',
            user_id: superIntendentId?.task__employee_id || '',
            chain_id: superIntendentId?.chain || '',
            received_today: bookingStatusReceivedToday || '',
            month_booking: bookingStatusMonth || '',
            today: bookingStatusToday || '',

            time_zone: timeZone,
            worklog_missing: worklogMissingStatus,
            timelog_missing: timelogMissingStatus,
        };
        getBookingDashboardDelta(data)
            .then((response) => {
                setBookingDatas(response?.bookings);
                setMissingWorkLogCount(response?.missing_log);
                setIsBookingLoading(false);
            })
            .catch((error) => {
                setIsBookingLoading(false);
            });
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            handleFetchData();
        }, 0.1);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [refresh, endDate]);

    const handleFetchData = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            time_zone: timeZone,
        };
        setIsStatisticsLoading(true);

        getReportDashboardDelta(data)
            .then((response) => {
                setDashReport(response);
                setDashData(response?.dataset);
                setStatistics(response?.dataset?.statistics);
                // setTodaysJobCount(response?.dataset?.todays_job_no);
                // setSuperintendantCount(response?.dataset?.superintendent_no);
                // setVesselCount(response?.dataset?.booking_received_today);
                // setLocationCount(response?.dataset?.total_bookings_month);
                // setJobStatus(response?.dataset?.jobs);
                // setYearlyPerformance(response?.dataset?.yearly_performance);

                setIsLoading(false);
                setIsStatisticsLoading(false);
                setOverallDatas(response.overall);
            })
            .catch((error) => {
                setIsStatisticsLoading(false);
                setIsLoading(false);
            });
    };

    const handleFetchJobStatus = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            time_zone: timeZone,
            // subsolution_id: selectedSubSolution ? selectedSubSolution.id : '',
            ...subsolution_query,
            solution_id: selectedSolution ? selectedSolution.solutions_id : '',
        };
        setIsStatisticsLoading(true);

        getDashJobStatus(data)
            .then((response) => {
                // setDashData(response?.dataset);
                // setStatistics(response?.dataset?.statistics);
                setTodaysJobCount(response?.dataset?.todays_job_no);
                setSuperintendantCount(response?.dataset?.superintendent_no);
                setVesselCount(response?.dataset?.booking_received_today);
                setLocationCount(response?.dataset?.total_bookings_month);
                setJobStatus(response?.dataset?.jobs);
                // // setYearlyPerformance(response?.dataset?.yearly_performance);

                // setIsLoading(false);
                setIsStatisticsLoading(false);
                // setOverallDatas(response.overall);
            })
            .catch((error) => {
                setIsStatisticsLoading(false);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            handleFetchJobStatus();
        }, 0.2);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [refresh, endDate, selectedSubSolution, selectedSolution]);

    const handleFetchDataScheduled = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            country_id: performanceFilterTabs?.fiscal_type?.country_id ?? '',
            fiscal_year_start_month: performanceFilterTabs?.fiscal_type?.fiscal_year_start_month || '',
            fiscal_year_end_month: performanceFilterTabs?.fiscal_type?.fiscal_year_end_month || '',
            time_zone: timeZone,
        };
        setPerformanceSheduledLoading(true);
        getReportDashboardDeltaScheduled(data)
            .then((response) => {
                setYearlyPerformance(response?.dataset?.yearly_performance);
                setPerformanceSheduledLoading(false);
            })
            .catch((error) => {
                setPerformanceSheduledLoading(false);
            });
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            handleFetchDataScheduled();
        }, 0.3);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [endDate, performanceFilterTabs?.fiscal_type]);

    useEffect(() => {
        let appointmentPayload = {
            start_date: startDate || '',
            end_date: endDate || '',
            keyword: pASelected,
            time_zone: timeZone,
        };
        setIsLoadingAppointmentPerformance(true);
        const timeOutId = setTimeout(() => {
            getReportDashboardDeltaAppointment(appointmentPayload)
                .then((response) => {
                    setAppointmentPerformance(response?.dataset?.yearly_performance);
                    setIsLoadingAppointmentPerformance(false);
                })
                .catch((error) => {
                    // setIsStatisticsLoading(false);
                    // setIsLoading(false);
                    setIsLoadingAppointmentPerformance(false);
                });
        }, 0.5);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [pASelected, refresh, endDate]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            let financialPerformancePayload = {
                start_date: startDate || '',
                end_date: endDate || '',
                timeframe: fPStatus || 'month',
                time_zone: timeZone,
                // subsolution_id: selectedSubSolution ? selectedSubSolution.id : '',
                ...subsolution_query,
                solution_id: selectedSolution ? selectedSolution.solutions_id : '',
            };
            setFinancialPerformanceLoading(true);

            getFinancePerformanceScheduled(financialPerformancePayload)
                .then((response) => {
                    setFinancialPerformance(response?.dataset?.yearly_performance);
                    setFinancialPerformanceLoading(false);
                })
                .catch((error) => {
                    setFinancialPerformanceLoading(false);

                    setIsLoading(false);
                });
        }, 0.51);

        return () => {
            clearTimeout(timeOutId);
        };
    }, [
        fPStatus,
        refresh,
        endDate,
        selectedSubSolution,
        selectedSolution,
        // initialFinanceStatus
    ]);

    const handleSubSolutionFetchDataRevenue = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            solution_id: selectedSolution ? selectedSolution?.solutions_id : '',
            key: barTabSelection?.value,
            search: searchByRevenew,
            time_zone: timeZone,
            sub_key: lineChartTab === 'revenue' ? 'revenue' : '',
        };
        setSubSolutionLoading(true);
        // setIsStatisticsLoading(true);

        getSubSolutionsDashboardDeltaRevenue(data)
            .then((response) => {
                setSubSolutionItems(response?.dataset);
                setSubsolutionCount(response?.dataset_count);
                // setIsStatisticsLoading(false);

                setSubSolutionLoading(false);
            })
            .catch((error) => {
                setSubSolutionLoading(false);
                // setIsStatisticsLoading(false);
            });
    };
    useEffect(() => {
        if (lineChartTab === 'revenue') {
            const timeOutId = setTimeout(() => {
                handleSubSolutionFetchDataRevenue();
            }, 0.4);

            return () => {
                clearTimeout(timeOutId);
            };
        }
    }, [barTabSelection, selectedSolution, endDate, searchByRevenew, lineChartTab]);

    const handleSubSolutionFetchDataInitial = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            solution_id: selectedSolution ? selectedSolution?.solutions_id : '',
            key: barTabSelection?.value,
            search: searchByRevenew,
            time_zone: timeZone,
            sub_key: lineChartTab === 'jobs' ? 'jobs' : '',
        };
        setSubSolutionLoading(true);
        // setIsStatisticsLoading(true);

        getSubSolutionsDashboardDeltaRevenue(data)
            .then((response) => {
                setSubSolutionItems(response?.dataset);
                setSubsolutionCount(response?.dataset);
                // setIsStatisticsLoading(false);

                setSubSolutionLoading(false);
            })
            .catch((error) => {
                setSubSolutionLoading(false);
                // setIsStatisticsLoading(false);
            });
    };
    useEffect(() => {
        if (lineChartTab === 'jobs') {
            handleSubSolutionFetchDataInitial();
        }
    }, [barTabSelection, selectedSolution, endDate, searchByRevenew, lineChartTab]);

    useEffect(() => {
        if (selectedSolution) {
            handleFilteredSolutions();
        }
    }, [selectedSolution]);

    const handleFilteredSolutions = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            time_zone: timeZone,

            solution_id: selectedSolution ? selectedSolution.solutions_id : '',
        };
        setIsStatisticsLoading(true);

        getFilteredSolutionDatas(data)
            .then((response) => {
                setStatistics(response?.dataset?.statistics);
                // setTodaysJobCount(response?.dataset?.todays_job_no);
                // setSuperintendantCount(response?.dataset?.superintendent_no);
                // setVesselCount(response?.dataset?.booking_received_today);
                // setLocationCount(response?.dataset?.total_bookings_month);
                // setJobStatus(response?.dataset?.jobs);
                // setYearlyPerformance(response?.dataset?.yearly_performance);
                setIsStatisticsLoading(false);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsStatisticsLoading(false);
            });
    };

    useEffect(() => {
        if (selectedSubSolution && barTabSelection?.value === 'subsolution') {
            handleFilteredSubSolutions();
        }
    }, [selectedSubSolution, barTabSelection, endDate]);

    const handleFilteredSubSolutions = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',

            ...subsolution_query, // subsolution_id: selectedSubSolution ? selectedSubSolution.id : '',
        };
        setIsStatisticsLoading(true);

        getFilteredSubSolutionDatas(data)
            .then((response) => {
                setStatistics(response?.dataset?.statistics);
                // setTodaysJobCount(response?.dataset?.todays_job_no);
                // setSuperintendantCount(response?.dataset?.superintendent_no);
                // setVesselCount(response?.dataset?.booking_received_today);
                // setLocationCount(response?.dataset?.total_bookings_month);
                // setJobStatus(response?.dataset?.jobs);
                // setYearlyPerformance(response?.dataset?.yearly_performance);
                setIsStatisticsLoading(false);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsStatisticsLoading(false);

                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (selectedSubSolution && barTabSelection?.value === 'customer') {
            handleFilteredCustomers();
        }
    }, [selectedSubSolution, barTabSelection]);

    const handleFilteredCustomers = () => {
        let data = {
            start_date: startDate || '',
            end_date: endDate || '',
            vessel_id: selectedSubSolution ? selectedSubSolution?.id : '',
            time_zone: timeZone,
        };
        setIsStatisticsLoading(true);
        getFilteredCustomersDatas(data)
            .then((response) => {
                setStatistics(response?.dataset?.statistics);
                // setTodaysJobCount(response?.dataset?.todays_job_no);
                setSuperintendantCount(response?.dataset?.superintendent_no);
                // setVesselCount(response?.dataset?.booking_received_today);
                // setLocationCount(response?.dataset?.total_bookings_month);
                // setJobStatus(response?.dataset?.jobs);
                setYearlyPerformance(response?.dataset?.yearly_performance);

                setIsLoading(false);
                setIsStatisticsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsStatisticsLoading(false);
            });
    };

    useEffect(() => {
        if (selectedSubSolution && barTabSelection.value === 'location') {
            handleFilteredLocations();
        }
    }, [selectedSubSolution, barTabSelection]);

    const handleFilteredLocations = () => {
        let data = {
            location_id: selectedSubSolution ? selectedSubSolution.id : '',
        };
        setIsStatisticsLoading(true);
        getFilteredLocationDatas(data)
            .then((response) => {
                setStatistics(response?.dataset?.statistics);
                // setTodaysJobCount(response?.dataset?.todays_job_no);
                setSuperintendantCount(response?.dataset?.superintendent_no);
                // setVesselCount(response?.dataset?.booking_received_today);
                // setLocationCount(response?.dataset?.total_bookings_month);
                // setJobStatus(response?.dataset?.jobs);
                setYearlyPerformance(response?.dataset?.yearly_performance);

                setIsStatisticsLoading(false);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsStatisticsLoading(false);
                setIsLoading(false);
            });
    };
    const bodyRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event, excludedElements) => {
            if (
                bodyRef?.current &&
                !!bodyRef?.current?.contains(event.target) &&
                !!excludedElements?.some((element) => element?.contains(event?.target))
            ) {
                setSelectedSolution(undefined);
                setSelectedSubSolution(undefined);
                setRefresh((prev) => !prev);
            }
        };

        const excludedElements = [
            document.getElementById('exclude1'),
            // document.getElementById('exclude2')
        ];

        document.addEventListener('mousedown', (event) => handleClickOutside(event, excludedElements));
        return () => {
            document.removeEventListener('mousedown', (event) => handleClickOutside(event, excludedElements));
        };
    }, []);

    const searchChangeHandler = debounce(1000, (e) => {
        setSearchByRevenew(e);
    });

    useEffect(() => {
        setTimeout(() => {
            if (subSolutionItems?.is_revenue_hidden) {
                setLineChartTab('jobs');
                // setPerformanceFilterTabs({ ...performanceFilterTabs, sub_type: 'Bookings' });
            }
            // if (yearlyPerformance?.is_revenue_hidden) {
            //     // setPerformanceChartTab('jobs');
            //     setPerformanceFilterTabs({ ...performanceFilterTabs, sub_type: 'Bookings' });
            // }
        }, 600);
    }, []);

    return (
        <div className="" ref={bodyRef}>
            {props?.loading && <Loader />}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '0.3rem',
                }}>
                <div>
                    <h5>
                        {selectedSolution?.solutions_name} {selectedSubSolution && `/ ${selectedSubSolution?.name}`}
                    </h5>
                </div>

                <div style={{ textAlign: 'right', display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <div>{dashReport?.base_currency_code}</div>
                    <div>
                        <Flatpickr
                            // timezone="UTC"
                            disabled={isLoading}
                            name="startDate"
                            value={startDate}
                            placeholder="Start Date"
                            options={{
                                time_24hr: true,
                            }}
                            className="form-control"
                            onChange={(e) => {
                                setStartDate(moment(e[0]).format('YYYY-MM-DD'));
                            }}
                        />
                    </div>
                    <div>
                        <Flatpickr
                            // timezone="UTC"
                            disabled={isLoading}
                            name="endDate"
                            value={endDate}
                            placeholder="End Date"
                            // disabled={startDate === undefined ? true : false}
                            options={{
                                time_24hr: true,
                                minDate: startDate,
                            }}
                            className="form-control"
                            onChange={(e) => {
                                setEndDate(moment(e[0]).format('YYYY-MM-DD'));
                                setWorklogMissingData([]);
                            }}
                        />
                    </div>
                    <RiRefreshLine
                        fontSize={'1.5rem'}
                        cursor={'pointer'}
                        className="reset_date"
                        onClick={() => {
                            setSelectedSolution(undefined);
                            setSelectedSubSolution(undefined);
                            setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
                            setEndDate(moment().format('YYYY-MM-DD'));
                            setJobStatusId(undefined);
                            setWorklogMissingData([]);
                            setRefresh((prev) => !prev);
                        }}
                    />

                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setStartDate(undefined);
                            setEndDate(undefined);
                            setSelectedSolution(undefined);
                            setSelectedSubSolution(undefined);
                            setJobStatusId(undefined);
                            setWorklogMissingData([]);
                        }}>
                        <Button size="sm" outline style={{ height: 'max-content' }}>
                            Clear All
                        </Button>
                    </div>
                </div>
            </div>
            {/* Header End */}
            <div
                className={`${
                    (JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ||
                        JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team') &&
                    superIntendentData?.length
                        ? 'grid__container__alpha'
                        : (JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ||
                              JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team') &&
                          !superIntendentData?.length
                        ? 'grid__container__beta_block'
                        : 'grid__container'
                }`}>
                <div className="common-grid__style chart__A">
                    <div
                        id="exclude1"
                        style={{ marginBottom: '0', padding: '1rem', border: 'none', boxShadow: 'none' }}>
                        {/* <CardBody> */}
                        {dashData && (
                            <TreeMap
                                // ref={barChartRef}
                                title="Total Bookings by Solution"
                                setSubSolutionItems={setSubSolutionItems}
                                setSubSolutionJobs={setSubSolutionJobs}
                                setStatistics={setStatistics}
                                setTodaysJobCount={setTodaysJobCount}
                                setSuperintendantCount={setSuperintendantCount}
                                setJobStatus={setJobStatus}
                                setLocationCount={setLocationCount}
                                setVesselCount={setVesselCount}
                                setYearlyPerformance={setYearlyPerformance}
                                setSelectedSolution={setSelectedSolution}
                                selectedSolution={selectedSolution}
                                setSuperIntendentId={setSuperIntendentId}
                                setSelectedSubSolution={setSelectedSubSolution}
                                setWorklogMissingData={setWorklogMissingData}
                                data={dashData || []}
                            />
                        )}
                        {/* </CardBody> */}
                    </div>
                </div>
                {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ||
                JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team' ? null : (
                    <div className="common-grid__style top__bar">
                        <Statistics isStatisticsLoading={isStatisticsLoading} statistics={statistics} />
                    </div>
                )}
                <div className="common-grid__style box__1">
                    {/* <Card style={{ marginBottom: '0' }}>
                        <CardBody> */}
                    {/* <Row> */}
                    <Col xl={6} style={{ textAlign: 'center' }}>
                        <p>Today's Assignments</p>
                        <a href="#booking">
                            <h3
                                onClick={() => {
                                    transactionRef1.current.scrollIntoView({
                                        behavior: 'smooth',
                                    });
                                    setDashDataTable({
                                        name: "Today's Assignments",
                                        data: super_dash?.todays_assignment,
                                    });
                                }}
                                style={{ cursor: 'pointer' }}>
                                {isStatisticsLoading ? (
                                    <span
                                        className="skeleton-loader-gradient"
                                        style={{
                                            width: '50%',
                                            margin: '0 auto',
                                        }}></span>
                                ) : (
                                    super_dash?.todays_assignment_count
                                )}
                            </h3>
                        </a>
                    </Col>
                    {/* Changed today */}
                    {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ? (
                        <Col xl={6} style={{ textAlign: 'center' }}>
                            <p>Open Assignments </p>
                            <a href="#booking">
                                <h3
                                    onClick={() => {
                                        transactionRef1.current.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                        setDashDataTable({
                                            name: 'Open Assignments',
                                            data: super_dash?.open_assignment,
                                        });
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{
                                                width: '50%',
                                                margin: '0 auto',
                                            }}></span>
                                    ) : (
                                        super_dash?.open_assignment_count
                                    )}
                                </h3>
                            </a>
                        </Col>
                    ) : (
                        <Col xl={6} style={{ textAlign: 'center' }}>
                            <p>Superintendents Engaged </p>
                            <a href="#booking">
                                <h3
                                    onClick={() => {
                                        transactionRef.current.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                        setBookingStatusToday(true);
                                        setSuperIntendentId('');
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{
                                                width: '50%',
                                                margin: '0 auto',
                                            }}></span>
                                    ) : (
                                        superintendantCount
                                    )}
                                </h3>
                            </a>
                        </Col>
                    )}
                    {/* </Row> */}
                    {/* </CardBody>
                    </Card> */}
                </div>
                <div class="common-grid__style box__sidebar">
                    <Card id="" style={{ marginBottom: '0', boxShadow: 'none' }}>
                        <CardBody style={{ minHeight: '400px', padding: 0, boxShadow: 'none' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    Total {lineChartTab === 'revenue' ? 'Revenue' : 'Bookings'} By{' '}
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={() => toggle()}
                                        style={{
                                            display: 'inline',
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            color: 'steelblue',
                                        }}>
                                        <DropdownToggle data-toggle="dropdown" tag="span">
                                            {barTabSelection?.label}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            style={{
                                                padding: '8px 10px',
                                            }}>
                                            <div
                                                onClick={() => {
                                                    setBarTabSelection({
                                                        label: 'Subsolution',
                                                        value: 'subsolution',
                                                    });
                                                    setSelectedSubSolution(undefined);
                                                }}>
                                                Subsolution
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setBarTabSelection({
                                                        label: 'Customers',
                                                        value: 'customer',
                                                    });
                                                    setSelectedSubSolution(undefined);
                                                }}>
                                                Customers
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setBarTabSelection({
                                                        label: 'Locations',
                                                        value: 'location',
                                                    });
                                                    setSelectedSubSolution(undefined);
                                                }}>
                                                Locations
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setBarTabSelection({
                                                        label: 'Territory',
                                                        value: 'territory',
                                                    });
                                                    setSelectedSubSolution(undefined);
                                                }}>
                                                Territory
                                            </div>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                {/* document.getElementById('booking_tab_dashboard').click() */}
                                <div className="line_charts">
                                    <div className="line_charts_header">
                                        {subSolutionItems?.is_revenue_hidden ? (
                                            ''
                                        ) : (
                                            <div
                                                id="booking_tab_dashboard"
                                                className={`line_chart_tab ${lineChartTab === 'jobs' ? 'active' : ''}`}
                                                onClick={() => setLineChartTab('jobs')}>
                                                Bookings
                                            </div>
                                        )}
                                        {subSolutionItems?.is_revenue_hidden ? (
                                            ''
                                        ) : (
                                            <div
                                                className={`line_chart_tab ${
                                                    lineChartTab === 'revenue' ? 'active' : ''
                                                }`}
                                                onClick={() => setLineChartTab('revenue')}>
                                                Revenue
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div
                                className="search_box_by_revenue"
                                style={{
                                    marginTop: '8px',
                                }}>
                                <FormGroup>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="search_keyword"
                                        name="search_keyword"
                                        placeholder={`Search by ${barTabSelection?.label}`}
                                        onChange={(e) => searchChangeHandler(e?.target?.value)}
                                    />
                                </FormGroup>
                            </div>

                            {subSolutionLoading ? (
                                <LoaderBeta />
                            ) : lineChartTab === 'revenue' ? (
                                subSolutionItems?.is_revenue_hidden === false && (
                                    <BarChartBetaRevenue
                                        data={
                                            subSolutionItems &&
                                            subSolutionItems?.data
                                                ?.slice(0, 20)
                                                .sort((a, b) => b?.total_rate - a?.total_rate)
                                        }
                                        setWorklogMissingData={setWorklogMissingData}
                                        setStatistics={setStatistics}
                                        setTodaysJobCount={setTodaysJobCount}
                                        setSuperintendantCount={setSuperintendantCount}
                                        setLocationCount={setLocationCount}
                                        setVesselCount={setVesselCount}
                                        setJobStatus={setJobStatus}
                                        setYearlyPerformance={setYearlyPerformance}
                                        setSelectedSubSolution={setSelectedSubSolution}
                                        setInitialFinanceStatus={setInitialFinanceStatus}
                                    />
                                )
                            ) : lineChartTab === 'jobs' ? (
                                subSolutionItems && (
                                    <BarChartBetaJobs
                                        data={
                                            subsolutionCount?.data?.length && subsolutionCount?.data
                                            // ?.sort((a, b) => b?.total_count - a?.total_count)
                                        }
                                        setWorklogMissingData={setWorklogMissingData}
                                        setStatistics={setStatistics}
                                        setTodaysJobCount={setTodaysJobCount}
                                        setSuperintendantCount={setSuperintendantCount}
                                        setLocationCount={setLocationCount}
                                        setVesselCount={setVesselCount}
                                        setJobStatus={setJobStatus}
                                        setYearlyPerformance={setYearlyPerformance}
                                        setSelectedSubSolution={setSelectedSubSolution}
                                        setInitialFinanceStatus={setInitialFinanceStatus}
                                    />
                                )
                            ) : (
                                ''
                            )}
                        </CardBody>
                    </Card>
                </div>
                <div class="common-grid__style box__3">
                    <PerformanceCalendarFiscal
                        dropdownPerformanceOpen={dropdownPerformanceOpen}
                        togglePerformance={togglePerformance}
                        yearlyPerformance={yearlyPerformance}
                        countryList={countryList}
                        setPerformanceFilterTabs={setPerformanceFilterTabs}
                        performanceFilterTabs={performanceFilterTabs}
                        performanceSheduledLoading={performanceSheduledLoading}
                    />
                </div>
                {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ? (
                    <div class="common-grid__style box__4" style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xl={6} style={{ textAlign: 'center', width: '50%' }}>
                            <p>Vessel </p>
                            <a href="#booking">
                                <h3
                                    onClick={() => {
                                        transactionRef1.current.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                        setDashDataTable({
                                            name: 'Vessel',
                                            data: super_dash?.vessel,
                                        });
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{
                                                width: '50%',
                                                margin: '0 auto',
                                            }}></span>
                                    ) : (
                                        super_dash?.vessel_count
                                    )}
                                </h3>
                            </a>
                        </Col>
                        <Col xl={6} style={{ textAlign: 'center', width: '50%' }}>
                            <p>Incomplete Worklogs </p>
                            <a href="#booking">
                                <h3
                                    onClick={() => {
                                        transactionRef1.current.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                        setDashDataTable({
                                            name: 'Incomplete Worklogs',
                                            data: super_dash?.incomplete_worklog,
                                        });
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{
                                                width: '50%',
                                                margin: '0 auto',
                                            }}></span>
                                    ) : (
                                        super_dash?.incomplete_worklog_count
                                    )}
                                </h3>
                            </a>
                        </Col>
                    </div>
                ) : (
                    <div class="common-grid__style box__4" style={{ padding: '0.75rem' }}>
                        {/* <Card style={{ marginBottom: '0' }}>
                        <CardBody> */}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
                                <span style={{ margin: '0' }}>Completed Bookings</span>
                                <span style={{ margin: '0' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{ width: '50%', margin: '0 auto' }}></span>
                                    ) : (
                                        `${
                                            // completedJobCount &&
                                            // completedJobCount?.[0] &&
                                            completedJobCount?.[0]?.count ?? ''
                                        } / ${totalJobsCount ?? ''}`
                                    )}
                                </span>
                            </div>

                            <meter
                                style={{ width: '100%' }}
                                max={totalJobsCount}
                                value={completedJobCount?.[0]?.count}></meter>
                        </div>
                        {/* </CardBody>
                    </Card> */}
                    </div>
                )}{' '}
                {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ? null : (
                    <div class="common-grid__style box__5">
                        <div>
                            {/* <Card style={{ marginBottom: '0' }}>
                            <CardBody> */}
                            <p>Total Booking for the Month</p>
                            <a href="#booking">
                                <h3
                                    onClick={() => {
                                        transactionRef.current.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                        setBookingStatusMonth(true);
                                        setSuperIntendentId('');
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{
                                                width: '50%',
                                                margin: '0 auto',
                                            }}></span>
                                    ) : (
                                        locationCount
                                    )}
                                </h3>
                            </a>
                            {/* </CardBody>
                        </Card> */}
                        </div>
                        <div>
                            {/* <Card style={{ marginBottom: '0' }}>
                            <CardBody> */}
                            <p>Bookings Received Today </p>
                            <a href="#booking">
                                <h3
                                    onClick={() => {
                                        transactionRef.current.scrollIntoView({
                                            behavior: 'smooth',
                                        });
                                        setBookingStatusReceivedToday(true);
                                        setSuperIntendentId('');
                                    }}
                                    style={{ cursor: 'pointer' }}>
                                    {isStatisticsLoading ? (
                                        <span
                                            className="skeleton-loader-gradient"
                                            style={{
                                                width: '50%',
                                                margin: '0 auto',
                                            }}></span>
                                    ) : (
                                        vesselCount
                                    )}
                                </h3>
                            </a>
                            {/* </CardBody>
                        </Card> */}
                        </div>{' '}
                    </div>
                )}
                {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Superintendent' ||
                JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name === 'Survey Team' ? null : (
                    <div class="common-grid__style box__6">
                        <FinancePerformance
                            title="Finance Performance"
                            setFPStatus={setFPStatus}
                            // financialPerformance={initialFinanceStatus ? yearlyPerformance : financialPerformance}
                            financialPerformance={financialPerformance}
                            fPStatus={fPStatus}
                            financialPerformanceLoading={financialPerformanceLoading}
                            setInitialFinanceStatus={setInitialFinanceStatus}
                        />
                    </div>
                )}
                {jobStatus?.data?.length ? (
                    <div class="common-grid__style box__7">
                        <TasksBeta
                            transactionRef={transactionRef}
                            handleJobStatus={handleJobStatus}
                            title={'Booking Status'}
                            data={jobStatus?.data}
                            isLoading={isLoading}
                            isRevenueHidden={jobStatus?.is_revenue_hidden}
                        />
                    </div>
                ) : null}
                {superIntendentData?.length ? (
                    <div class="common-grid__style box__8">
                        <div style={{ height: '395px' }}>
                            <div style={{ height: '300px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                        <h6 className="header-title mt-0" style={{ margin: '0', fontWeight: 'bold' }}>
                                            Superintendent
                                        </h6>
                                        <div
                                            className="line_charts"
                                            style={{
                                                padding: '0.3rem',
                                                border: '1px solid silver',
                                                cursor: isLoading ? 'not-allowed' : '',
                                            }}>
                                            <div
                                                className="line_charts_header"
                                                style={{
                                                    pointerEvents: isLoading ? 'none' : '',
                                                }}>
                                                <div
                                                    className={`line_chart_tab ${
                                                        superIntendentType === 'propel' ? 'active' : ''
                                                    }`}
                                                    onClick={() => {
                                                        setSuperIntendentType('propel');
                                                        superIntendentType === 'contractor' &&
                                                            setWorklogMissingData([]);
                                                    }}>
                                                    Propel
                                                </div>
                                                <div
                                                    className={`line_chart_tab ${
                                                        superIntendentType === 'contractor' ? 'active' : ''
                                                    }`}
                                                    onClick={() => {
                                                        setSuperIntendentType('contractor');
                                                        superIntendentType === 'propel' && setWorklogMissingData([]);
                                                    }}>
                                                    Contractor
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {JSON.parse(localStorage.getItem('br_user_role'))?.userrole_name ===
                                    'Superintendent' ? null : (
                                        <div
                                            onClick={() => {
                                                worklogMissingData?.dataset?.length
                                                    ? setWorklogMissingData([])
                                                    : fetchWorklogMissing();
                                            }}
                                            style={{ cursor: 'pointer' }}>
                                            {!isLoading ? (
                                                <>
                                                    {worklogMissingData?.dataset?.length ? (
                                                        <FeatherIcon.Eye />
                                                    ) : (
                                                        <FeatherIcon.EyeOff />
                                                    )}{' '}
                                                    {worklogMissingLoading ? 'Loading...' : 'Worklog Missing'}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    )}
                                </div>
                                <Row>
                                    <Col xl={12}>
                                        {superIntendentData && (
                                            <DashboardSuperintendentTable
                                                superIntendentData={superIntendentData}
                                                superIntendentId={superIntendentId}
                                                setSuperIntendentId={setSuperIntendentId}
                                                transactionRef={transactionRef}
                                                superIntendentType={superIntendentType}
                                                setWorklogMissingStatus={setWorklogMissingStatus}
                                                setTimelogMissingStatus={setTimelogMissingStatus}
                                                worklogMissingData={worklogMissingData}
                                                isLoading={isLoading}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                ) : null}
                {appointmentPerformance.length ? (
                    <div class="common-grid__style box__9">
                        {' '}
                        <PerformanceRevenue
                            title={'Performance Based on Bookings by Date'}
                            setPASelected={setPASelected}
                            appointmentPerformance={appointmentPerformance}
                            pASelected={pASelected}
                            isLoadingAppointmentPerformance={isLoadingAppointmentPerformance}
                        />
                    </div>
                ) : null}{' '}
            </div>
            {/* <Row>
                <Col xl={appointmentPerformance ? 8 : 12}>
                    <Card style={{ height: '395px', marginBottom: '0' }}>
                        <CardBody style={{ height: '300px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                    <h4 className="header-title mt-0" style={{ margin: '0' }}>
                                        Superintendent
                                    </h4>
                                    <div
                                        className="line_charts"
                                        style={{ padding: '0.3rem', border: '1px solid silver' }}>
                                        <div className="line_charts_header">
                                            <div
                                                className={`line_chart_tab ${
                                                    superIntendentType === 'propel' ? 'active' : ''
                                                }`}
                                                onClick={() => setSuperIntendentType('propel')}>
                                                Propel
                                            </div>
                                            <div
                                                className={`line_chart_tab ${
                                                    superIntendentType === 'contractor' ? 'active' : ''
                                                }`}
                                                onClick={() => setSuperIntendentType('contractor')}>
                                                Contractor
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col xl={12}>
                                    {superIntendentData && (
                                        <DashboardSuperintendentTable
                                            superIntendentData={superIntendentData}
                                            superIntendentId={superIntendentId}
                                            setSuperIntendentId={setSuperIntendentId}
                                            transactionRef={transactionRef}
                                            superIntendentType={superIntendentType}
                                            setWorklogMissingStatus={setWorklogMissingStatus}
                                            isLoading={isLoading}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl={4}>
                    <PerformanceRevenue
                        title={'Performance Based on Bookings by Date'}
                        setPASelected={setPASelected}
                        appointmentPerformance={appointmentPerformance}
                        pASelected={pASelected}
                    />
                </Col>
            </Row> */}

            <div ref={transactionRef1} id="booking" style={{ marginTop: '1rem' }}>
                {dashDataTable?.data?.length ? (
                    <DashTable data={dashDataTable?.data || []} title={dashDataTable?.name} />
                ) : null}
            </div>

            <div ref={transactionRef} id="booking" style={{ marginTop: '1rem' }}>
                {bookingDatas?.length ? (
                    <DashboardTodo
                        selectedSolution={selectedSolution}
                        selectedSubSolution={selectedSubSolution}
                        superIntendentId={superIntendentId}
                        setSuperIntendentId={setSuperIntendentId}
                        superDatas={superIntendentData?.data_propel}
                        jobStatusId={jobStatusId}
                        setJobStatusId={setJobStatusId}
                        setBookingStatusReceivedToday={setBookingStatusReceivedToday}
                        setBookingStatusToday={setBookingStatusToday}
                        setBookingStatusMonth={setBookingStatusMonth}
                        bookingStatusReceivedToday={bookingStatusReceivedToday}
                        bookingStatusMonth={bookingStatusMonth}
                        bookingStatusToday={bookingStatusToday}
                        title={'Booking Details'}
                        data={bookingDatas}
                        count={bookingDatas?.length}
                        isBookingLoading={isBookingLoading}
                        worklogMissingStatus={worklogMissingStatus}
                        timelogMissingStatus={timelogMissingStatus}
                        setWorklogMissingStatus={setWorklogMissingStatus}
                        setTimelogMissingStatus={setTimelogMissingStatus}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default DashboardBeta;
