import React from 'react';
import { Formik } from 'formik';
import TextField from '../../../../../../components/textFeild/textFeild';
import { Col, Row, CustomInput, Label } from 'reactstrap';

import * as Yup from 'yup';
import Button from '../../../../../../components/button/button';
import Select from '../../../../../../components/select/select';

const BasicInfo = ({
    onSubmit,
    statusList,
    companyList,
    editDetails,
    type,
    onEditSubmit,
    currecyList,
    handleAction,
    data,
    vesselTypes,
    actionType,
    userRole,
    territories,
    currencyType,
}) => {
    const validationSchema = Yup.object().shape({
        user_first_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name field is required'),
        user_last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name field is required'),
        territory: Yup.mixed().required('Territory field is required'),
        // company_id: Yup.string().required('This field is required'),
        user_role_id: Yup.mixed().required('This field is required').label('User Role'),
        // user_email: Yup.string().email().required('This field is required'),
        user_email: Yup.string().required().email().label('Email'),
        // currency_type_id: Yup.mixed()
        //   .required("This field is required")
        //   .label("Currency Type"),
    });
    const validationSchema_create = Yup.object().shape({
        user_first_name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name field is required'),
        user_last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name field is required'),
        territory: Yup.mixed().required('Territory field is required'),
        user_email: Yup.string().required().email().label('Email'),
        user_role_id: Yup.mixed().required('This field is required').label('User Role'),
        password: Yup.string().required('Password is required field').min(8, 'Password must be at 8 char long'),
        confirm_password: Yup.string()
            .required('Confirm Password is  required field')
            .oneOf([Yup.ref('password')], 'Passwords does not match'),
        // currency_type_id: Yup.mixed()
        //   .required("This field is required")
        //   .label("Currency Type"),
        active: Yup.mixed().required('This field is required').label('Status'),
    });

    return (
        <Formik
            initialValues={data}
            validationSchema={actionType !== 'Edit' ? validationSchema_create : validationSchema}
            onSubmit={(values, actions) => {
                handleAction(values, 1);
                // actions.setSubmitting(false);
            }}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={(e) => handleSubmit(e, values)}>
                    <Row>
                        <Col lg={6}>
                            <TextField
                                label="First name"
                                required={true}
                                name="user_first_name"
                                value={values.user_first_name}
                                error={errors.user_first_name && touched.user_first_name && errors.user_first_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Col>
                        <Col lg={6}>
                            <TextField
                                label="Last name"
                                required={true}
                                name="user_last_name"
                                value={values.user_last_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.user_last_name && touched.user_last_name && errors.user_last_name}
                            />
                        </Col>
                        <Col lg={6}>
                            <TextField
                                label="Email"
                                required={true}
                                name="user_email"
                                value={values.user_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.user_email && touched.user_email && errors.user_email}
                            />
                        </Col>
                        <Col lg={6}>
                            <p className="mb-1 mt-3 font-weight-bold">Territory *</p>
                            <Select
                                options={territories}
                                isMulti={true}
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(v) => {
                                    setFieldValue('territory', v);
                                }}
                                value={values.territory}
                            // onChange={handleChange}
                            />
                            {errors.territory && touched.territory && errors.territory && (
                                <div className="errorMessage">{errors.territory}</div>
                            )}

                            {/* <SelectField
                            label={"Territory"}
                            required={true}
                            isMulti={true}
                            name={"territory"}
                            firstLabel={"Select User Territories"}
                            list={territories}
                            value={values.territory}
                            onChange={handleChange}
                            error={errors.territory && touched.territory && errors.territory}
                            onBlur={handleBlur}
                        /> */}
                        </Col>

                        <Col lg={6}>
                            <TextField
                                label="Password"
                                required={true}
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.password && touched.password && errors.password}
                            />
                        </Col>
                        <Col lg={6}>
                            <TextField
                                label="Confirm Password"
                                type="password"
                                required={true}
                                name="confirm_password"
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.confirm_password && touched.confirm_password && errors.confirm_password}
                            />
                        </Col>

                        <Col lg={6}>
                            <p className="mb-1 mt-3 font-weight-bold">User Role *</p>
                            <Select
                                options={userRole}
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(v) => {
                                    setFieldValue('user_role_id', v);
                                }}
                                value={values.user_role_id}
                            />
                            {errors.user_role_id && touched.user_role_id && errors.user_role_id && (
                                <div className="errorMessage">{errors.user_role_id}</div>
                            )}
                        </Col>

                        <Col lg={6}>
                            <p className="mb-1 mt-3 font-weight-bold">Status *</p>
                            <Select
                                options={[
                                    { label: 'Active', value: '1' },
                                    { label: 'InActive', value: '0' },
                                ]}
                                placeholder="Active"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(v) => {
                                    setFieldValue('active', v);
                                }}
                                value={values.active}
                            />
                            {errors.active && touched.active && errors.active && (
                                <div className="errorMessage">{errors.active}</div>
                            )}
                        </Col>
                        <Col lg={4}>
                            <TextField
                                label="Order in Head Office"
                                name="headquarters_priority"
                                type="number"
                                value={values.headquarters_priority}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.headquarters_priority && touched.headquarters_priority && errors.headquarters_priority}
                            />
                        </Col>
                        <Col lg={4}>
                            <TextField
                                label="Order in Territory"
                                name="organizations_priority"
                                type="number"
                                value={values.organizations_priority}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.organizations_priority && touched.organizations_priority && errors.organizations_priority}
                            />
                        </Col>
                        <Col lg={4}>
                            <TextField
                                label="Order in Country"
                                name="country_priority"
                                type="number"
                                value={values.country_priority}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.country_priority && touched.country_priority && errors.country_priority}
                            />
                        </Col>
                        <Col lg={6}>
                            <p className="mb-1 mt-3 font-weight-bold" style={{ opacity: '0' }}>
                                Notes
                            </p>
                            <div className="form-group" style={{ marginLeft: '20px' }}>
                                <CustomInput
                                    type="checkbox"
                                    onChange={(v) => {
                                        setFieldValue('is_superintendent', v.currentTarget.checked == true ? 1 : 0);
                                    }}
                                    onBlur={handleBlur}
                                    name="is_superintendent"
                                    id="is_superintendent"
                                    defaultValue="1"
                                    defaultChecked={values.is_superintendent == 1 ? true : false}
                                    className="pl-1"
                                    label="Is Superintendent"
                                />

                                <CustomInput
                                    type="checkbox"
                                    onChange={(v) => {
                                        setFieldValue('is_bivac', v.currentTarget.checked == true ? 1 : 0);
                                    }}
                                    onBlur={handleBlur}
                                    name="is_bivac"
                                    id="is_bivac"
                                    defaultValue="1"
                                    defaultChecked={values.is_bivac == 1 ? true : false}
                                    className="pl-1"
                                    label="Is BIVAC"
                                />
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            //   type="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            title="Next"
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default BasicInfo;
