/* LAYOUT */
export const FetchNotifications = 'FETCH_NOTIFICATIONS';
export const FetchNotificationsSuccess = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FetchNotificationsFailure = 'FETCH_NOTIFICATIONS_FAILURE';
export const ClearNotifications = 'CLEAR_NOTIFICATIONS';

export const FetchNotifications_REQUEST = "FetchNotifications_REQUEST";

export const SOCKETRESPONSE = "SOCKETRESPONSE";
export const ISLOADING = "ISLOADING";
