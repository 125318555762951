import { Field, Formik } from 'formik';
import { default as moment, default as Moment } from 'moment';
import React, { Component } from 'react';
import { Search } from 'react-bootstrap-table2-toolkit';
import * as FeatherIcon from 'react-feather';
import Flatpickr from 'react-flatpickr';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardTitle, Col, Input, ModalBody, ModalFooter, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import * as yup from 'yup';

import Loader from '../../components/Loader/loader';
import { BASE_URL } from '../../helpers/helper';
import Texteditor from '../../helpers/texteditor';
import {
    activity_status_list,
    activity_type_list,
    add_activity_attachments,
    create_activity,
    create_activity_comments,
    delete_activity_attachments,
    delete_activity_comments,
    getTerritory,
    list_activity_comments,
    list_source,
    list_user,
    severity_types_list,
    update_activity,
    update_activity_comments,
} from './api.functions';

import 'react-toastify/dist/ReactToastify.css';

let user = JSON.parse(localStorage.getItem('br_user'));

class AddActivity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            booking_status_id: this.props.statusId,
            location_id: this.props.location_id,
            booking_id: this.props.booking_id ? this.props.booking_id : '',
            actions: this.props.actions,
            records: [],
            columns: [],
            comments: [],
            territoryList: [],
            sourceListLoaded: false,
            activityTypeLoaded: false,
            severityTypeLoaded: false,
            assignedTerritoryLoaded: false,
            statusListLoaded: false,
            activityCommentLoaded: false,
            userLoaded: false,
            newAction: false,
            defaultSorted: [],
            subsolutionLists: [],
            productList: [],
            customerLoading: true,
            commentid: '',
            comment: '',
            isActive: false,
            userList: [],
            activity_type_list: [],
            // followupList: [],
            severity_types_list: [],
            sourceList: [],
            customerError: '',
            label: 'Create',
            content: this.props.content ? this.props.content : '',
            files: this.props.content?.attachments ?? [],
            fileSizeError: '',
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleModal = this.toggleModal.bind(this);
    }

    // toggle = (tab) => {
    //     if (this.state.activeTabs !== tab) {
    //         this.setState({
    //             activeTabs: tab,
    //         });
    //     }
    // };

    // toggleModal = () => {
    //     this.setState((prevState) => ({
    //         modal: !prevState.modal,
    //     }));
    // };

    componentDidMount = () => {
        this.getSourceList();
        this.getAssignedUsers();
        this.getActivityTypes();
        this.getSeverityTypes();
        // this.followupstatus();
        this.getAssignedTerritory();
        this.getStausList();
        this.getActivityComments();

        if (localStorage.getItem('activity_state') === 'edit') {
            this.setState({ label: 'Update' });
        } else if (localStorage.getItem('activity_state') === 'duplicate') {
            this.setState({ label: 'Duplicate' });
        }
    };

    addNewComment = (e, comment, commentid) => {
        this.setState({ newAction: true, comment, commentid });
    };

    cancelNewComment = () => {
        this.setState({ newAction: false, comment: '' });
    };

    postComment = () => {
        let payload = { comment: this.state.comment, activity_id: this.state.content.activity_id };
        if (this.state.commentid !== '') {
            update_activity_comments(this.state.commentid, payload).then((res) => {
                this.setState({ newAction: false, comment: '', commentid: '' }, () => this.fetchComments());
            });
        } else {
            create_activity_comments(payload).then((res) => {
                this.setState({ newAction: false, comment: '', commentid: '' }, () => this.fetchComments());
            });
        }
    };
    deleteNewComment = (e, commentid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete  this comment?',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                delete_activity_comments(commentid)
                    .then((response) => {
                        toast.success('Comment Deleted Successfully');
                        // window.location.reload();
                        this.fetchComments();
                    })
                    .catch(function (error) {});
            }
        });
    };
    commentChange = (e) => {
        this.setState({ comment: e.target.value });
    };
    getActivityComments = () => {
        if (
            this.state.content.activity_id !== null &&
            this.state.content.activity_id !== '' &&
            this.state.content.activity_id !== undefined
        ) {
            this.fetchComments();
        } else {
            this.setState({ activityCommentLoaded: true });
        }
    };
    fetchComments = () => {
        let payload = { view: 'all', activity_id: this.state.content.activity_id };
        list_activity_comments(payload)
            .then((res) => {
                this.setState({ comments: res.data, activityCommentLoaded: true });
            })
            .catch(() => {
                this.setState({ activityCommentLoaded: true });
            });
    };
    createComments = () => {};
    updateComments = () => {};

    getAssignedUsers() {
        let payload = {
            user_name: '',
            customer_id: '',
            user_role_id: '',
            active: '1',
            // localStorage.getItem("customer_id")
        };

        list_user(payload)
            .then((response) => {
                let user_data = {};
                let userList = [];

                response.data.forEach((item) => {
                    user_data = {
                        label: item.user_first_name + ' ' + item.user_last_name,
                        value: item.user_id,
                    };
                    userList.push(user_data);
                });
                let dataUser = userList.sort((a, b) => a.label.localeCompare(b.label));

                this.setState({ userList: dataUser, userLoaded: true });
            })
            .catch(function (error) {
                this.setState({ userLoaded: true });
            });
    }

    getAssignedTerritory() {
        let payload = {
            user_name: '',
            customer_id: '',
            user_role_id: '',
            active: '1',
            // localStorage.getItem("customer_id")
        };

        getTerritory(payload)
            .then((response) => {
                let user_data = {};
                let userList = [];
                if (response.response_code == 200) {
                    response.data.forEach((item) => {
                        user_data = {
                            label: item.territory_name,
                            value: item.territory_id,
                        };
                        userList.push(user_data);
                    });
                    let dataUser = userList.sort((a, b) => a.label.localeCompare(b.label));

                    this.setState({ territoryList: dataUser, assignedTerritoryLoaded: true });
                } else if (response.response_code == 401) {
                    toast.error('read permission of territory is required to add or edit activity');
                    this.setState({ assignedTerritoryLoaded: true });
                }
            })
            .catch(function (error) {
                this.setState({ assignedTerritoryLoaded: true });
            });
    }

    getSeverityTypes() {
        let payload = { active: '1' };
        severity_types_list(payload)
            .then((response) => {
                let user_data = {};
                let userList = [];
                if (response.response_code == 200) {
                    response.data.forEach((item) => {
                        user_data = {
                            label: item.severity_types_name,
                            value: item.severity_types_id,
                        };
                        userList.push(user_data);
                    });
                    let dataUser = userList.sort((a, b) => a.label.localeCompare(b.label));

                    this.setState({ severity_types_list: dataUser, severityTypeLoaded: true });
                } else if (response.response_code == 401) {
                    toast.error('read permission of severity status is required to add or edit activity');
                    this.setState({ severityTypeLoaded: true });
                }
            })
            .catch(function (error) {
                this.setState({ severityTypeLoaded: true });
                toast.error('unauthorized');
            });
    }

    getStausList() {
        // let stst = [];

        // stst = [
        //     {
        //         label: 'Active',
        //         value: '1',
        //     },
        //     {
        //         label: 'In Active',
        //         value: '0',
        //     },
        // ];

        // this.setState({ statusList: stst });

        // return;

        let payload = {
            active: '1',
            // "customer_id":"","employee_id":"","created_date":"","location_id":this.state.location_id
        };
        activity_status_list(payload)
            .then((response) => {
                if (response.response_code == 200) {
                    let status_data = {};
                    let stst = [];

                    response.data.forEach((item) => {
                        status_data = {
                            label: item.activity_status_name,
                            value: item.activity_status_id,
                        };
                        stst.push(status_data);
                    });
                    this.setState({ statusList: stst, statusListLoaded: true });
                }
            })
            .catch(function (error) {
                this.setState({ statusListLoaded: true });
            });
    }

    getSourceList() {
        //
        list_source()
            .then((response) => {
                if (response.response_code == 200) {
                    let status_data = {};
                    let stst = [];

                    response.data.forEach((item) => {
                        status_data = {
                            label: item.source_name,
                            value: item.source_table_id,
                        };
                        stst.push(status_data);
                    });
                    let dataUser = stst.sort((a, b) => a.label.localeCompare(b.label));

                    this.setState({ sourceList: dataUser, sourceListLoaded: true });
                }
            })
            .catch(function (error) {
                this.setState({ sourceListLoaded: true });
            });
    }

    getActivityTypes() {
        activity_type_list({ active: '1' })
            .then((response) => {
                if (response.response_code == 200) {
                    let user_data = {};
                    let activity_type_lists = [];
                    response.data.forEach((item) => {
                        user_data = {
                            label: item.activity_type_name,
                            value: item.activity_type_id,
                        };
                        activity_type_lists.push(user_data);
                    });
                    let dataUser = activity_type_lists.sort((a, b) => a.label.localeCompare(b.label));

                    this.setState({ activity_type_list: dataUser, activityTypeLoaded: true });
                } else if (response.response_code == 401) {
                    toast.error('read permission of activity types is required to add or edit activity');
                    this.setState({ activityTypeLoaded: true });
                }
            })
            .catch(function (error) {
                this.setState({ activityTypeLoaded: true });
            });
    }

    // followupstatus() {
    //     let followupList = [];

    //     followupList = [
    //         {
    //             label: 'No',
    //             value: 0,
    //         },
    //         {
    //             label: 'Yes',
    //             value: 1,
    //         },
    //     ];
    //     this.setState({ followupList: followupList });
    // }

    clearFormikprops() {
        this.setState({
            activity_type: '',
            assigned_to: '',
            severity_type: '',
            // followup: '',
            // followup_date: '',
            statusvalue: '',
            source: '',
            label: 'Create',
            activity_id: '',
            activity_description: '',
        });
    }
    onFileRemove = (files, key) => {
        let current = files;
        let newObject = {};
        let i = 0;
        Object.keys(current).map((k) => {
            if (k !== key) {
                newObject = { ...newObject, [i]: current[k] };
                i++;
            }
        });

        // delete current[key];
        return newObject;
    };

    CreateActivity(values) {
        let assignedto = [];
        let loc = [];
        if (values.assigned_to) {
            values.assigned_to.forEach((element) => {
                assignedto.push(element.value);
            });
        }
        if (values.territory) {
            values.territory.forEach((element) => {
                loc.push(element.value);
            });
        }

        let url = '';
        if (values.activity_id != '') {
            url = BASE_URL + 'operation/update_activity';
        } else {
            url = BASE_URL + 'operation/create_activity';
        }

        let payload = {
            source_table_id: this.state.booking_id
                ? this.state.booking_id
                : this.state.content?.source_table_id
                ? this.state.content?.source_table_id
                : 0,
            activity_type_id: values.activity_type.value,

            assignee_user_id: assignedto,
            activity_description: values.description,
            territory_ids: loc,
            notes: '',
            document_upload: '',
            severity_type_id: values.severity_type.value,
            start_date: '',
            end_date: values.end_date[0]
                ? Moment(values.end_date[0]).format('YYYY-MM-DD')
                : values.end_date
                ? Moment(values.end_date).format('YYYY-MM-DD')
                : '',
            due_date: values.due_date[0]
                ? Moment(values.due_date[0]).format('YYYY-MM-DD')
                : values.due_date
                ? Moment(values.due_date).format('YYYY-MM-DD')
                : '',
            activity_status_id: values.statusvalue.value,

            customer_id: localStorage.getItem('customer_id'),
            company_id: '',
            created_by_user: localStorage.getItem('loggedin_user_id'),
            source_id: values.source.value,
            activity_id: values.activity_id,
            modified_by_user: values.activity_id ? 1 : '',
        };
        let subdata = new FormData();
        subdata.append(
            'source_table_id',
            this.state.booking_id
                ? this.state.booking_id
                : this.state.content?.source_table_id
                ? this.state.content?.source_table_id
                : ''
        );
        subdata.append('activity_type_id', values.activity_type.value);
        subdata.append('source_id', values.source.value ? values.source.value : '');

        subdata.append('activity_status_id', values.statusvalue.value);
        subdata.append('activity_description', values.description);
        subdata.append('customer_id', localStorage.getItem('customer_id') ?? '');
        subdata.append('created_by_user', localStorage.getItem('loggedin_user_id'));
        subdata.append('modified_by_user', localStorage.getItem('loggedin_user_id'));

        subdata.append('notes', '');
        subdata.append('start_date', '');
        subdata.append('document_upload', '');
        subdata.append('company_id', '');

        subdata.append(
            'due_date',
            values.due_date && values.due_date[0]
                ? Moment(values.due_date[0]).format('YYYY-MM-DD')
                : values.due_date
                ? Moment(values.due_date).format('YYYY-MM-DD')
                : ''
        );

        subdata.append(
            'end_date',
            values.end_date && values.end_date[0]
                ? Moment(values.end_date[0]).format('YYYY-MM-DD')
                : values.end_date
                ? Moment(values.end_date).format('YYYY-MM-DD')
                : ''
        );
        subdata.append('severity_type_id', values.severity_type.value);

        subdata.append('assignee_user_id', JSON.stringify(assignedto));
        subdata.append('territory_ids', JSON.stringify(loc));
        if (values.activity_id !== null && values.activity_id !== undefined && values.activity_id !== '') {
            let att = new FormData();
            if (values.attachement) {
                Object.keys(values.attachement).map((key, i) => {
                    att.append(`attachments`, values.attachement[key] ?? null);
                });
                att.append('activity_id', values.activity_id);

                add_activity_attachments(att);
            }
        } else {
            if (values.attachement) {
                Object.keys(values.attachement).map((key, i) => {
                    subdata.append(`attachments`, values.attachement[key] ?? null);
                });
            }
        }

        if (values.activity_id !== null && values.activity_id !== undefined && values.activity_id !== '') {
            subdata.append('activity_id', values.activity_id);
            this.setState({ isActive: true });

            update_activity(payload)
                .then((response) => {
                    if (response.response_code == 200) {
                        let label = this.state.label;
                        this.setState({ isActive: false });
                        toast.success('Activity ' + label + 'd Successfully');
                        this.props.toggleModal();

                        if (this.props.getrateListList()) {
                            this.props.getrateListList();
                        }
                    } else {
                        this.setState({ isActive: false });
                        toast.error('Something went Wrong!!');
                    }
                })
                .catch((error) => {
                    this.setState({ error: true, isActive: false });

                    toast.error(error?.response?.data?.message);
                });
        } else {
            this.setState({ isActive: true });
            create_activity(subdata)
                .then((response) => {
                    if (response.response_code == 200) {
                        let label = this.state.label;
                        this.setState({ isActive: false });
                        toast.success('Activity ' + label + 'd Successfully');
                        this.props.toggleModal();

                        if (this.props.getrateListList()) {
                            this.props.getrateListList();
                        }
                    } else {
                        this.setState({ isActive: false });
                        toast.error('Something went Wrong!!');
                    }
                })
                .catch((error) => {
                    this.setState({ error: true, isActive: false });
                    toast.error(error?.response?.data?.message);
                });
        }
    }
    handleDeleteAttachment = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete  this Attachment?',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                delete_activity_attachments(id)
                    .then((res) => {
                        toast.success(res.message);

                        let fi = this.state.files;
                        let f = fi.filter((i) => i.id !== id);
                        this.setState({ files: f });
                    })
                    .catch((err) => toast.error(err.response.data.message));
            }
        });
    };

    render() {
        const validationSchema = yup.object().shape({
            activity_type: yup.mixed().required().label('Activity type'),
            // assigned_to: yup.mixed().required().label('Assigned To'),
            description: yup.mixed().required().label('Description'),
            severity_type: yup.mixed().required().label('Priority'),
            // followup: yup.mixed().required().label('Followup'),
            statusvalue: yup.mixed().required().label('Status'),
            due_date: yup.date().nullable(),
            // end_date: yup
            // .date()
            // .when(
            //     "due_date",
            //     (due_date, schema) => due_date && schema.min(due_date,"End date has to be greater than due date"))
        });
        function onKeyDown(keyEvent) {
            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                keyEvent.preventDefault();
            }
        }

        // function disableFollowupDate(isfollowupp) {
        //     var cstId = isfollowupp.value;

        //     if (cstId != 1) {
        //         //  isfollowup =true;
        //     }
        // }

        const { SearchBar } = Search;
        const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
            <React.Fragment>
                <label className="d-inline mr-1">Show</label>
                <Input
                    style={{ padding: '5px' }}
                    type="select"
                    name="select"
                    id="no-entries"
                    className="custom-select custom-select-sm d-inline col-1"
                    defaultValue={currSizePerPage}
                    onChange={(e) => onSizePerPageChange(e.target.value)}>
                    {options.map((option, idx) => {
                        return (
                            <option key={idx} value={option.page}>
                                {option.text}
                            </option>
                        );
                    })}
                </Input>
                <label className="d-inline ml-1">entries</label>
            </React.Fragment>
        );

        function SelectFieldSingleStatus(FieldProps) {
            return (
                <>
                    <Select
                        options={FieldProps.options}
                        {...FieldProps.field}
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="react-select"
                        onChange={(option) => {
                            FieldProps.form.setFieldValue(FieldProps.field.name, option);
                            // disableFollowupDate(option);
                        }}
                    />
                    {FieldProps.error && <div className="errorMessage">{FieldProps.error}</div>}
                </>
            );
        }
        function SelectFieldSingle(FieldProps) {
            return (
                <>
                    <Select
                        options={FieldProps.options}
                        {...FieldProps.field}
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="react-select"
                        defaultValue={FieldProps.selectedValue}
                        onChange={(option) => {
                            FieldProps.form.setFieldValue(FieldProps.field.name, option);
                        }}
                    />
                    {FieldProps.error && <div className="errorMessage">{FieldProps.error}</div>}
                </>
            );
        }

        function SelectFieldMulti(FieldProps) {
            return (
                <>
                    <Select
                        options={FieldProps.options}
                        {...FieldProps.field}
                        isMulti={true}
                        className="react-select"
                        classNamePrefix="react-select"
                        defaultValue={FieldProps.currentValue}
                        onChange={(option) => {
                            FieldProps.form.setFieldValue(FieldProps.field.name, option);
                        }}
                    />
                    {FieldProps.error && <div className="errorMessage">{FieldProps.error}</div>}
                </>
            );
        }
        function TexteditorField(FieldProps) {
            return <Texteditor name="description" initialContent={FieldProps.initialContent} />;
        }
        function SelectDatePicker(FieldProps) {
            return (
                <>
                    <div className="date_Picker">
                        <span className="icon">
                            <svg
                                width="41"
                                height="40"
                                viewBox="0 0 41 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.9014 3.67989C11.4472 3.98598 11.3911 4.15133 11.3911 5.18548V6.10994H9.63008C8.08823 6.10994 7.80719 6.12572 7.37234 6.23696C5.88491 6.61738 4.68432 7.81798 4.30389 9.3054C4.10492 10.0834 4.10492 32.5394 4.30389 33.3174C4.69095 34.8308 5.94024 36.0552 7.46194 36.4127C8.19998 36.586 33.2775 36.5606 33.9609 36.3859C35.4483 36.0054 36.6489 34.8048 37.0294 33.3174C37.2283 32.5394 37.2283 10.0834 37.0294 9.3054C36.6489 7.81798 35.4483 6.61738 33.9609 6.23696C33.5261 6.12572 33.245 6.10994 31.7032 6.10994H29.9421V5.18548C29.9421 3.96408 29.6734 3.53342 28.9115 3.53342C28.1496 3.53342 27.8809 3.96408 27.8809 5.18548V6.10994H20.6666H13.4524V5.18548C13.4524 3.76749 12.7636 3.09889 11.9014 3.67989ZM11.3911 8.83796C11.3911 9.78026 11.7253 10.2324 12.4217 10.2324C13.1182 10.2324 13.4524 9.78026 13.4524 8.83796V8.17115H20.6666H27.8809V8.83796C27.8809 9.78026 28.2151 10.2324 28.9115 10.2324C29.6093 10.2324 29.9421 9.78058 29.9421 8.83332V8.16182L31.6652 8.18256L33.3882 8.20336L33.7747 8.38378C34.8296 8.87616 35.0558 9.3874 35.0843 11.3435L35.1057 12.8089H20.6666H6.22753L6.24891 11.3435C6.28885 8.60607 6.75513 8.18996 9.79691 8.1776L11.3911 8.17115V8.83796ZM35.0802 23.8074L35.063 32.7447L34.8916 33.1103C34.6512 33.6232 34.2698 34.0123 33.7906 34.2336L33.3882 34.4195H20.6666H7.94504L7.57949 34.2481C7.06657 34.0077 6.67745 33.6263 6.45613 33.1471L6.2703 32.7447L6.2531 23.8074L6.2359 14.8701H20.6666H35.0974L35.0802 23.8074Z"
                                    fill="#555555"
                                />
                            </svg>
                        </span>
                        <Flatpickr
                            value={FieldProps.field.value}
                            options={{ enableTime: false, dateFormat: 'd F Y' }}
                            onChange={(date) => {
                                FieldProps.form.setFieldValue(FieldProps.field.name, date);
                            }}
                            className="form-control"
                            placeholder="Select Date"
                        />
                    </div>
                    {FieldProps.error && <div className="errorMessage">{FieldProps.error}</div>}
                </>
            );
        }

        // if (Boolean(this.state.customerLoading)) return <Loader />;
        // if (Boolean(this.state.customerError)) return <Error message={this.state.customerError} />;
        const { newAction, comments, files } = this.state;
        if (
            this.state.isActive ||
            !this.state.sourceListLoaded ||
            !this.state.userLoaded ||
            !this.state.activityTypeLoaded ||
            !this.state.severityTypeLoaded ||
            !this.state.assignedTerritoryLoaded ||
            !this.state.statusListLoaded ||
            !this.state.activityCommentLoaded
        ) {
            return <Loader />;
        }

        return (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    activity_type: this.state.content
                        ? {
                              value: this.state.content?.activity_type_details.activity_type_id,
                              label: this.state.content?.activity_type_details.activity_type_name,
                          }
                        : '',

                    // assigned_to :  this.state.content ? ( {
                    //     value:this.state.content.assignee_user_details.user_id,
                    //     label:this.state.content.assignee_user_details.user_first_name + ' ' +this.state.content.assignee_user_details.user_last_name,
                    // }) :"",

                    assigned_to: this.state.content ? this.state.content.assignedTolist : [],
                    territory: this.state.content ? this.state.content.territory : [],

                    severity_type: this.state.content
                        ? {
                              value: this.state.content.severity_type_details.severity_types_id,
                              label: this.state.content.severity_type_details.severity_types_name,
                          }
                        : '',

                    // followup: this.state.content
                    //     ? {
                    //           value: this.state.content.follow_up,
                    //           label: this.state.content.follow_up == 1 ? 'Yes' : 'No',
                    //       }
                    //     : '',

                    source: this.state.content
                        ? {
                              value: this.state.content.source_table_details
                                  ? this.state.content.source_table_details.source_table_id
                                  : '',
                              label: this.state.content.source_table_details
                                  ? this.state.content.source_table_details.source_name
                                  : '',
                          }
                        : '',

                    statusvalue: this.state.content
                        ? {
                              value: this.state.content.activity_status_id ? this.state.content.activity_status_id : '',
                              label: this.state.content.activity_status_id
                                  ? this.state.content.activity_status_details?.activity_status_name
                                  : '',
                          }
                        : {
                              value: 2,
                              label: 'Open',
                          },

                    // followup_date: this.state.content
                    //     ? this.state.content.follow_up_date
                    //         ? new Date(this.state.content?.follow_up_date)
                    //         : ''
                    //     : '',
                    activity_id:
                        localStorage.getItem('activity_state') === 'duplicate'
                            ? ''
                            : this.state.content
                            ? this.state.content.activity_id
                            : '',
                    description: this.state.content ? this.state.content.activity_description : '',
                    due_date: this.state.content
                        ? this.state.content.due_date
                            ? new Date(this.state.content?.due_date)
                            : ''
                        : new Date(),
                    end_date: this.state.content
                        ? this.state.content.end_date
                            ? new Date(this.state.content?.end_date)
                            : ''
                        : '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    await new Promise((resolve) => setTimeout(resolve, 500));

                    try {
                        this.CreateActivity(values);
                        resetForm({});
                    } catch (error) {
                        toast.error(error.response.data.message);
                    }
                }}>
                {(formik) => (
                    <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
                        <ModalBody>
                            <Row>
                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Activity Type *</p>
                                    <div className="form-group">
                                        <Field
                                            name="activity_type"
                                            selectedValue={formik.values.activity_type}
                                            options={this.state.activity_type_list}
                                            component={SelectFieldSingle}
                                            error={
                                                formik.errors.activity_type &&
                                                formik.touched.activity_type &&
                                                formik.errors.activity_type
                                            }
                                            required={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Assigned User</p>
                                    <div className="form-group">
                                        <Field
                                            name="assigned_to"
                                            selectedValue={formik.values.assigned_to}
                                            options={this.state.userList}
                                            component={SelectFieldMulti}
                                            error={
                                                formik.errors.assigned_to &&
                                                formik.touched.assigned_to &&
                                                formik.errors.assigned_to
                                            }
                                            required={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Assigned Territory</p>
                                    <div className="form-group">
                                        <Field
                                            name="territory"
                                            selectedValue={formik.values.territory}
                                            options={this.state.territoryList}
                                            component={SelectFieldMulti}
                                            error={
                                                formik.errors.territory &&
                                                formik.touched.territory &&
                                                formik.errors.territory
                                            }
                                            required={true}
                                        />
                                    </div>
                                </Col>

                                <Col lg={6} style={{ display: 'none' }}>
                                    <p className="mb-1 mt-3 font-weight-bold">Source</p>
                                    <div className="form-group">
                                        <Field
                                            name="source"
                                            options={this.state.sourceList}
                                            component={SelectFieldSingle}
                                            selectedValue={formik.values.source}
                                            error={
                                                formik.errors.source && formik.touched.source && formik.errors.source
                                            }
                                            required={false}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Priority *</p>
                                    <div className="form-group">
                                        <Field
                                            name="severity_type"
                                            options={this.state.severity_types_list}
                                            component={SelectFieldSingle}
                                            selectedValue={formik.values.severity_type}
                                            error={
                                                formik.errors.severity_type &&
                                                formik.touched.severity_type &&
                                                formik.errors.severity_type
                                            }
                                            required={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <>
                                        <p className="mb-1 mt-3 font-weight-bold">Due Date *</p>
                                        <div className="form-group">
                                            <Field
                                                name="due_date"
                                                component={SelectDatePicker}
                                                error={
                                                    formik.errors.due_date &&
                                                    formik.touched.due_date &&
                                                    formik.errors.due_date
                                                }
                                                required={true}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.due_date}
                                            />
                                        </div>
                                    </>
                                </Col>
                                {/* 
                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Follow Up *</p>
                                    <div className="form-group">
                                        <Field
                                            name="followup"
                                            options={this.state.followupList}
                                            component={SelectFieldSingleStatus}
                                            selectedValue={formik.values.followup}
                                            error={
                                                formik.errors.followup &&
                                                formik.touched.followup &&
                                                formik.errors.followup
                                            }
                                            required={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>*/}
                                {/* {(isfollowup == true) ? ( */}
                                {/*  <>
                                        <p className="mb-1 mt-3 font-weight-bold">Follow Up Date</p>
                                        <div className="form-group">
                                            <Field
                                                name="followup_date"
                                                component={SelectDatePicker}
                                                error={
                                                    formik.errors.followup_date &&
                                                    formik.touched.followup_date &&
                                                    formik.errors.followup_date
                                                }
                                                // disabled={true}
                                                // readOnly={this.state.isfollowup}
                                                required={true}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.followup_date}
                                            />
                                        </div>
                                    </>
                                </Col> 
                            */}
                                <Col lg={12}>
                                    <p className="mb-1 mt-3 font-weight-bold">Activity Description *</p>
                                    <div className="form-group">
                                        <Field
                                            error={
                                                formik.errors.description &&
                                                formik.touched.description &&
                                                formik.errors.description
                                            }
                                            name="description"
                                            component={TexteditorField}
                                            initialContent={formik.values.description}
                                            // required={true}
                                        />
                                        {formik.errors.description && (
                                            <div className="errorMessage">{formik.errors.description}</div>
                                        )}
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <p className="mb-1 mt-3 font-weight-bold">Status *</p>
                                    <div className="form-group">
                                        <Field
                                            name="statusvalue"
                                            options={this.state.statusList}
                                            component={SelectFieldSingle}
                                            selectedValue={formik.values.statusvalue}
                                            error={
                                                formik.errors.statusvalue &&
                                                formik.touched.statusvalue &&
                                                formik.errors.statusvalue
                                            }
                                            required={true}
                                        />
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <>
                                        <p className="mb-1 mt-3 font-weight-bold">Completion Date </p>
                                        <div className="form-group">
                                            <Field
                                                name="end_date"
                                                component={SelectDatePicker}
                                                error={
                                                    formik.errors.end_date &&
                                                    formik.touched.end_date &&
                                                    formik.errors.end_date
                                                }
                                                // required={true}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.end_date}
                                            />
                                        </div>
                                    </>
                                </Col>

                                <Col lg={12}>
                                    <div className="form-control-file">
                                        <p className="mb-1 mt-3 font-weight-bold d-flex align-items-center">
                                            <label for="filechooser">
                                                <FeatherIcon.Paperclip style={{ height: 14 }} />
                                                <span> Attach Document (Max 15MB) </span>{' '}
                                                <span className="text-danger">
                                                    {`  `}
                                                    {this.state.fileSizeError}
                                                </span>
                                            </label>
                                        </p>
                                        <input
                                            label="Attach Document"
                                            name="customer_name"
                                            type="file"
                                            multiple
                                            id="filechooser"
                                            hidden
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => {
                                                formik.setFieldValue('attachement', e.target.files);
                                            }}
                                        />

                                        {/* {formik.values.attachement &&
                                        formik.values.attachement.length > 0 &&
                                        formik.values.attachement[0].size > 15728640
                                            ? this.setState({ fileSizeError: ' (File size should be less than 15MB)' })
                                            : ''} */}

                                        {formik.values.attachement !== null &&
                                            formik.values.attachement !== undefined &&
                                            formik.values.attachement !== '' && (
                                                <>
                                                    {Object.keys(formik.values.attachement).map((key) => {
                                                        return (
                                                            <div className="p-2 bg-light">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>{formik.values.attachement[key].name}</div>
                                                                    <div
                                                                        className="text-danger cursor-pointer"
                                                                        onClick={(e) =>
                                                                            formik.setFieldValue(
                                                                                'attachement',
                                                                                this.onFileRemove(
                                                                                    formik.values.attachement,
                                                                                    key
                                                                                )
                                                                            )
                                                                        }>
                                                                        <i className="uil uil-trash-alt mr-1"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                            )}

                                        {files.length > 0 &&
                                            files.map((file) => (
                                                <div className="p-2 bg-light">
                                                    <div className="d-flex justify-content-between">
                                                        <div
                                                            className="cursor-pointer text-primary"
                                                            onClick={(e) => window.open(file.attachment, '_blank')}>
                                                            {file.name}
                                                        </div>
                                                        <div
                                                            className="text-danger cursor-pointer"
                                                            onClick={(e) => this.handleDeleteAttachment(file.id)}>
                                                            <i className="uil uil-trash-alt mr-1"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Col>

                                {formik.values.attachement &&
                                formik.values.attachement.length > 0 &&
                                formik.values.attachement[0].size > 15728640
                                    ? this.setState({ fileSizeError: ' (File size should be less than 15MB)' })
                                    : ''}

                                {this.state.content.activity_id !== null &&
                                    this.state.content.activity_id !== undefined &&
                                    this.state.content.activity_id !== '' && (
                                        <>
                                            <Col lg={12}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div>
                                                        <h6>Activity Actions</h6>
                                                    </div>

                                                    <div style={{ display: 'flex' }}>
                                                        {newAction ? (
                                                            <>
                                                                <Button
                                                                    className="btn btn-success mr-2"
                                                                    style={{
                                                                        padding: '0.4rem 1rem',
                                                                        fontSize: '0.9rem',
                                                                        margin: '0.5rem 0',
                                                                    }}
                                                                    onClick={(e) => this.postComment(e)}>
                                                                    Post
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        padding: '0.4rem 1rem',
                                                                        fontSize: '0.9rem',
                                                                        margin: '0.5rem 0',
                                                                    }}
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={(e) => this.cancelNewComment(e)}>
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <Button
                                                                className="btn-primary btn-sm"
                                                                style={{
                                                                    padding: '0.4rem 1rem',
                                                                    fontSize: '0.9rem',
                                                                    margin: '0.5rem 0',
                                                                }}
                                                                onClick={(e) => this.addNewComment(e, '', '')}>
                                                                {' '}
                                                                Add
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>

                                            {newAction && (
                                                <Col lg={12} className="mt-2">
                                                    <Input
                                                        type="textarea"
                                                        rows="6"
                                                        name="description"
                                                        id="exampleText"
                                                        onChange={(e) => this.commentChange(e)}
                                                        value={this.state.comment}

                                                        // required={true}
                                                    />
                                                </Col>
                                            )}

                                            <Col lg={12} className="mt-2" style={{ height: '140px', overflow: 'auto' }}>
                                                {comments.length !== 0 ? (
                                                    comments.map((item, index) => (
                                                        <Card key={index}>
                                                            <CardTitle>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                        }}>
                                                                        <div
                                                                            style={{
                                                                                width: '20px',
                                                                                height: '20px',
                                                                                borderRadius: '50%',
                                                                                fontSize: '12px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                background: 'grey',
                                                                                color: 'white',
                                                                                marginRight: '10px',
                                                                            }}>
                                                                            {item.created_by_user
                                                                                ? item.created_by_user?.first_name.substring(
                                                                                      0,
                                                                                      1
                                                                                  )
                                                                                : 'U'}
                                                                        </div>
                                                                        <div>
                                                                            <b>
                                                                                {item.created_by_user?.first_name !==
                                                                                    undefined &&
                                                                                item.created_by_user?.first_name !==
                                                                                    null &&
                                                                                item.created_by_user?.first_name !== ''
                                                                                    ? item.created_by_user?.first_name
                                                                                    : ''}{' '}
                                                                                {item.created_by_user?.last_name !==
                                                                                    undefined &&
                                                                                item.created_by_user?.last_name !==
                                                                                    null &&
                                                                                item.created_by_user?.last_name !== ''
                                                                                    ? item.created_by_user?.last_name
                                                                                    : 'Unknown'}
                                                                            </b>{' '}
                                                                            Responded on{' '}
                                                                            <b>
                                                                                {moment(item.modified_date).format(
                                                                                    'DD-MMM-YYYY'
                                                                                )}{' '}
                                                                            </b>
                                                                            at{' '}
                                                                            <b>
                                                                                {moment(item.modified_date).format(
                                                                                    'hh:mm a'
                                                                                )}{' '}
                                                                            </b>
                                                                            {item.modified_by_user !== null
                                                                                ? `( edited )`
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {!newAction && (
                                                                            <>
                                                                                {user.id ===
                                                                                    item.created_by_user?.id && (
                                                                                    <>
                                                                                        <i
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                            className="uil uil-edit mr-1"
                                                                                            onClick={(e) =>
                                                                                                this.addNewComment(
                                                                                                    e,
                                                                                                    item.comment,
                                                                                                    item.id
                                                                                                )
                                                                                            }></i>{' '}
                                                                                        <i
                                                                                            className="uil uil-trash-alt mr-1 text-danger"
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                            onClick={(e) =>
                                                                                                this.deleteNewComment(
                                                                                                    e,

                                                                                                    item.id
                                                                                                )
                                                                                            }></i>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </CardTitle>
                                                            <CardBody>{item.comment}</CardBody>
                                                        </Card>
                                                    ))
                                                ) : (
                                                    <Card>
                                                        <CardTitle
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                            No Actions Found
                                                        </CardTitle>
                                                    </Card>
                                                )}
                                            </Col>
                                        </>
                                    )}
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" type="button" className="ml-1" onClick={this.props.toggleModal}>
                                Close
                            </Button>
                            <Button color="primary" type="submit">
                                {this.state.label}
                            </Button>
                        </ModalFooter>
                        {/* <button type="submit">Submit</button> */}
                    </form>
                )}
            </Formik>
        );
    }
}

export default withRouter(AddActivity);
