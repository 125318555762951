import React from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

const BarChartBetaJobs = (props) => {
    const {
        setStatistics,

        setTodaysJobCount,
        setSuperintendantCount,
        setLocationCount,
        setVesselCount,
        setJobStatus,
        setYearlyPerformance,
        data = [],
        setWorklogMissingData,
        setSelectedSubSolution,
        setInitialFinanceStatus,
    } = props;
    const series = [
        {
            name: 'Jobs',
            // data: props.data,
            data: (data && data?.length && data?.map((list) => list?.total_count)) || [],
        },
    ];
    const options = {
        title: {
            text: props?.title,
            // align: 'center',
        },
        chart: {
            id: 'basic-bar',
            width: '100%',
            height: '500px',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setWorklogMissingData([]);
                    setSelectedSubSolution(config.w.config.customData[config.dataPointIndex]);
                    setInitialFinanceStatus(false);
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        colors: ['#3B93A5'],
        dataLabels: {
            // enabled: false,
        },
        xaxis: {
            categories: (data && data?.length && data?.map((list) => list?.name || '')) || [],
        },
    };

    // const data = props?.data?.map((list) => list?.total_count) ?? [];
    return (
        <>
            <div style={{ overflowX: 'scroll' }}>
                {/* <h5 className="card-title header-title">{props.title}</h5> */}

                <Chart
                    options={{ ...options, customData: data }}
                    series={series}
                    type="bar"
                    className="apex-charts"
                    height={'500px'}
                />
            </div>
        </>
    );
};

export default BarChartBetaJobs;
