import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, ButtonGroup } from 'reactstrap';
import Swal from 'sweetalert2';

import config from '../../../config';
import { COMMON_API_ERROR } from '../../../constants';
import { listTerritories } from '../api.functions';
import LocationForm from './compoenent/Forms/location';
import useCountry from './useCountry';

function useLocation(props) {
    const _useCountry = useCountry(props);
    const defaultValues = {
        location_name: '',
        address1: '',
        address2: '',
        suburb: '',
        city: '',
        location_state: '',
        postcode: '',
        country: '',
        notes: '',
        created_by_user: localStorage.getItem('loggedin_user_id'),
        modified_by_user: '',
        active: '1',
    };

    useEffect(() => {
        // _useCountry.fetch();
    }, []);

    const [customerList, setCustomerList] = useState([]);
    const [customerError, setCustomerError] = useState(null);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState(null);
    const [action, setAction] = useState('Create');
    const [initialValues, SetInitialValues] = useState(defaultValues);
    const [territoryList, setTerritoryList] = useState([]);

    const fetch = () => {
        setCustomerLoading(true);

        const payload = {
            location_name: '',
            active: '',
            user_id: localStorage.getItem('loggedin_user_id'),
        };
        axios
            .post(`${config.apiRoot}configuration/location_list`, payload)
            .then((res) => {
                setCustomerLoading(false);
                setCustomerList(res?.data.data);
            })
            .catch(() => {
                setCustomerLoading(false);
                setCustomerError(COMMON_API_ERROR);
            });

        getTerritory();
    };

    const getTerritory = () => {
        const payload = {
            customer_id: '',
            tarrif_rate_type_name: '',
            user_id: localStorage.getItem('loggedin_user_id'),
        };
        let list = [];

        listTerritories(payload).then((res) => {
            let data = res?.data;

            if (data) {
                data.map((value) => {
                    list.push({ name: value.territory_name, value: value.territory_id });
                });
            }

            setTerritoryList(list.sort((a, b) => a.name.localeCompare(b.name)));
            // return ;
        });
    };

    const keyField = 'location_id';
    const customerColumns = [
        // {
        //     dataField: 'location_id',
        //     text: 'Location Id',
        //     sort: true,
        //     formatter: (item, row) => {
        //         return `#${item}`;
        //     }
        // },
        {
            dataField: 'sl.no',
            text: 'Sl no.',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                let rowNumber =
                    (localStorage.getItem('page') - 1) * localStorage.getItem('sizePerPage') + (rowIndex + 1);

                return rowNumber;
            },
            // sort: true,
            headerStyle: { width: '80px' },
        },
        {
            dataField: 'location_name',
            text: 'Location Name',
            sort: true,
            headerStyle: { width: '200px' },
        },
        {
            dataField: 'city',
            text: 'City',
            sort: true,
            headerStyle: { width: '110px' },
        },
        {
            dataField: 'suburb',
            text: 'Suburb',
            sort: true,
            headerStyle: { width: '130px' },
        },

        {
            dataField: 'postcode',
            text: 'Postcode',
            sort: true,
            headerStyle: { width: '120px' },
        },
        {
            dataField: 'country',
            text: 'Country',
            sort: true,
            headerStyle: { width: '120px' },
        },
        {
            dataField: 'created_date',
            text: 'Created At',
            headerStyle: { width: '120px' },
            formatter: (value) => {
                if (Boolean(value)) return moment(value, 'YYYY-MM-DD').format('DD MMMM YYYY');
            },
        },
        {
            dataField: 'active',
            text: 'Status',
            headerStyle: { width: '100px' },
            formatter: (item, row) => {
                if (Boolean(item)) return <div style={{ color: 'green' }}>Active</div>;
                return <div style={{ color: 'red' }}>Inactive</div>;
            },
        },
        {
            dataField: 'location_id',
            text: '',
            headerStyle: { width: '140px' },
            formatter: (rowContent, row) => {
                return (
                    <ButtonGroup>
                        {sessionStorage.getItem('update') === '1' && (
                            <Button
                                className="btn btn-info"
                                onClick={() => handleEditClick(row, localStorage.getItem('user_id'))}>
                                Edit
                            </Button>
                        )}
                        {sessionStorage.getItem('delete') === '1' && (
                            <Button className="btn btn-danger" onClick={() => onDelete(rowContent)}>
                                Delete
                            </Button>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const handleClose = () => {
        setVisible(false);
        SetInitialValues(defaultValues);
    };

    const ModalContent = () => {
        return (
            <LocationForm
                reload={fetch}
                initialValues={initialValues}
                actionType={action}
                country={_useCountry.country}
                modelClose={handleClose}
                territory={territoryList}
                fetch={fetch}
            />
        );
    };

    const openModel = (title = 'Add Location', action = 'Create') => {
        setTitle(title);
        setVisible(true);
        setAction(action);
        _useCountry.fetch();
    };

    const handleEditClick = (row) => {
        openModel('Edit Location', 'Edit');
        SetInitialValues(row);
    };

    const onDelete = (rowContent) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure that you want to delete? ',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                let payload = {
                    location_id: rowContent,
                    user_id: localStorage.getItem('loggedin_user_id'),
                };
                axios
                    .post(`${config.apiRoot}configuration/delete_location`, payload)
                    .then((res) => {
                        fetch();
                        toast.success(res.data.data?.message);
                    })
                    .catch((e) => {
                        toast.error(COMMON_API_ERROR);
                    });
            }
        });
    };

    return {
        fetch,
        customerList,
        customerError,
        customerLoading,
        customerColumns,
        visible,
        ModalContent,
        handleClose,
        title,
        disableSelectColumn: false,
        hideSelectAll: false,
        openModel,
        onDelete,
        initialValues,
        keyField,
    };
}

export default useLocation;
