import React from 'react';
import { Button as ButtonComponent, Spinner } from 'reactstrap';

const Button = ({ loading = false, title, ...rest }) => {
    const getLoading = () => {
        if (loading) {
            return (
                <>
                    <Spinner size="sm"></Spinner>
                </>
            );
        }
    };

    return (
        <ButtonComponent color={rest.varient ? rest.varient : 'primary'} {...rest}>
            {' '}
            {getLoading()} {title}
        </ButtonComponent>
    );
};

export default Button;
