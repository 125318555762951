const convert_date = (individualDate) => {
    let formatDate = new Date(individualDate);
    const customFormatDate = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
        timeZone: 'Australia/Perth',
    })?.format(formatDate);
    let myFormatDateArr = customFormatDate?.toString().replaceAll(',', '').split(' ');
    let formatString = '';
    if (myFormatDateArr?.length >= 3) {
        formatString = `${myFormatDateArr?.[1]}-${myFormatDateArr?.[0]}-${myFormatDateArr?.[2]}`;
    }
    return formatString;
};
export default convert_date;
