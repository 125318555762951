import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { CardBody, Card, Spinner } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from 'components/Loader/loader';

const DashboardSuperintendentTable = ({
    setSuperIntendentId,
    transactionRef,
    setWorklogMissingStatus,
    setTimelogMissingStatus,
    superIntendentData,
    superIntendentType,
    isLoading,
    superIntendentId,
    worklogMissingData,
}) => {
    const combinedArray = superIntendentData?.data_propel?.map((obj1) => {
        const matchingObj = worklogMissingData?.dataset?.find((obj2) => obj1.task__employee_id === obj2.user_id);
        return { ...obj1, ...matchingObj };
    });

    function checkKeyExists(array, key) {
        for (let i = 0; i < array?.length; i++) {
            if (key in array[i]) {
                return true; // Key found in at least one object
            }
        }
        return false; // Key not found in any object
    }

    const NoDataIndication = () => (
        <div className="EmptyBlock">
            <div className="rect_Emplty">No Data Available</div>
        </div>
    );
    const { SearchBar } = Search;

    const columns = [
        {
            dataField: 'user_name',
            text: 'Name',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return (
                    <div
                        style={{ color: 'steelblue', cursor: 'pointer' }}
                        onClick={() => {
                            transactionRef.current.scrollIntoView({ behavior: 'smooth' });
                            setSuperIntendentId(row);
                            setWorklogMissingStatus(false);
                        }}
                        // onClick={() => history.push(`/apps/bookingdetails/${row.booking_id}`)}
                    >
                        {rowContent}
                    </div>
                );
            },
        },
        {
            dataField: 'vessel_count',
            text: 'Vessel Count',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'hours',
            text: 'Total Working Hours',
            sort: true,
            headerClasses: 'id-custom-cell',
        },
        {
            dataField: 'missing_worklog',
            text: 'Timelog Missing',
            sort: true,
            headerClasses: 'id-custom-cell',
            formatter: (rowContent, row) => {
                return (
                    <div
                        style={{ color: 'steelblue', cursor: 'pointer' }}
                        onClick={() => {
                            transactionRef.current.scrollIntoView({ behavior: 'smooth' });
                            setTimelogMissingStatus(true);
                            setSuperIntendentId(row);
                        }}>
                        {rowContent || '-'}
                    </div>
                );
            },
        },
        {
            dataField: 'count',
            text: 'Worklog Missing',
            sort: true,
            headerClasses: 'id-custom-cell',
            hidden: !checkKeyExists(combinedArray, 'count'),
            formatter: (rowContent, row) => {
                return (
                    <div
                        style={{ color: 'steelblue', cursor: 'pointer' }}
                        onClick={() => {
                            transactionRef.current.scrollIntoView({ behavior: 'smooth' });
                            setWorklogMissingStatus(true);
                            setSuperIntendentId(row);
                        }}
                        // onClick={() => history.push(`/apps/bookingdetails/${row.booking_id}`)}
                    >
                        {rowContent || '-'}
                    </div>
                );
            },
        },
    ].filter((item) => !item.hidden);

    const newColumns = columns.filter((column) => superIntendentData?.headers?.includes(column.text));

    const rowClasses = (row) => (row.task__employee_id === superIntendentId.task__employee_id ? 'selected_row1' : '');

    const data =
        superIntendentType === 'propel'
            ? superIntendentData.data_propel
                  ?.filter((list) => list.user_name !== 'Superintendent TBA')
                  ?.sort((a, b) => (a.user_name > b.user_name ? 1 : -1)) || []
            : superIntendentData.data_contractor
                  ?.filter((list) => list.user_name !== 'Superintendent TBA')
                  ?.sort((a, b) => (a.user_name > b.user_name ? 1 : -1)) || [];

    return (
        <>
            {isLoading ? (
                <div className="card" style={{ height: '270px' }}>
                    <CardBody className="loaderContainerTest">
                        <Spinner>Loading...</Spinner>
                    </CardBody>
                </div>
            ) : (
                <div
                    className="dashboard-table"
                    style={{
                        overflowY: 'auto',
                        padding: '1rem 0',
                        height: '285px',
                    }}>
                    {!!newColumns.length && (
                        <ToolkitProvider
                            bootstrap4
                            keyField="activity_id"
                            data={combinedArray}
                            columns={newColumns}
                            search>
                            {(props) => (
                                <React.Fragment>
                                    <BootstrapTable
                                        {...props.baseProps}
                                        bordered={false}
                                        striped={true}
                                        wrapperClasses="table-responsive"
                                        rowClasses={rowClasses}
                                        noDataIndication={() => <NoDataIndication />}
                                    />
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    )}
                </div>
            )}
            <div style={{ marginTop: '15px' }}>Total count : {data.length}</div>
        </>
    );
};

export default DashboardSuperintendentTable;
