import React from 'react';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';

const Statistics = ({ isStatisticsLoading, statistics }) => {
    return (
        <div className="stats_main" style={{ marginBottom: '0' }}>
            <div className="main_wrapper">
                <div className="card_header">
                    <div className="header_content">
                        <h1>Statistics</h1>
                    </div>
                </div>
                <div className="card_body">
                    <div className="card_body_wrapper">
                        {isStatisticsLoading
                            ? [1, 2, 3, 4, 5, 6].map((num) => (
                                  <div className="card_stats">
                                      <span className="skeleton-loader-gradient" style={{ width: '50%' }}></span>
                                      <br />
                                      <span className="skeleton-loader-gradient" style={{ width: '70%' }}></span>
                                  </div>
                              ))
                            : !!statistics &&
                              !!statistics.length &&
                              statistics?.map((stat) => (
                                  <div className="card_stats">
                                      <div className="number">
                                          <RiMoneyDollarCircleLine style={{ fontSize: '1.3rem' }} />
                                          <p
                                              style={{
                                                  fontSize: '0.75rem',
                                                  fontWeight: 'lighter',
                                              }}>
                                              {stat.amount < 0 ? '0' : stat.amount}
                                          </p>
                                      </div>
                                      <p
                                          className="title"
                                          style={{
                                              fontSize: '0.75rem',
                                              fontWeight: 'lighter',
                                          }}>
                                          {stat.name}
                                      </p>
                                  </div>
                              ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;
